import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Brands } from '../../dataset/Brands';
import { BrandsService } from '../../providers/brands/brands.service';

@Injectable({
  providedIn: 'root'
})
export class BrandsResolveService implements Resolve<Brands[]> {

  constructor(private brandsService: BrandsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Brands[]> {
    return await this.brandsService.getAll();
  }
}
