import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MeetingPlace} from "../../dataset/MeetingPlace";

@Injectable({
  providedIn: 'root'
})
export class MeetingsService {

  constructor(private http: HttpClient) { }

  async getMeetingPlaces(activityId: string): Promise<MeetingPlace[]> {
    return this.http.get<MeetingPlace[]>(`/get-meeting-places/${activityId}`).toPromise();
  }

  async createMeetingPlace(data: any) {
    return this.http.post<MeetingPlace>(`/create-meeting-place`, data).toPromise();
  }

  async updateMeetingPlace(id: string, data: any) {
    return this.http.put<MeetingPlace>(`/update-meeting-place/${id}`, data).toPromise();
  }

  async removeMeetingPlace(id: string) {
    return this.http.delete(`/delete-meeting-place/${id}`).toPromise();
  }
}
