import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CategoriesService} from '../../../../providers/categories/categories.service';
import {Category} from '../../../../dataset/Category';
import {UploadService} from '../../../../providers/upload/upload.service';
import {Shop, ShopTypes} from '../../../../dataset/Shop';
import {ShopsService} from '../../../../providers/shops/shops.service';
import {DialogService} from '../../../../common/alert-dialog/services/dialog.service';
import {ENTER} from '@angular/cdk/keycodes';
import {Mall} from "../../../../dataset/Mall";
import {map as obsMap, startWith} from "rxjs/operators";
import {keyBy, map} from "lodash";
import {ShopFacade} from "../../../../dataset/ShopFacade";
import {Observable} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DirtyFormModalService} from '../../../../providers/dirty-form-modal/dirty-form-modal.service';
import {TranslocoService} from '@ngneat/transloco';
import {getSortedAvailableLocales} from "../../../../../utils/locale.utils";
import {filterFacade} from "../../../../../utils/facade";
import {linkPattern} from "../../../../../utils/utils";
import {fileSizeValidator, FileSizeValidatorResults} from '../../../../../utils/file.utils';
import {Brands} from "../../../../dataset/Brands";
import {ScheduleComponent} from "../../../../common/ui/schedule/schedule.component";

@Component({
  selector: 'app-shops-edit-modal',
  templateUrl: './shops-edit-modal.component.html',
  styleUrls: ['./shops-edit-modal.component.scss']
})
export class ShopsEditModalComponent implements OnInit, OnDestroy {

  @ViewChild('categoryInput', {static: true}) categoryInput: ElementRef;
  @ViewChild('scheduleComponent', {static: true}) scheduleComponent: ScheduleComponent;

  form: FormGroup;
  selectedPaymentTypes: string[] = [];
  logo: File;
  logoFileUrl: string;
  background: File;
  backgroundFileUrl: string;

  showcase: File;
  showcaseFileUrl: string;
  showcaseFileType: string;

  galleryFiles: {file?: File, url: string}[] = [];

  loading = false;
  locales: string[] = [];

  shopCategories: Category[] = [];
  brands: Brands[] = [];
  separatorKeysCodes: number[] = [ENTER];
  categoryCtrl = new FormControl();
  tagsCtrl = new FormControl();
  filteredCategories: Observable<Category[]>;
  allCategories: Category[] = [];
  locale = 'en';
  tags: string[] = [];
  shopTypes = ShopTypes;
  maxDescLength = 500;
  maxShortDescLength = 200;

  constructor(public sanitizer: DomSanitizer,
              public dialogRef: MatDialogRef<ShopsEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { mall: Mall, shop?: Shop, brands: Brands[], hasProxy: boolean },
              private categoriesService: CategoriesService,
              private uploadService: UploadService,
              private dialogs: DialogService,
              private fb: FormBuilder,
              private shopsService: ShopsService,
              private dirtyFormService: DirtyFormModalService,
              private translocoService: TranslocoService) {
    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      obsMap((c: string | null) => c ? this._filter(c) : this.allCategories.slice())
    );
  }

  parseData(categories: Category[]): Category[] {
    const categoriesIndex = keyBy(categories, c => c._id);
    categories
      .filter(c => !!c.parent)
      .forEach(c => c.parentName = categoriesIndex[c.parent].facade[this.locale].name);
    return categories;
  }

  async ngOnInit() {
    const {shop, mall, brands} = this.data;
    this.brands = brands;
    this.locale = this.data.mall.settings?.default_locale || this.translocoService.getActiveLang();
    this.locales = getSortedAvailableLocales(mall.settings.frontend_available_locales, this.locale);
    this.form = this.fb.group({
      facade: this.fb.array([this.fb.group({
        __locale: this.locale,
        name: [null, Validators.required],
        description: null,
        shortDescription: null
      })]),
      type: null,
      index: [null, Validators.min(0)],
      featuredIndex: [null, Validators.min(0)],
      color: ['#ffdd00', Validators.required],
      group: [],
      phone: [],
      email: [],
      website: [null, Validators.pattern(linkPattern)],
      address: [],
      renterPhone: [],
      renterName: [],
      roomNumber: [],
      isActive: [true, Validators.required],
      hideInSearch: false,
      social: this.fb.group({
        tg: null,
        vk: null,
      })
    });
    this.dirtyFormService.registerForm(this.form, this.dialogRef);
    this.allCategories = this.parseData(await this.categoriesService.getAll(mall._id));
    if (shop) {
      const data = Object.assign({}, shop);
      delete data.facade;
      this.form.patchValue(data);
      this.scheduleComponent.setValue(data.scheduleNew);
      const facades = this.fb.array(map(shop.facade, (facade, __locale) => this.fb.group({
        __locale,
        name: facade.name,
        description: facade.description,
        shortDescription: facade.shortDescription,
      })));
      this.form.setControl('facade', facades);
      this.selectedPaymentTypes = shop.paymentType;
      this.logoFileUrl = shop.logo;
      this.backgroundFileUrl = shop.backgroundImage;
      this.showcaseFileUrl = shop.showcase;
      if (this.showcaseFileUrl) {
        this.showcaseFileType = decodeURIComponent(this.showcaseFileUrl.split('#')[1]);
      }
      this.allCategories.filter(c => shop.categories.includes(c._id)).forEach(c => this.addCategory(c));
      if (shop.searchTags?.length > 0) {
        this.tags = shop.searchTags.split(',');
      }
      this.galleryFiles = shop.gallery.map(url => ({
        url,
        file: null,
      }));
    }
  }

  removeGalleryFile(pos: number) {
    this.galleryFiles.splice(pos, 1);
  }

  removeLogoFile() {
    this.logoFileUrl = null;
    this.logo = null;
  }

  removeBackgroundFile() {
    this.backgroundFileUrl = null;
    this.background = null;
  }

  isPayTypeSelected(type: string) {
    if (!this.selectedPaymentTypes) {
      return false;
    }
    return this.selectedPaymentTypes.includes(type);
  }

  selectPayType(type: string) {
    this.dirtyFormService.forceDirtyForm();
    if (this.isPayTypeSelected(type)) {
      const index = this.selectedPaymentTypes.indexOf(type);
      this.selectedPaymentTypes.splice(index, 1);
    } else {
      this.selectedPaymentTypes.push(type);
    }
  }

  async logoFile($event: Event) {
    this.dirtyFormService.forceDirtyForm();
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const file = target.files.item(0);
    if (fileSizeValidator(file) !== FileSizeValidatorResults.VALID) {
      const message = this.translocoService.translate(
        fileSizeValidator(file) === FileSizeValidatorResults.INVALID_SIZE ?
          'incorrect-file-size' :
          'incorrect-file-extension'
      );
      this.dialogs.alert(
        {
          title: `${this.translocoService.translate('error')} - ${file.name}`,
          message,
        });
      return;
    }
    const {url} = await this.uploadService.previewUrl(file);
    this.logo = file;
    this.logoFileUrl = url;
  }

  async backgroundFile($event: Event) {
    this.dirtyFormService.forceDirtyForm();
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const file = target.files.item(0);
    if (fileSizeValidator(file) !== FileSizeValidatorResults.VALID) {
      const message = this.translocoService.translate(
        fileSizeValidator(file) === FileSizeValidatorResults.INVALID_SIZE ?
          'incorrect-file-size' :
          'incorrect-file-extension'
      );
      this.dialogs.alert(
        {
          title: `${this.translocoService.translate('error')} - ${file.name}`,
          message,
        });
      return;
    }
    const {url} = await this.uploadService.previewUrl(file);
    this.background = file;
    this.backgroundFileUrl = url;
  }

  async showcaseFile($event: Event) {
    this.dirtyFormService.forceDirtyForm();
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    if (target.files.length) {
      const file = target.files.item(0);
      if (fileSizeValidator(file, true) !== FileSizeValidatorResults.VALID) {
        const message = this.translocoService.translate(
          fileSizeValidator(file, true) === FileSizeValidatorResults.INVALID_SIZE ?
            'incorrect-file-size' :
            'incorrect-file-extension'
        );
        this.dialogs.alert(
          {
            title: `${this.translocoService.translate('error')} - ${file.name}`,
            message,
          });
        return;
      }
      const {url} = await this.uploadService.previewUrl(file);
      this.showcase = file;
      this.showcaseFileType = file.type;
      this.showcaseFileUrl = url;
    }
    target.value = null;
  }

  clearShowcase() {
    this.showcaseFileUrl = null;
    this.showcase = null;
    this.showcaseFileType = null;
  }

  async addGalleryFiles($event: Event) {
    this.dirtyFormService.forceDirtyForm();
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const files = Array.from(target.files);
    for (const file of files) {
      if (fileSizeValidator(file) !== FileSizeValidatorResults.VALID) {
        const message = this.translocoService.translate(
          fileSizeValidator(file) === FileSizeValidatorResults.INVALID_SIZE ?
            'incorrect-file-size' :
            'incorrect-file-extension'
        );
        this.dialogs.alert(
          {
            title: `${this.translocoService.translate('error')} - ${file.name}`,
            message,
          });
        return;
      }
      const {url} = await this.uploadService.previewUrl(file);
      this.galleryFiles.push({
        file,
        url,
      });
    }
  }

  async confirm() {
    this.loading = true;
    try {
      const facade: { [locale: string]: ShopFacade } = this.facades.controls.reduce((last, control) => {
        const {__locale} = control.value;
        delete control.value.__locale;
        last[__locale] = control.value;
        return last;
      }, {});
      const shop: Shop = Object.assign(this.form.value, {
        paymentType: this.selectedPaymentTypes,
        mall: this.data.mall._id,
        categories: this.shopCategories.map(c => c._id),
        logo: this.logo ? null : this.logoFileUrl,
        backgroundImage: this.background ? null : this.backgroundFileUrl,
        facade
      });

      const showcase = this.showcase ? await this.uploadService.upload(this.showcase) : null;
      const gallery = [];
      for (const file of this.galleryFiles) {
        if (file.file) {
          if (fileSizeValidator(file.file) !== FileSizeValidatorResults.VALID) {
            const message = this.translocoService.translate(
              fileSizeValidator(file.file) === FileSizeValidatorResults.INVALID_SIZE ?
                'incorrect-file-size' :
                'incorrect-file-extension'
            );
            this.dialogs.alert(
              {
                title: `${this.translocoService.translate('error')} - ${file.file.name}`,
                message,
              });
            return;
          }
          gallery.push(await this.uploadService.upload(file.file));
        } else {
          gallery.push(file.url);
        }
      }
      shop.gallery = gallery;

      if (showcase) {
        shop.showcase = `${showcase}#${encodeURIComponent(this.showcaseFileType)}`;
      } else if (!this.showcaseFileUrl) {
        shop.showcase = null;
      }

      if (this.tags.length === 0) {
        shop.searchTags = map(facade, v => v.name).join(',');
      } else {
        shop.searchTags = this.tags.join(',');
      }

      if (!shop.type) {
        delete shop.type;
      }

      shop.scheduleNew = this.scheduleComponent.getCurrentValue();

      let res;
      if (this.data.shop) {
        res = await this.shopsService.update(this.data.shop._id, shop);
      } else {
        res = await this.shopsService.create(shop);
      }
      if (this.logo) {
        res = await this.shopsService.upload(res._id, this.logo, 'logo');
      }
      if (this.background) {
        res = await this.shopsService.upload(res._id, this.background, 'backgroundimage');
      }
      this.dialogRef.close(res);
    } catch (err) {
      this.dialogs.error(err);
    }
    this.loading = false;
  }

  addTag(event: MatChipInputEvent) {
    this.dirtyFormService.forceDirtyForm();
    const input = event.input;
    const value = event.value;
    this.tags.push(value);
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagsCtrl.setValue(null);
  }

  removeCategoryFromShop(category: Category) {
    const index = this.shopCategories.indexOf(category);
    if (index >= 0) {
      this.dirtyFormService.forceDirtyForm();
      this.shopCategories.splice(index, 1);
      this.allCategories.push(category);
    }
  }

  removeTag(tag: string) {
    this.dirtyFormService.forceDirtyForm();
    this.tags = this.tags.filter(item => item !== tag);
  }

  categorySelected(event: MatAutocompleteSelectedEvent) {
    this.dirtyFormService.forceDirtyForm();
    const category = this.allCategories.find(c => c._id === event.option.value);
    if (category) {
      this.addCategory(category);
    }
    this.categoryInput.nativeElement.value = '';
    this.categoryCtrl.setValue(null);
  }

  addCategory(category: Category) {
    this.shopCategories.push(category);
    const index = this.allCategories.indexOf(category);
    if (index >= 0) {
      this.allCategories.splice(index, 1);
    }
  }

  private _filter(value: string): Category[] {
    const filterValue = value?.toLowerCase() || '';
    return this.allCategories.filter(c => filterFacade<Category>(c, this.locale, filterValue));
  }


  get facades(): FormArray {
    return this.form.get('facade') as FormArray;
  }

  hasFacade(__locale: string): boolean {
    return this.facades.controls.filter(c => c.value.__locale === __locale).length !== 0;
  }

  removeFacade(index: number) {
    this.facades.removeAt(index);
  }

  addFacade(__locale: string) {
    this.facades.push(this.fb.group({
      __locale,
      name: [null, Validators.required],
      description: null,
      shortDescription: null,
    }));
  }

  async setBrandData(brand: Brands) {
    try {
      if (await this.dialogs.confirm({
        title: this.translocoService.translate('brands-confirm-title'),
        message: this.translocoService.translate('brands-confirm'),
      })) {
        this.logoFileUrl = brand.logo;
        const facades = this.fb.array(map(brand.facade, (facade, __locale) => this.fb.group({__locale, ...facade})));
        this.form.setControl('facade', facades);

        if (brand.searchTags.length > 0) {
          this.tags = brand.searchTags.split(',');
        }
        this.form.setControl('website', this.fb.control(brand.website ?? ''));
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

  localeName(__locale: string) {
    return __locale;
  }

  localsCount(): number {
    return this.locales.length;
  }

  cancel() {
    this.dirtyFormService.closeSafely();
  }

  ngOnDestroy() {
  }
}
