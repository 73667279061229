import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { SpeakerSession, SpeakerSessionFile } from "../../dataset/SpeakerSession";
import { Facade } from '../../dataset/Facade';
import { Speaker } from '../../dataset/Speaker';

@Injectable({
  providedIn: 'root'
})
export class SpeakerSessionsService {

  constructor(private http: HttpClient) {
  }

  async getAll(activity: string): Promise<SpeakerSession[]> {
    return this.http.get<SpeakerSession[]>('/speaker-sessions', {params: {activity}}).toPromise();
  }

  async get(id: string): Promise<SpeakerSession> {
    return this.http.get<SpeakerSession>(`/speaker-session/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<SpeakerSession>(`/speaker-session`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<SpeakerSession>(`/speaker-session/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<SpeakerSession>(`/speaker-session/${id}`).toPromise();
  }

  async getFiles(id: string) {
    return this.http.get<SpeakerSessionFile[]>(`/session/${id}/files`).toPromise();
  }

  async addFile(id: string, file: File, facade: Facade<{ title: string, description: string }>, fileIndex?: number): Promise<SpeakerSession> {
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('facade', JSON.stringify(facade));
    if (fileIndex) {
      fileData.append('fileIndex', String(fileIndex));
    }
    return this.http.put<SpeakerSession>(`/session/${id}/file`, fileData).toPromise();
  }

  async updateFile(id: string, fileUrl: string, facade: Facade<{ title: string, description: string }>, fileIndex?: number): Promise<SpeakerSessionFile[]> {
    const fileData = new FormData();
    fileData.append('facade', JSON.stringify(facade));
    if (fileIndex) {
      fileData.append('fileIndex', String(fileIndex));
    }
    return this.http.put<SpeakerSessionFile[]>(`/session/${id}/update-file?fileUrl=${fileUrl}`, fileData).toPromise();
  }

  async deleteFile(id: string, fileUrl: any) {
    return this.http.delete(`/session/${id}/file`, {params: {fileUrl}}).toPromise();
  }

  async getActivitySpeakers(activity: string): Promise<Speaker[]> {
    return this.http.get<Speaker[]>(`/activity-speakers/${activity}/speaker-list`).toPromise();
  }

  async updateActivitySpeakers(id: string, data: {account: string, index: number}[]): Promise<Speaker[]> {
    return this.http.put<Speaker[]>(`/activity-speakers/${id}/update-speaker-list`, {
      speakers: data,
    }).toPromise();
  }
}
