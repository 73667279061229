import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Terminal} from '../../dataset/Terminal';
import {format} from 'date-fns';
import {Observable} from "rxjs";
import {BannerGroup} from "../../dataset/BannerGroup";
import {TerminalGroups} from "../../dataset/TerminalGroups";

@Injectable()
export class TerminalsService {

  constructor(private http: HttpClient) {
  }

  async getAll(options: {mall?: string, isProd?: boolean}): Promise<Terminal[]> {
    let params = new HttpParams();
    const {mall, isProd} = options;
    if (mall) {
      params = params.set('mall', mall);
    }
    if (isProd) {
      params = params.set('prod', 'true');
    }
    return this.http.get<Terminal[]>('/terminal', {
      params,
    }).toPromise();
  }

  async get(id: string): Promise<Terminal> {
    return this.http.get<Terminal>(`/terminal/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Terminal>(`/terminal`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<Terminal>(`/terminal/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<Terminal>(`/terminal/${id}`).toPromise();
  }

  async activateByPin(id: string, pin: any) {
    return this.http.get<Terminal>(`/terminal/${id}/activate-by-pin?pin=${pin}`).toPromise();
  }

  screenshots(id: string, date: Date): Observable<string[]> {
    return this.http.get<string[]>(`/terminal/${id}/screenshots`, {
      params: new HttpParams().set('date', format(date, 'yyyy-MM-dd'))
    });
  }

  async updateCommands(id: string, data: any): Promise<string[]> {
    return this.http.post<string[]>(`/terminal/${id}/commands`, data).toPromise();
  }

  async getGroups(mall: string): Promise<TerminalGroups[]> {
    let params = new HttpParams();
    params = params.set('mall', mall);
    return this.http.get<TerminalGroups[]>('/terminal-groups', {
      params,
    }).toPromise();
  }

  async createGroup(data: TerminalGroups): Promise<TerminalGroups> {
    return this.http.post<TerminalGroups>('/terminal-groups', data).toPromise();
  }

  async updateGroup(groupId: string, data: TerminalGroups): Promise<TerminalGroups> {
    return this.http.put<TerminalGroups>(`/terminal-groups/${groupId}`, data).toPromise();
  }

  async deleteGroup(id: string) {
    return this.http.delete(`/terminal-groups/${id}`).toPromise();
  }

}
