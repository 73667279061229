import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivityContactsService } from '../../providers/activity-contacts/activity-contacts.service';
import { ActivityContactPerson } from '../../dataset/ActivityContactPerson';

@Injectable({
  providedIn: 'root'
})
export class ActivityContactsResolverService implements Resolve<ActivityContactPerson[]> {

  constructor(private service: ActivityContactsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ActivityContactPerson[]> {
    return this.service.getAll(route.parent.paramMap.get('id'));
  }
}
