import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { SearchService } from '../../../app/providers/search/search.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'fuse-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class FuseSearchBarComponent implements OnInit, OnDestroy {
  expanded: boolean;
  fuseConfig: any;
  searchControl = new FormControl();

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private searchService: SearchService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (config) => {
          this.fuseConfig = config;
        }
      );
    this.searchService.collapsedObservable
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(expanded => {
        this.expanded = expanded;
        if (!expanded) {
          this.searchControl.setValue('');
        }
      });
    this.searchControl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(val => this.searchService.setText(val));
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  collapse(): void {
    this.searchService.setExpanded(false);
  }

  expand(): void {
    this.searchService.setExpanded(true);
  }

}
