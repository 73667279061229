import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TerminalsService} from "../../../../providers/terminals/terminals.service";
import {Terminal} from "../../../../dataset/Terminal";
import {Mall} from "../../../../dataset/Mall";
import {FormControl} from "@angular/forms";
import {format, parse} from "date-fns";
import {filter, flatMap, map, tap} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-screenshots',
  templateUrl: './screenshots.component.html',
  styleUrls: ['./screenshots.component.scss']
})
export class ScreenshotsComponent implements OnInit {

  screenshots: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  mall: Mall;
  terminal: Terminal;
  loading = false;

  dateControl = new FormControl();

  constructor(public route: ActivatedRoute,
              private router: Router,
              private terminalsService: TerminalsService) {
    this.terminal = this.route.snapshot.data.terminal;
    this.mall = this.route.snapshot.data.mall;
    this.dateControl.valueChanges.subscribe((value) => {
      if (value) {
        this.router.navigate([], {
          queryParams: {date: format(value, 'yyyy-MM-dd')},
          queryParamsHandling: 'merge'
        });
      }
    });

    this.route.queryParamMap
      .pipe(
        map(q => q.get('date')),
        filter(d => !!d),
        map(d => parse(d, 'yyyy-MM-dd', new Date())),
        tap(date => this.dateControl.setValue(date)),
        tap(() => this.loading = true),
        flatMap(date => this.terminalsService.screenshots(this.terminal._id, date)),
        tap(() => this.loading = false),
      )
      .subscribe(this.screenshots);

    const date = this.route.snapshot.queryParamMap.get('date');
    if (!date) {
      this.dateControl.setValue(new Date());
    }
  }

  async ngOnInit() {
  }

}
