import {Injectable} from '@angular/core';
import {PreSignedUploadService} from "../media/pre-signed-upload.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {isFileImage} from "../../../utils/file.utils";

@Injectable()
export class UploadService {

  constructor(private http: HttpClient,
              private preSignService: PreSignedUploadService) {
  }

  async upload(file: File): Promise<string> {
    const name = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const data = await this.preSignService.sign(name, file.type);
    const fileData = new FormData();
    Object.keys(data.fields).forEach(key => {
      fileData.append(key, data.fields[key]);
    });
    fileData.append('Content-Type', file.type);
    fileData.append('file', file);
    await this.http.post(data.url, fileData).toPromise();
    return `${data.url}/${data.fields.key}`;
  }

  previewUrl(file: File): Promise<{ url: string, width?: number, height?: number }> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (isFileImage(file.name)) {
          const image = new Image();
          image.src = <string>fileReader.result;

          image.onload = function() {
            resolve({
              url: <string>fileReader.result,
              width: image.width,
              height: image.height,
            });
          };
        } else {
          resolve({
            url: <string>fileReader.result,
          });
        }
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
      fileReader.readAsDataURL(file);
    });
  }

  prepareFile(file: File): FormData {
    const name = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const fileData = new FormData();
    fileData.append('Content-Type', file.type);
    fileData.append('file', file);
    return fileData;
  }

  uploadFileToChat(formData: any): Observable<any> {
    return this.http.post<any>('/chat/upload', formData);
  }

}
