import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {EditQrCodeComponent} from "./edit-qr-code/edit-qr-code.component";
import {QrCode} from "../../../../dataset/QrCode";
import {ActivatedRoute} from "@angular/router";
import {DialogService} from "../../../../common/alert-dialog/services/dialog.service";
import {QrCodesService} from "../../../../providers/qr-codes/qr-codes.service";
import {TranslocoService} from "@ngneat/transloco";
import {copyToBuffer} from "../../../../../utils/utils";
import {environment} from "../../../../../environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Mall} from "../../../../dataset/Mall";

@Component({
  selector: 'app-qr-codes',
  templateUrl: './qr-codes.component.html',
  styleUrls: ['./qr-codes.component.scss']
})
export class QrCodesComponent implements OnInit {

  @Input() qrCodes: QrCode[] = [];
  @Input() mall: Mall;

  constructor(private dialog: MatDialog,
              private dialogs: DialogService,
              private qrCodesService: QrCodesService,
              private translocoService: TranslocoService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  edit(qrCode?: QrCode): void {
    this.dialog.open(EditQrCodeComponent, {
      data: {
        qrCode,
        mallId: this.route.snapshot.data.mall._id,
      },
      minWidth: 500,
    }).afterClosed().subscribe(async result => {
      console.log(result);
      if (result) {
        if (qrCode) {
          const updatedCode = await this.qrCodesService.update(qrCode._id, result);
          Object.assign(qrCode, updatedCode);
        } else {
          this.qrCodes.push(result);
          console.log(result);
        }
      }
    });
  }

  async removeCode(code: QrCode, pos: number): Promise<void> {
    if (await this.dialogs.confirm({
      title: 'Delete?',
      message: `Are you sure you want to delete qr code?`
    })) {
      try {
        await this.qrCodesService.remove(code._id);
        const data = [...this.qrCodes];
        data.splice(pos, 1);
        this.qrCodes = data;
      } catch (e) {
        this.dialogs.error(e);
      }
    }
  }

  copyLink(code: QrCode): void {
    copyToBuffer(`${environment.qr_url}/${code._id}`);
    this.snackBar.open(this.translocoService.translate('value-copied'), this.translocoService.translate('close'), {
      duration: 2000,
    });
  }

  open(code: QrCode): void {
    window.open(`${environment.qr_url}/${code._id}`, '_blank');
  }

}
