import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AnalyticsService} from '../../providers/analytics/analytics.service';
import {MallsService} from "../../providers/malls/malls.service";
import {format, subMonths} from "date-fns";

@Injectable()
export class AnalyticsResolveService implements Resolve<void> {

  constructor(private analyticsService: AnalyticsService,
              private mallsService: MallsService, private router: Router) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    let {from, to} = route.queryParams;
    if (!from || !to) {
      from = format(subMonths(new Date(), 1), 'dd-MM-yyyy');
      to = format(new Date(), 'dd-MM-yyyy');
      const string = `/app/malls/${route.parent.params.id}/analytics?from=${from}&to=${to}`;
      this.router.navigateByUrl(string);
    }
  }

}
