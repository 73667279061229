import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Promo } from '../../dataset/Promo';
import {UploadService} from "../upload/upload.service";

@Injectable({
  providedIn: 'root'
})
export class ActivityPromoService {

  constructor(private http: HttpClient,
              private uploadService: UploadService) {
  }

  async getAll(activity: string): Promise<Promo[]> {
    return this.http.get<Promo[]>(`/activity-promo/promo-groups?activity=${activity}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Promo>(`/activity-promo/promo-group/quantity`, data).toPromise();
  }

  async delete(groupId: string) {
    return this.http.delete(`/activity-promo/promo-group/${groupId}`).toPromise();
  }

  async upload(groupId: string, file: File): Promise<Promo> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.post<Promo>(`/activity-promo/promo-group/${groupId}/img`, fileData).toPromise();
  }
}
