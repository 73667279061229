import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DirtyFormModalService } from '../../../../providers/dirty-form-modal/dirty-form-modal.service';
import { Terminal, TerminalMapType } from '../../../../dataset/Terminal';

@Component({
  selector: 'app-terminals-edit-modal',
  templateUrl: './terminals-edit-modal.component.html',
  styleUrls: ['./terminals-edit-modal.component.scss']
})
export class TerminalsEditModalComponent implements OnInit {

  form: FormGroup;
  terminalMapTypes = TerminalMapType;

  constructor(public dialogRef: MatDialogRef<TerminalsEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { terminal: Terminal },
              fb: FormBuilder,
              private dirtyFormService: DirtyFormModalService) {
    this.form = fb.group({
      name: [null, Validators.required],
      bearing: [0, Validators.required],
      orientation: [null, Validators.required],
      mapType: [this.terminalMapTypes.TYPE_2D, Validators.required],
      ip: null,
      hostname: null,
      video_source: null,
      isProd: false,
      comment: null,
      devtools: false,
      showCursor: false,
    });
    this.dirtyFormService.registerForm(this.form, dialogRef);
  }

  ngOnInit() {
    const {terminal} = this.data;
    if (terminal) {
      this.form.patchValue(terminal);
    }
  }

  confirm() {
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dirtyFormService.closeSafely();
  }

}
