import { SponsorGroup } from './../../dataset/SponsorGroup';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ActivitySponsorsService } from '../../providers/activity-sponsors/activity-sponsors.service';
import { Activity } from '../../dataset/Activity';

@Injectable({
  providedIn: 'root'
})
export class ActivitySponsorGroupsResolverService implements Resolve<SponsorGroup[]> {

  constructor(private sponsorsService: ActivitySponsorsService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<SponsorGroup[]> {
    const activity: Activity = route.parent.data.activity;
    return this.sponsorsService.getAllGroups(activity._id);
  }
}
