import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort, Sort} from "@angular/material/sort";
import {Mall} from "../../../dataset/Mall";
import {MatTableDataSource} from "@angular/material/table";
import {TerminalGroups} from "../../../dataset/TerminalGroups";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../common/alert-dialog/services/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {TerminalsService} from "../../../providers/terminals/terminals.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslocoService} from "@ngneat/transloco";
import {MallsService} from "../../../providers/malls/malls.service";
import {SearchService} from "../../../providers/search/search.service";
import {Terminal} from "../../../dataset/Terminal";
import {debounceTime, map, takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {filterFacade} from "../../../../utils/facade";
import {TerminalsGroupEditModalComponent} from "./terminals-group-edit-modal/terminals-group-edit-modal.component";
import {BannerGroup} from "../../../dataset/BannerGroup";
import {FacadePipe} from "../../../common/pipes/facade.pipe";

@UntilDestroy()
@Component({
  selector: 'app-terminal-groups',
  templateUrl: './terminal-groups.component.html',
  styleUrls: ['./terminal-groups.component.scss']
})
export class TerminalGroupsComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  mall: Mall;
  bannerGroups: BannerGroup[];
  terminals: Terminal[];
  displayedColumns = [
    'name',
    'orientation',
    'bannerGroups',
    'terminals',
    'delete',
    'edit',
  ];
  locale = 'ru';
  loading = false;
  dataSource = new MatTableDataSource<TerminalGroups>();
  private $stop = new Subject();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialogs: DialogService,
              private dialog: MatDialog,
              private terminalsService: TerminalsService,
              protected sanitizer: DomSanitizer,
              private snackBar: MatSnackBar,
              private translocoService: TranslocoService,
              private facadePipe: FacadePipe,
              private mallService: MallsService,
              private searchService: SearchService) {
    this.route.data.subscribe((data: { mall: Mall, terminalGroups: TerminalGroups[], bannerGroups: BannerGroup[], terminals: Terminal[] }) => {
      this.mall = data.mall;
      this.dataSource.data = data.terminalGroups;
      this.bannerGroups = data.bannerGroups;
      this.terminals = data.terminals;
    });
    this.searchService.textObservable
      .pipe(takeUntil(this.$stop), debounceTime(200))
      .subscribe(searchToken => {
        this.router.navigate([], {
          queryParams: {searchToken},
          queryParamsHandling: 'merge'
        });
      });
    this.translocoService.langChanges$
      .pipe(untilDestroyed(this))
      .subscribe(val => this.locale = val);
    this.dataSource.filterPredicate = (data: TerminalGroups, filter: string) => filterFacade<TerminalGroups>(data, this.locale, filter);
    this.dataSource.sortingDataAccessor = (item: TerminalGroups, property) => {
      switch (property) {
        case 'name':
          return item.facade[this.locale]?.name;
        default:
          return item[property];
      }
    };
    this.route.queryParamMap
      .pipe(
        map(m => m.get('searchToken')),
        tap(v => this.searchService.setText(v))
      )
      .subscribe(searchToken => this.dataSource.filter = searchToken);
    this.route.queryParamMap.subscribe(params => {
      if (params.has('create')) {
        this.edit();
        return;
      }
      if (params.has('edit')) {
        const terminalGroup = this.dataSource.data.find(item => item._id === params.get('edit'));
        if (!terminalGroup) {
          this.dialogs.alert({
            title: 'Error',
            message: 'The terminals` group has not found',
          });
          return;
        }
        this.edit(terminalGroup);
      }
    });
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    const sortState: Sort = {active: 'name', direction: 'asc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  ngOnDestroy() {
    this.searchService.setExpanded(false);
    this.$stop.complete();
  }

  async refresh() {
    this.loading = true;
    try {
      const data = await Promise.all([
        this.terminalsService.getGroups(this.mall._id),
        this.mallService.get(this.mall._id),
      ]);
      this.dataSource.data = data[0];
      this.mall = data[1];
    } catch (err) {
      this.dialogs.error(err);
    }
    this.loading = false;
  }

  async edit(terminalGroup?: TerminalGroups) {
    try {
      const ref = this.dialog.open(TerminalsGroupEditModalComponent, {
        data: {
          terminalGroup,
          mall: this.mall,
          bannerGroups: this.bannerGroups,
          terminals: this.terminals.filter(item => !item.group || item.group === terminalGroup?._id)},
          minWidth: '300px'
      });
      const result: TerminalGroups = await ref.afterClosed().toPromise();
      this.router.navigate([], { queryParams: null });
      if (result) {
        if (terminalGroup) {
          const updatedTerminal = await this.terminalsService.updateGroup(terminalGroup._id, result);
          Object.assign(terminalGroup, updatedTerminal);
        } else {
          result.mall = this.mall._id;
          const createdTerminal = await this.terminalsService.createGroup(result);
          const data = this.dataSource.data;
          data.push(createdTerminal);
          this.dataSource.data = data;
        }
      }
    } catch (err) {
      this.dialogs.error(err);
      this.router.navigate([], { queryParams: null });
    }
  }

  async remove($event: Event, terminalGroup: TerminalGroups) {
    $event.stopPropagation();
    try {
      if (await this.dialogs.confirm({
        title: this.translocoService.translate('are-you-sure'),
        message: this.translocoService.translate('remove', {item: this.facadePipe.transform(terminalGroup.facade, this.locale)}),
      })) {
        await this.terminalsService.deleteGroup(terminalGroup._id);
        const data = this.dataSource.data;
        const index = data.indexOf(terminalGroup);
        data.splice(index, 1);
        this.dataSource.data = data;
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

  getBannerGroupsNames(groups: string[]): string {
    if (!groups?.length || !this.bannerGroups?.length) return '';

    return this.bannerGroups.filter(item => groups.includes(item._id)).map(group => group.name).join(', ');
  }

  getTerminalNames(terminals: string[]): string {
    if (!terminals?.length || !this.terminals?.length) return '';

    return this.terminals.filter(item => terminals.includes(item._id)).map(terminal => terminal.name).join(', ');
  }

  getOrientationName(name: string): string {
    if (name === 'p') return 'Vertical';
    return 'Horizontal';
  }

}
