import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Banner } from '../../dataset/Banner';
import { BannersService } from '../../providers/banners/banners.service';
import { Mall } from '../../dataset/Mall';

@Injectable()
export class BannersResolver implements Resolve<Banner[]> {

  constructor(private bannersService: BannersService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Banner[]> {
    const mall: Mall = route.parent.data.mall;
    return await this.bannersService.getAll(mall._id);
  }

}
