import { Injectable } from '@angular/core';
import { ShopsService } from '../../providers/shops/shops.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Shop } from '../../dataset/Shop';

@Injectable({
  providedIn: 'root'
})
export class ShopResolveService implements Resolve<Shop> {

  constructor(private shopsService: ShopsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Shop> {
    return await this.shopsService.get(route.paramMap.get('id'));
  }
}
