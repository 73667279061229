import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { CommonModule } from '../../../app/common/common.module';

import { FuseSearchBarComponent } from './search-bar.component';

@NgModule({
  declarations: [
    FuseSearchBarComponent
  ],
  imports: [
    AngularCommonModule,
    CommonModule,
  ],
  exports: [
    FuseSearchBarComponent
  ]
})
export class FuseSearchBarModule {
}
