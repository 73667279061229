import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../common/alert-dialog/services/dialog.service';
import { ManageAccountsService } from '../providers/manage-accounts/manage-accounts.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  form: FormGroup;
  inviteId: string;

  constructor(private router: Router,
              private fb: FormBuilder,
              private dialog: DialogService,
              private manageAccountsService: ManageAccountsService,
              private translocoService: TranslocoService,
              private route: ActivatedRoute) {
    this.route.queryParams.subscribe((data: {inviteId: string}) => {
      this.inviteId = data.inviteId;
    });
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit() {
  }

  async send() {
    this.form.disable();
    try {
      await this.manageAccountsService.confirmInvite({
        ...this.form.value,
        inviteId: this.inviteId,
      });
      await this.dialog.alert({
        title: this.translocoService.translate('success'),
        message: this.translocoService.translate('invite-accepted')
      });
      this.router.navigateByUrl('/auth/sign-in');
    } catch (err) {
      this.dialog.error(err);
    }
    this.form.enable();
  }

}
