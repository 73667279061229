import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Shop} from "../../dataset/Shop";
import {ShopsService} from "../../providers/shops/shops.service";
import {Mall} from "../../dataset/Mall";

@Injectable()
export class MallShopsResolveService implements Resolve<Shop[]> {

  constructor(private shopsService: ShopsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Shop[]> {
    const mall: Mall = route.parent.data.mall;
    return await this.shopsService.getAll(mall._id);
  }

}
