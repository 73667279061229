import { NgModule } from '@angular/core';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import { CommonModule } from "../common/common.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FlexModule } from "@angular/flex-layout";
import { TranslocoModule } from "@ngneat/transloco";
import { FuseModule } from '@fuse/fuse.module';
import { fuseConfig } from '@fuse';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { LayoutModule } from '../layout/layout.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MallModule } from './mall/mall.module';

@NgModule({
  imports: [
    CommonModule,
    RootRoutingModule,
    MatNativeDateModule,
    DragDropModule,
    FlexModule,
    TranslocoModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    // App modules
    LayoutModule,
    MallModule,
  ],
  declarations: [
    RootComponent,
  ],
})
export class RootModule {
}
