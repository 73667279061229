import {Injectable} from '@angular/core';
import {MallsService} from "../../providers/malls/malls.service";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Mall} from "../../dataset/Mall";

@Injectable()
export class MallsResolveService implements Resolve<Mall[]> {

  constructor(private mallsApi: MallsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Mall[]> {
    return await this.mallsApi.getAll();
  }

}
