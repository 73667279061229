import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {UploadService} from '../../../../providers/upload/upload.service';
import {Category, CategoryActionNames} from '../../../../dataset/Category';
import {CategoriesService} from '../../../../providers/categories/categories.service';
import {Mall} from '../../../../dataset/Mall';
import {map} from "lodash";
import { DirtyFormModalService } from '../../../../providers/dirty-form-modal/dirty-form-modal.service';
import { TranslocoService } from '@ngneat/transloco';
import {fileSizeValidator, FileSizeValidatorResults} from "../../../../../utils/file.utils";
import {DialogService} from "../../../../common/alert-dialog/services/dialog.service";

@Component({
  selector: 'app-catalog-edit-modal',
  templateUrl: './catalog-edit-modal.component.html',
  styleUrls: ['./catalog-edit-modal.component.scss']
})
export class CatalogEditModalComponent implements OnInit, OnDestroy {

  form: FormGroup;
  coverFile: File;
  coverFileUrl: SafeUrl;

  iconFile: File;
  iconFileUrl: SafeUrl;

  loading = false;
  locales: string[] = [];
  categories: Category[] = [];
  locale = 'en';
  actions = Object.keys(CategoryActionNames);
  categoryActionNames = CategoryActionNames;

  get facades(): FormArray {
    return this.form.get('facade') as FormArray;
  }

  constructor(public dialogRef: MatDialogRef<CatalogEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ({ mall: Mall, category: Category }),
              private fb: FormBuilder,
              private uploadService: UploadService,
              private categoriesService: CategoriesService,
              private dialogs: DialogService,
              private dirtyFormService: DirtyFormModalService,
              private translocoService: TranslocoService,
              private domSanitizer: DomSanitizer) {
  }

  async ngOnInit() {
    const {mall} = this.data;
    this.locale = mall.settings?.default_locale || this.translocoService.getActiveLang();
    this.locales = mall.settings.frontend_available_locales || [this.locale];
    this.categories = await this.categoriesService.getAll(mall._id);
    this.form = this.fb.group({
      facade: this.fb.array([this.fb.group({
        __locale: this.locale,
        name: [null, Validators.required]
      })]),
      order: null,
      orderOnMain: null,
      showOnMain: false,
      color: ['#ffdd00', Validators.required],
      externalId: null,
      parent: null,
      action: this.fb.group({
        name: null,
        payload: null,
      })
    });
    this.dirtyFormService.registerForm(this.form, this.dialogRef);
    const category: Category = this.data.category;
    if (category) {
      this.categories = this.categories.filter(c => c._id !== category._id);
      const data = Object.assign({}, category);
      delete data.facade;
      if (!data.action) {
        delete data.action;
      }
      this.form.patchValue(data);
      const facades = this.fb.array(map(category.facade, (facade, __locale) => this.fb.group({__locale, ...facade})));
      this.form.setControl('facade', facades);
      this.coverFileUrl = category.cover ? category.cover : null;
      this.iconFileUrl = category.icon ? category.icon : null;
    }
  }

  async changeCover($event: Event) {
    this.dirtyFormService.forceDirtyForm();
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const file = target.files.item(0);
    if (fileSizeValidator(file) !== FileSizeValidatorResults.VALID) {
      const message = this.translocoService.translate(
        fileSizeValidator(file) === FileSizeValidatorResults.INVALID_SIZE ?
          'incorrect-file-size' :
          'incorrect-file-extension'
      );
      this.dialogs.alert(
        {
          title: `${this.translocoService.translate('error')} - ${file.name}`,
          message,
        });
      return;
    }
    const {url} = await this.uploadService.previewUrl(file);
    this.coverFile = file;
    this.coverFileUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  async changeIcon($event: Event) {
    this.dirtyFormService.forceDirtyForm();
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const file = target.files.item(0);
    if (fileSizeValidator(file) !== FileSizeValidatorResults.VALID) {
      const message = this.translocoService.translate(
        fileSizeValidator(file) === FileSizeValidatorResults.INVALID_SIZE ?
          'incorrect-file-size' :
          'incorrect-file-extension'
      );
      this.dialogs.alert(
        {
          title: `${this.translocoService.translate('error')} - ${file.name}`,
          message,
        });
      return;
    }
    const {url} = await this.uploadService.previewUrl(file);
    this.iconFile = file;
    this.iconFileUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  async confirm() {
    this.loading = true;
    try {
      const facade = this.facades.controls.reduce((last, control) => {
        const {__locale} = control.value;
        delete control.value.__locale;
        last[__locale] = control.value;
        return last;
      }, {});
      const category: Category = Object.assign(this.form.value, {
        mall: this.data.mall._id,
        cover: this.coverFile ? null : this.coverFileUrl,
        icon: this.iconFile ? null : this.iconFileUrl,
        facade
      });
      if (!category.action?.name) {
        category.action = null;
      }
      let res;
      if (this.data.category) {
        res = await this.categoriesService.update(this.data.category._id, category);
      } else {
        res = await this.categoriesService.create(category);
      }
      if (this.coverFile) {
        res = await this.categoriesService.upload(res._id, this.coverFile, 'cover');
      }
      if (this.iconFile) {
        res = await this.categoriesService.upload(res._id, this.iconFile, 'icon');
      }
      this.dialogRef.close(res);
    } catch (err) {
      alert(err.message);
    }
    this.loading = false;

  }

  hasFacade(__locale: string): boolean {
    return this.facades.controls.filter(c => c.value.__locale === __locale).length !== 0;
  }

  removeFacade(index: number) {
    this.facades.removeAt(index);
  }

  addFacade(__locale: string) {
    this.facades.push(this.fb.group({__locale, name: [null, Validators.required]}));
  }

  localeName(__locale: string) {
    return __locale;
  }

  localsCount(): number {
    return this.locales.length;
  }

  cancel() {
    this.dirtyFormService.closeSafely();
  }

  removeIcon() {
    this.iconFile = null;
    this.iconFileUrl = null;
  }

  removeCover() {
    this.coverFile = null;
    this.coverFileUrl = null;
  }

  ngOnDestroy() {
  }

}
