import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { UserService } from '../../../providers/user/user.service';
import { User } from '../../../dataset/User';
import { TranslocoService } from '@ngneat/transloco';
import { NavigationService } from '../../../providers/navigation/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../../../common/alert-dialog/services/dialog.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  user: User;
  isAdmin = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private translocoService: TranslocoService,
    private navigationService: NavigationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogs: DialogService,
  ) {
    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
      {
        id: 'ru',
        title: 'Russian',
        flag: 'ru',
      },
      {
        id: 'ar',
        title: 'Arabian',
        flag: 'ar',
      },
      {
        id: 'fr',
        title: 'French',
        flag: 'fr',
      },
    ];

    this.user = this.userService.user;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });
    this.userService.subscribe(user => {
      this.isAdmin = this.userService.userRoles.isAdmin;
    });

    // Set the selected language from default languages
    const activeLang = this.translocoService.getActiveLang();
    this.selectedLanguage = this.languages.find(lang => lang.id === activeLang);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;
    this.translocoService.setActiveLang(lang.id);
    localStorage.setItem('lang', lang.id);
  }

  async logout() {
    if (await this.dialogs.confirm({
      title: "Logout",
      message: "Are your sure?"
    })) {
      await this.userService.logout();
      this.router.navigateByUrl('/auth/sign-in');
    }
  }
}
