import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {

  static throwError(message: string, code?: string) {
    const error = new Error(message);
    error.name = code || "BackendError";
    return throwError(error);
  }

  static fixURL(url: string) {
    if (url.startsWith('https://amplitude.com')) {
      return url;
    }
    if (url.startsWith('/assets')) {
      return url;
    }
    if (!url.startsWith('http')) {
      return url.startsWith('/channel')
        ? environment.chat_server + (url.startsWith('/') ? '' : '/') + url
        : environment.api_server + (url.startsWith('/') ? '' : '/') + url;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const url = ApiInterceptorService.fixURL(req.url);

    const accessToken = localStorage.getItem("access_token");

    if (req.url && !req.url.startsWith('https://amplitude.com')) {
      req = req.clone({
        url: url,
        ...(accessToken ? {
          headers: new HttpHeaders({
            "X-Access-Token": accessToken
          })
        } : {})
      });
    } else {
      return next.handle(req);
    }

    if (req.url && !req.url.startsWith('https://amplitude.com')) {
      return next.handle(req)
        .pipe(
          catchError(httpErrorResponse => {
            if (httpErrorResponse.error) {

              const error = httpErrorResponse.error;

              if (error.message) {
                return ApiInterceptorService.throwError(error.message);
              }
            }
            return ApiInterceptorService.throwError('Server unavailable, please contact support for details');
          })
        );
    } else {
      return next.handle(req);
    }
  }
}
