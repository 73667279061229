import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TracksService } from '../../providers/tracks/tracks.service';
import { Track } from '../../dataset/Track';

@Injectable({
  providedIn: 'root'
})
export class ActivityTracksResolverService implements Resolve<Track[]> {

  constructor(private service: TracksService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Track[]> {
    return this.service.getAll(route.parent.paramMap.get('id'));
  }
}
