import { BrowserModule, HammerModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {MaterialModule} from "./common/material/material.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NotFoundComponent} from "./not-found/not-found.component";
import {AppRoutingModule} from "./app-routing.module";
import {RootModule} from "./root/root.module";
import {AuthGuard} from "./resolvers/auth.guard";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ApiInterceptorService} from "./providers/api-interceptor/api-interceptor.service";
import {RavenErrorHandler} from "./raven-error-handler";
import {UserService} from "./providers/user/user.service";
import {UserResolver} from "./resolvers/user.resolver";
import {UsersService} from "./providers/users/users.service";
import {MallsService} from './providers/malls/malls.service';
import {MallResolveService} from "./resolvers/mall/mall-resolve.service";
import {ShopsService} from './providers/shops/shops.service';
import {MallShopsResolveService} from "./resolvers/mall-shops/mall-shops-resolve.service";
import {MallTerminalsResolveService} from './resolvers/mall-terminals/mall-terminals-resolve.service';
import {TerminalsService} from './providers/terminals/terminals.service';
import {MallsResolveService} from "./resolvers/malls/malls-resolve.service";
import {AnalyticsService} from './providers/analytics/analytics.service';
import {AnalyticsResolveService} from './resolvers/analytics/analytics-resolve.service';
import {PreSignedUploadService} from "./providers/media/pre-signed-upload.service";
import {UploadService} from "./providers/upload/upload.service";
import {BannersService} from './providers/banners/banners.service';
import {BannersResolver} from './resolvers/banners/banners-resolver.service';
import {CategoriesService} from './providers/categories/categories.service';
import {CategoriesResolveService} from './resolvers/categories/categories-resolve.service';
import {MarketingService} from './providers/marketing/marketing.service';
import {MarketingResolveService} from './resolvers/marketing/marketing-resolve.service';
import {DatasetService} from "./providers/dataset-service/dataset.service";
import {MapParserService} from "./providers/map-parser/map-parser.service";
import {TerminalResolveService} from "./resolvers/terminal/terminal-resolve.service";
import { TranslocoRootModule } from './transloco-root.module';
import { FuseModule } from '@fuse/fuse.module';
import { fuseConfig } from '@fuse';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { FacadePipe } from './common/pipes/facade.pipe';
import {MeetingsService} from "./providers/meetings/meetings.service";
import { ActivityFilesResolver } from './resolvers/activity-files/activity-files-resolver';
import { ShopResolveService } from './resolvers/shop/shop-resolve.service';
import { CountriesService } from './providers/countries/countries.service';
import { CountriesResolveService } from './resolvers/countries/countries-resolve.service';
import { ActivitySupportFilesResolveService } from './resolvers/activity-support-files/activity-support-files-resolve.service';
import { ManageAccountsService } from './providers/manage-accounts/manage-accounts.service';
import { ActivityRolesResolverService } from './resolvers/activity-roles/activity-roles-resolver.service';
import { ActivityMeetingPlacesResolveService } from './resolvers/activity-meeting-places/activity-meeting-places-resolve.service';
import { ActivityTracksResolverService } from './resolvers/activity-tracks/activity-tracks-resolver.service';
import { ActivityPromoService } from './providers/activity-promo/activity-promo.service';
import { ActivityPromoResolverService } from './resolvers/activity-promo/activity-promo-resolver.service';
import { ActivityTariffsService } from './providers/activity-tariffs/activity-tariffs.service';
import { ActivityPushesService } from './providers/activity-pushes/activity-pushes.service';
import { ActivityPushesResolveService } from './resolvers/activity-pushes/activity-pushes-resolve.service';
import { ActivitySectionsService } from './providers/activity-sections/activity-sections.service';
import { ActivitySectionsResolverService } from './resolvers/activity-sections/activity-sections-resolver.service';
import { ActivityEmailsResolveService } from './resolvers/activity-emails/activity-emails-resolve.service';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { QuillModule } from 'ngx-quill';
import { ActivityContactsService } from './providers/activity-contacts/activity-contacts.service';
import { ActivityContactsResolverService } from './resolvers/activity-contacts/activity-contacts-resolver.service';
import { MallAdminsResolverService } from './resolvers/mall-admins/mall-admins-resolver.service';
import { ActivityHallResolveService } from './resolvers/hall-resolver/hall-resolve.service';
import { getDefaultQuillConfig } from '../utils/quill';
import { ActivitySpeakerSessionsResolverService } from './resolvers/activity-speaker-sessions/activity-speaker-sessions-resolver.service';
import { InviteModule } from './invite/invite.module';
import { ActivitySponsorsService } from './providers/activity-sponsors/activity-sponsors.service';
import { ActivitySponsorsResolverService } from './resolvers/activity-sponsors/activity-sponsors-resolver.service';
import { AdminGuard } from './resolvers/admin.guard';
import { SegmentModule } from 'ngx-segment-analytics';
import { ActivitySponsorGroupsResolverService } from './resolvers/activity-sponsor-groups/activity-sponsor-groups-resolver.service';
import {BrandsResolveService} from "./resolvers/brands/brands-resolve.service";
import {AuthModule} from "./auth/auth.module";

const apiInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiInterceptorService,
  multi: true
};

const errorHandler = {
  provide: ErrorHandler,
  useClass: RavenErrorHandler
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InviteModule,
    RootModule,
    AppRoutingModule,
    MaterialModule,
    TranslocoRootModule,
    AuthModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    NgxQRCodeModule,
    HammerModule,
    QuillModule.forRoot(getDefaultQuillConfig()),
    SegmentModule.forRoot({ apiKey: 'NDXiwD48DnGvLhHLB6pWFqYihf8FGKUz', debug: true, loadOnInitialization: true })
  ],
  providers: [
    apiInterceptor,
    errorHandler,
    AuthGuard,
    AdminGuard,
    UserService,
    UserResolver,
    UsersService,
    MallsService,
    MallsResolveService,
    TerminalResolveService,
    MallResolveService,
    ShopsService,
    MallShopsResolveService,
    TerminalsService,
    MallTerminalsResolveService,
    AnalyticsService,
    AnalyticsResolveService,
    PreSignedUploadService,
    UploadService,
    BannersService,
    BannersResolver,
    CategoriesService,
    CategoriesResolveService,
    MarketingService,
    MarketingResolveService,
    DatasetService,
    MapParserService,
    FacadePipe,
    MeetingsService,
    ActivityMeetingPlacesResolveService,
    ActivityFilesResolver,
    ActivityTracksResolverService,
    ShopResolveService,
    CountriesService,
    CountriesResolveService,
    BrandsResolveService,
    ActivitySupportFilesResolveService,
    ManageAccountsService,
    ActivityRolesResolverService,
    ActivityPromoService,
    ActivityPromoResolverService,
    ActivityTariffsService,
    ActivityPushesService,
    ActivityPushesResolveService,
    ActivitySectionsService,
    ActivitySectionsResolverService,
    ActivityEmailsResolveService,
    ActivityContactsService,
    ActivityContactsResolverService,
    MallAdminsResolverService,
    ActivityHallResolveService,
    ActivitySpeakerSessionsResolverService,
    ActivitySponsorsService,
    ActivitySponsorsResolverService,
    ActivitySponsorGroupsResolverService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
