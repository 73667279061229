export interface Position {
  x: number;
  y: number;
}

export enum TerminalMapType {
  TYPE_2D= '2d',
  TYPE_3D= '3d'
}

export enum TerminalCommands {
  RESTART = 'restart',
  REBOOT = 'reboot',
}


export interface Terminal {
  _id: string;
  name: string;
  mall: string;
  floor: number;
  position: Position;
  mapId: string;
  hostname: string;
  group: string;
  last_ping_delta: number;
  front_ping_delta: number;
  started_at_delta: number;
  frontend_version: string;
  wrapper_version?: string;
  terminalMapType: TerminalMapType;
  token?: string;
  commands: TerminalCommands[];
  ips?: string[];
  comment?: string;
}
