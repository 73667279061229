import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService } from '../../common/alert-dialog/services/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class DirtyFormModalService {

  private form: FormGroup = null;
  private modalRef?: MatDialogRef<any> = null;
  private onBackdropClick: Subscription;
  private onClose: Subscription;
  // if we need custom fields outside of form
  private forceDirty = false;

  constructor(private dialogs: DialogService,
              private translocoService: TranslocoService) {
  }

  registerForm(form: FormGroup, modalRef?: MatDialogRef<any>) {
    this.form = form;
    if (modalRef) {
      this.modalRef = modalRef;
      modalRef.disableClose = true;
      this.onBackdropClick = modalRef.backdropClick().subscribe(() => this.closeSafely());
      this.onClose = modalRef.afterClosed().subscribe(() => this.close());
    }
  }

  // TODO transloco
  async closeSafely() {
    if (this.form.dirty || this.forceDirty) {
      this.translocoService.getActiveLang();
      const close = await this.dialogs.confirm({
        title: this.translocoService.translate('are-you-sure'),
        message: this.translocoService.translate('unsaved-lost')
      });
      if (close) {
        this.close();
      }
    } else {
      this.close();
    }
  }

  forceDirtyForm() {
    this.forceDirty = true;
  }

  private close() {
    if (this.modalRef) {
      this.modalRef.disableClose = false;
      this.modalRef.close();
      this.onBackdropClick.unsubscribe();
      this.onClose.unsubscribe();
      this.modalRef = null;
    }
    this.form = null;
    this.forceDirty = false;
  }
}
