import { ErrorHandler, Injectable } from '@angular/core';
import {environment} from '../environments/environment';
import {captureException, init, showReportDialog} from "@sentry/browser";


@Injectable()
export class RavenErrorHandler implements ErrorHandler {

  constructor() {
    if (environment.sentry) {
      init({
        dsn: environment.sentry,
        release: environment.version,
      });
    }
  }

  handleError(error) {
    if (environment.sentry) {
      const eventId = captureException(error.originalError || error);
      showReportDialog({eventId});
    } else {
      console.error(error);
    }
  }
}
