import {find, sumBy} from 'lodash';
import {GraphRouteSegment} from './GraphRouteSegment';
import {GraphLine} from './GraphLine';

export class GraphRoute {

  public distance: number;
  private segments: GraphRouteSegment[] = [];

  constructor(lines: GraphLine[]) {
    if (lines.length) {
      this.distance = sumBy(lines, l => l.distance);
      let segmentLines: GraphLine[] = [];
      let lastFloor = lines[0].fromFloor;
      lines.forEach((line: GraphLine, index: number, array: GraphLine[]) => {
        if (lastFloor === line.toFloor) {
          segmentLines.push(line);
          if (array.length - 1 === index) {
            this.segments.push(new GraphRouteSegment(segmentLines, lastFloor, null));
          }
        } else {
          this.segments.push(new GraphRouteSegment(segmentLines, lastFloor, line.toFloor + ''));
          lastFloor = line.toFloor;
          segmentLines = [];
        }
      });

      const toDelete: GraphRouteSegment[] = [];
      let lastSegment = this.segments[0];
      for (let i = 1; i < this.segments.length; i++) {
        const current = this.segments[i];
        if (!current.path) {
          toDelete.push(current);
          lastSegment.toFloor = current.toFloor;
        } else {
          lastSegment = current;
        }
      }
      this.segments = this.segments.filter(s => !toDelete.includes(s));
    }
  }

  getSegment(floor: number): GraphRouteSegment {
    return find(this.segments, s => s.floor === floor);
  }

  getPrevSegment(floor: number): GraphRouteSegment {
    const segment = find(this.segments, s => s.floor === floor);
    const index = this.segments.indexOf(segment);
    if (index !== -1 && index !== 0) {
      return this.segments[index - 1];
    }
  }
}
