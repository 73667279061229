import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-file-button',
  templateUrl: './file-button.component.html',
  styleUrls: ['./file-button.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileButtonComponent),
    multi: true
  }]
})
export class FileButtonComponent implements OnInit, ControlValueAccessor {

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  @Output() fileChange = new EventEmitter<File>();

  disabled = false;
  private _file: File;

  get file() {
    return this._file;
  }

  @Input()
  set file(file: File) {
    this._file = file;
    this.fileChange.emit(file);
    if (this.onChange) {
      this.onChange(file);
    }
  }

  private onChange: Function;
  private onTouched: Function;

  constructor() {
  }

  ngOnInit() {
  }

  select() {
    this.fileInput.nativeElement.click();
  }

  async fileSelected($event: Event) {
    const input = ($event.target || $event.srcElement) as HTMLInputElement;
    this.file = input.files.item(0);
    input.value = "";
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.file = obj;
  }

}
