import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {idGen} from "../../../../dataset/map/Graph";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {map} from "rxjs/operators";
import {QrCode} from "../../../../dataset/QrCode";
import {QrCodesService} from "../../../../providers/qr-codes/qr-codes.service";

@UntilDestroy()
@Component({
  selector: 'app-attach-qr-modal',
  templateUrl: './attach-qr-modal.component.html',
  styleUrls: ['./attach-qr-modal.component.scss']
})
export class AttachQrModalComponent implements OnInit, OnDestroy {

  qrCodes: { qrCode: QrCode; attached: boolean; boundToThisPoint: boolean }[] = [];
  filteredQrCodes: BehaviorSubject<{ qrCode: QrCode; attached: boolean; boundToThisPoint: boolean }[]>;
  searchControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<AttachQrModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { mall: string; nodes: string[]; id: string },
              private qrCodesService: QrCodesService) {
  }

  async ngOnInit() {
    const id = t => t.position ? idGen(t.position.x, t.position.y, t.floor) : undefined;
    const data = await this.qrCodesService.getAll(this.data.mall);
    this.qrCodes = data.map(qrCode => ({
      qrCode,
      attached: this.data.nodes.includes(id(qrCode)),
      boundToThisPoint: id(qrCode) === this.data.id,
    }));
    const attached = (a, b) => a.attached === b.attached;
    this.qrCodes.sort((a, b) => attached(a, b) ? 0 : a.attached ? 1 : 0);
    this.filteredQrCodes = new BehaviorSubject(this.qrCodes);
    this.searchControl.valueChanges
      .pipe(
        untilDestroyed(this),
        map(v => this.qrCodes.filter(s => s.qrCode.title.toLowerCase().indexOf(v.toLowerCase()) !== -1))
      )
      .subscribe(this.filteredQrCodes);
  }

  select(qrCode: QrCode) {
    this.dialogRef.close(qrCode);
  }

  ngOnDestroy(): void {
  }

}
