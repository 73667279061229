import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Mall} from "../../dataset/Mall";
import {QrCodesService} from "../../providers/qr-codes/qr-codes.service";
import {QrCode} from "../../dataset/QrCode";

@Injectable({
  providedIn: 'root'
})
export class QrCodesResolver implements Resolve<QrCode[]> {

  constructor(private qrService: QrCodesService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<QrCode[]> {
    const mall: Mall = route.parent.data.mall;
    return await this.qrService.getAll(mall._id);
  }
}
