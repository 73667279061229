import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Track } from '../../dataset/Track';

@Injectable({
  providedIn: 'root'
})
export class TracksService {

  constructor(private http: HttpClient) {
  }

  async getAll(activityId: string): Promise<Track[]> {
    return this.http.get<Track[]>(`/tracks/${activityId}`).toPromise();
  }

  async create(activityId: string, data: Track) {
    return this.http.post<Track>(`/track/${activityId}`, data).toPromise();
  }

  async update(id: string, data: Track): Promise<Track> {
    return this.http.put<Track>(`/track/${id}`, data).toPromise();
  }

  async delete(trackId: string) {
    return this.http.delete(`/track/${trackId}`).toPromise();
  }
}
