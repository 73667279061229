import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Brands } from "../../dataset/Brands";
import {UploadService} from "../upload/upload.service";

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  constructor(private http: HttpClient,
              private uploadService: UploadService) { }

  async getAll(): Promise<Brands[]> {
    return this.http.get<Brands[]>('/brands').toPromise();
  }

  async get(id: string): Promise<Brands> {
    return this.http.get<Brands>(`/brands/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Brands>(`/brands`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<Brands>(`/brands/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<Brands>(`/brands/${id}`).toPromise();
  }

  async upload(brandId: string, file: File): Promise<Brands> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.post<Brands>(`/brands/${brandId}/logo`, fileData).toPromise();
  }
}
