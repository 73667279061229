/**
 * Результат ответа
 * */
export enum FileSizeValidatorResults {
  VALID,
  INVALID_SIZE,
  INVALID_FORMAT,
}

export function isFileImage(fileName: string) {
  return /\.(jpe?g|png|gif|svg|webp)$/i.test(fileName);
}

export function isFileVideo(fileName: string) {
  return /\.(mp4|avi|mpe?g|wmv|webm)$/i.test(fileName);
}

/*
* Проверяет размер фото или видео файла
* @file: File - файл
* @skipFormatCheck: boolean - пропустить проверку формата файла
**/
export const fileSizeValidator = (file: File, skipFormatCheck?: boolean): FileSizeValidatorResults => {
  // Максимальный размер изображения (3 Мб)
  const maxImgFileSize = 3145728;
  // Максимальный размер видео (300 Мб)
  const maxVideoFileSize = 314572800;

  const isImage = isFileImage(file.name);
  const isVideo = isFileVideo(file.name);

  if (!isVideo && !isImage && !skipFormatCheck) return FileSizeValidatorResults.INVALID_FORMAT;
  if ((isImage && file.size < maxImgFileSize) ||
    (isVideo && file.size < maxVideoFileSize) ||
    (!isVideo && !isImage && skipFormatCheck))
    return FileSizeValidatorResults.VALID;

  return FileSizeValidatorResults.INVALID_SIZE
}
