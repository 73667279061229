import {Component} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent {

  public readonly days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  readonly form: FormGroup = new FormGroup(this.days.reduce((prev, current) => {
    prev[current] = new FormGroup({
      active: new FormControl(false),
      workingHours: new FormArray([]),
      breaks: new FormArray([]),
    });
    return prev;
  }, {}));

  getDayWorkingHoursControl(day: string): FormArray {
    return (this.form.get(day) as FormGroup).get('workingHours') as FormArray;
  }

  getDayBreaksControl(day: string): FormArray {
    return (this.form.get(day) as FormGroup).get('breaks') as FormArray;
  }

  addWorkingHour(day: string) {
    this.getDayWorkingHoursControl(day).controls.push(this.getTimeControl());
  }

  addBreak(day: string) {
    this.getDayBreaksControl(day).controls.push(this.getTimeControl());
  }

  removeWorkingHours(day: string, index: number) {
    this.getDayWorkingHoursControl(day).removeAt(index);
  }

  removeBreaks(day: string, index: number) {
    this.getDayBreaksControl(day).removeAt(index);
  }

  private getTimeControl(): FormGroup {
    return new FormGroup({
      from: new FormControl(null, Validators.required),
      to: new FormControl(null, Validators.required),
    });
  }

  getCurrentValue() {
    const val = this.form.getRawValue();
    let returnNull = true;
    Object.keys(val).forEach(key => {
      if (val[key].workingHours.length > 0 || val[key].breaks.length > 0) {
        returnNull = false;
      }
      if (!val[key].active) {
        delete val[key];
      } else {
        delete val[key].active;
      }
    });
    return returnNull ? null : val;
  }

  setValue(val) {
    if (!val) {
      return;
    }
    this.form.reset();
    Object.keys(val).forEach(key => {
      if (val[key].workingHours.length > 0) {
        val[key].workingHours.forEach(() => this.addWorkingHour(key));
        val[key].active = true;
      }
      if (val[key].breaks.length > 0) {
        val[key].breaks.forEach(() => this.addBreak(key));
      }
    });
    this.form.patchValue(val);
  }
}
