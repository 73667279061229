import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Mall, MallTheme} from '../../dataset/Mall';
import { Collaborator } from '../../dataset/Collaborator';
import {tap} from "rxjs/operators";
import {UploadService} from "../upload/upload.service";

@Injectable()
export class MallsService {

  constructor(private http: HttpClient,
              private uploadService: UploadService) {
  }

  async getAll(): Promise<Mall[]> {
    return this.http.get<Mall[]>('/mall').toPromise();
  }

  async get(id: string): Promise<Mall> {
    return this.http.get<Mall>(`/mall/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Mall>(`/mall`, data).toPromise();
  }

  async update(mall: Mall, data: any) {
    return this.http.put<Mall>(`/mall/${mall._id}`, data)
      .pipe(
        tap(() => mall.mallVersion++)
      ).toPromise();
  }

  async getMap(id: string): Promise<{ floors: any[], mapConfig: any }> {
    return this.http.get<{ floors: any[], mapConfig: any }>(`/mall/${id}/map`).toPromise();
  }

  async updateMap(mall: Mall, data: { floors: any, mapConfig: any, isBigMap: boolean }) {
    return this.http.put(`/mall/${mall._id}/map`, data)
      .pipe(
        tap(() => mall.mallVersion++)
      ).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<Mall>(`/mall/${id}`).toPromise();
  }

  async invite(mallId: string, email: string, employee?: boolean) {
    return this.http.put<Collaborator>(`/mall/${mallId}/invite`, {
      email,
      employee
    }).toPromise();
  }

  async expel(mallId: string, userId: string) {
    return this.http.delete<Collaborator>(`/mall/${mallId}/expel`, {
      params: {
        userId: userId
      }
    }).toPromise();
  }

  async getTheme(mallId: string): Promise<MallTheme> {
    return this.http.get<MallTheme>(`/mall/${mallId}/theme`).toPromise();
  }

  async updateTheme(mallId: string, data: any): Promise<any> {
    return this.http.put<any>(`/mall/${mallId}/theme`, data).toPromise();
  }

  async getBigMapByUrl(link: string): Promise<{floors: any, mapConfig: any}> {
    const data = await this.http.get<{floors: any, mapConfig: any}>(link).toPromise();
    return {
      ...data,
      floors: data.floors.map(floor => ({
        ...floor,
        snap: decodeURIComponent(floor.snap)
      }))
    };
  }

  async uploadLogo(mallId: string, file: File): Promise<Mall> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.post<Mall>(`/mall/${mallId}/logotype`, fileData).toPromise();
  }

}
