import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Push } from '../../dataset/Push';

@Injectable({
  providedIn: 'root'
})
export class ActivityPushesService {

  constructor(private http: HttpClient) {

  }

  async getPushNotifications(activity: string): Promise<Push[]> {
    return this.http.get<Push[]>(`/manage-accounts/${activity}/push-notifications`).toPromise();
  }

  async getEmailNotifications(activity: string): Promise<Push[]> {
    return this.http.get<Push[]>(`/manage-accounts/${activity}/email-notifications`).toPromise();
  }

  async create(activity: string, data: any) {
    return this.http.post<Push>(`/manage-accounts/${activity}/post-push`, data).toPromise();
  }

  async removePushNotification(activity: string, pushId: string) {
    return this.http.delete(`/manage-accounts/${activity}/push-notification/${pushId}`).toPromise();
  }

  async removeEmailNotification(activity: string, pushId: string) {
    return this.http.delete(`/manage-accounts/${activity}/email-notification/${pushId}`).toPromise();
  }
}
