import { Component, OnDestroy, OnInit } from '@angular/core';
import { Mall } from '../../../../dataset/Mall';
import { MatTableDataSource } from '@angular/material/table';
import { Shop } from '../../../../dataset/Shop';
import { DialogService } from '../../../../common/alert-dialog/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ShopsService } from '../../../../providers/shops/shops.service';
import { SearchService } from '../../../../providers/search/search.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { SearchAccountDialogComponent } from '../../../../common/search-account-dialog/search-account-dialog.component';
import { Location } from '@angular/common';
import {Account, AccountInActivity} from '../../../../dataset/Account';

@UntilDestroy()
@Component({
  selector: 'app-shop-agents',
  templateUrl: './shop-agents.component.html',
  styleUrls: ['./shop-agents.component.scss']
})
export class ShopAgentsComponent implements OnInit, OnDestroy {

  mall: Mall;
  shop: Shop;
  displayedColumns = ['name', 'menu'];
  dataSource = new MatTableDataSource<AccountInActivity>();
  locale = 'en';

  constructor(private dialogs: DialogService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private shopsService: ShopsService,
              private searchService: SearchService,
              private location: Location,
              private translationService: TranslocoService) {
    this.route.data.subscribe(async (data: { mall: Mall, shop: Shop }) => {
      this.mall = data.mall;
      this.shop = data.shop;
      this.dataSource.data = data.shop.agents;
    });
    this.searchService.textObservable
      .pipe(untilDestroyed(this), debounceTime(200))
      .subscribe(value => this.dataSource.filter = value);
    this.translationService.langChanges$
      .pipe(untilDestroyed(this))
      .subscribe(val => this.locale = val);
    this.dataSource.filterPredicate = (data: AccountInActivity, filter: string) => data.account.name.toLowerCase().includes(filter.toLowerCase().trim());
  }

  async ngOnInit() {

  }

  ngOnDestroy() {
    this.searchService.setExpanded(false);
  }

  async add() {
    const ref = await this.dialog.open(SearchAccountDialogComponent, {
      minWidth: '400px',
      data: {}
    });
    const result: Account = await ref.afterClosed().toPromise();
    if (result) {
      try {
        this.shop = await this.shopsService.addAgent(this.shop._id, result.id);
        this.dataSource.data = this.shop.agents;
      } catch (err) {
        await this.dialogs.error(err);
      }
    }
  }

  async remove(account: AccountInActivity) {
    try {
      if (await this.dialogs.confirm({
        title: 'Delete?',
        message: `Are you sure you want to delete agent?`
      })) {
        await this.shopsService.removeAgent(this.shop._id, account.account._id);
        const data = this.dataSource.data;
        const index = data.indexOf(account);
        data.splice(index, 1);
        this.dataSource.data = data;
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

  back() {
    this.location.back();
  }

}
