import {GraphEdge} from './GraphEdge';
import {Graph} from './Graph';
import {chain, find, map, pick, flatten} from 'lodash';
import {Floor} from './Floor';

// const portalLinesStart = ({fromFloor, id, toFloors}) => toFloors.map(portalLines(fromFloor, id));
// const portalLines = (from, id) => to => ({from, to, id});

const pause = (amount: number = 0): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, amount);
  });
};

export class Floors {
  locales: string[] = [];
  graph: Graph;

  get length(): number {
    return this.floors.length;
  }

  constructor(private floors: Floor[] = []) {
    const _print = true;
    const print = (str) => {
      if (!_print) {
        return;
      }
      console.log(str);
    };
    this.locales = chain(this.floors).map(f => f.locales).flatten().uniq().value();
    const nodes = chain(this.floors).map('graph.nodes').flatten().value();
    const edges = chain(this.floors).map('graph.edges').flatten().value();
    const portals = chain(this.floors)
      .map('portals')
      .flatten()
      .groupBy('id')
      .value();

    const data = map(portals, (p, id) => ({
      id, avatars: p.map(i => pick(i, ['fromFloor', 'toFloors', 'accessible'])) || []
    }));

    const _portals = chain(data).map(portal => {

      print(`====================`);
      print(`[PORTAL: ${portal.id}]`);

      return portal.avatars.map(avatar => {

        print(`  [AVATAR AT №: ${avatar.fromFloor} FLOOR]`);

        // map all avatars
        const fromProjections = this.getIdProjectionInFloor(avatar.fromFloor, portal.id);

        print(`    from: ${fromProjections}`);

        return fromProjections.map(from => {
          // map all toFloors
          return avatar.toFloors.map(toFloor => {
            // create edges
            const toProjections = this.getIdProjectionInFloor(toFloor, portal.id) || [];

            print(`      projections for ${toFloor} floor: ${toProjections}`);

            return toProjections.map(to => {
              // edge
              return new GraphEdge(from, to, 500, '', avatar.fromFloor, toFloor, false);
            });
          });
        });
      });
    }).flattenDeep().value();

    edges.push(..._portals);
    this.graph = new Graph(nodes, edges);
  }

  get(number): Floor {
    return find(this.floors, f => f.number === number);
  }

  getIdProjectionInFloor(floor: number, uid: string): string[] {
    const f = this.get(floor);
    return f ? f.getIdProjection(uid) : [];
  }


  getIdProjection(uid: string): string[] {
    return chain(this.floors).map(f => f.getIdProjection(uid)).compact().flatten().value();
  }

  getIdsForType(type: string, disabled = false): string[] {
    return chain(this.floors).map(f => f.getIdsForType(type, disabled)).compact().flatten().value();
  }

  public getFloorForId(id: string): number {
    for (const floor of this.floors) {
      if (floor.getElement(id)) {
        return floor.number;
      }
    }
  }

  getAllIds() {
    const data = this.floors.map(f => f.getIds());
    return flatten(data);
  }

  numbers(): number[] {
    return this.floors.map(f => f.number);
  }

}
