import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {Mall} from '../../../dataset/Mall';
import {Category} from '../../../dataset/Category';
import {DialogService} from '../../../common/alert-dialog/services/dialog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoriesService} from '../../../providers/categories/categories.service';
import {CatalogEditModalComponent} from './catalog-edit-modal/catalog-edit-modal.component';
import {keyBy} from 'lodash';
import { debounceTime } from "rxjs/operators";
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SearchService } from '../../../providers/search/search.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterFacade } from '../../../../utils/facade';
import {FacadePipe} from "../../../common/pipes/facade.pipe";

@UntilDestroy()
@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnDestroy, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  mall: Mall;
  displayedColumns = ['name', 'parentName', 'delete', 'edit'];
  dataSource = new MatTableDataSource<Category>();
  locale = 'en';
  categoriesMap: { [id: string]: Category};

  constructor(private dialogs: DialogService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private categoriesService: CategoriesService,
              private searchService: SearchService,
              private translocoService: TranslocoService,
              private facadePipe: FacadePipe) {
    this.route.data.subscribe(async (data: { mall: Mall, categories: Category[] }) => {
      this.mall = data.mall;
      if (data.mall.settings.default_locale) {
        this.locale = data.mall.settings.default_locale;
      }
      this.dataSource.data = data.categories;
      this.categoriesMap = keyBy(data.categories, c => c._id);
    });
    this.searchService.textObservable
      .pipe(untilDestroyed(this), debounceTime(200))
      .subscribe(value => this.dataSource.filter = value);
    this.translocoService.langChanges$
      .pipe(untilDestroyed(this))
      .subscribe(val => this.locale = val);
    this.dataSource.filterPredicate = (data: Category, filter: string) => filterFacade<Category>(data, this.locale, filter);
    this.dataSource.sortingDataAccessor = (item: Category, property) => {
      switch (property) {
        case 'name':
          return item.facade[this.locale]?.name;
        default:
          return item[property];
      }
    };
    this.route.queryParamMap.subscribe(params => {
      if (params.has('create')) {
        this.edit();
        return;
      }
      if (params.has('edit')) {
        const catalog = this.dataSource.data.find(item => item._id === params.get('edit'));
        if (!catalog) {
          this.dialogs.alert({
            title: 'Error',
            message: 'The catalog has not found',
          });
          return;
        }
        this.edit(catalog);
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.searchService.setExpanded(false);
  }

  async edit(category?: Category) {
    const ref = this.dialog.open(CatalogEditModalComponent, {
      data: {
        category,
        mall: this.mall
      },
      minWidth: '900px'
    });
    try {
      const result: Category = await ref.afterClosed().toPromise();
      this.router.navigate([], { queryParams: null });
      if (result) {
        if (category) {
          Object.assign(category, result);
        } else {
          const data = this.dataSource.data;
          data.push(result);
          this.dataSource.data = data;
        }
      }
    } catch (error) {
      this.dialogs.error(error);
    }
  }

  async remove($event: Event, category: Category) {
    $event.stopPropagation();
    try {
      if (this.dataSource.data.find(item => item.parent === category._id)) {
        this.dialogs.error(new Error('This category has child categories'));
        return;
      }
      if (await this.dialogs.confirm({
        title: this.translocoService.translate('are-you-sure'),
        message: this.translocoService.translate('remove', {item: this.facadePipe.transform(category.facade, this.locale)})
      })) {
        await this.categoriesService.delete(category._id);
        const data = this.dataSource.data;
        const index = data.indexOf(category);
        data.splice(index, 1);
        this.dataSource.data = data;
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

}
