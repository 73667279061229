import {Component, Inject} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { catchError, debounceTime, flatMap, startWith } from "rxjs/operators";
import {AccountsService} from "../../providers/accounts/accounts.service";
import {Account} from "../../dataset/Account";

@Component({
  selector: 'app-search-account-dialog',
  templateUrl: './search-account-dialog.component.html',
  styleUrls: ['./search-account-dialog.component.scss']
})
export class SearchAccountDialogComponent {

  filteredAccounts: BehaviorSubject<Account[]> = new BehaviorSubject<Account[]>([]);
  searchControl = new FormControl();
  accounts: Account[] = [];

  constructor(public dialogRef: MatDialogRef<SearchAccountDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {activity: string},
              private accountsService: AccountsService) {
    const activityId = data?.activity;
    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        flatMap(v => this.accountsService.rxGetAll(v.toLowerCase(), activityId).pipe(catchError(() => [])))
      )
      .subscribe(this.filteredAccounts);
  }

  select(account: Account) {
    this.dialogRef.close(account);
  }
}
