import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Terminal, TerminalCommands} from "../../../../dataset/Terminal";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogService} from "../../../../common/alert-dialog/services/dialog.service";
import {TerminalsService} from "../../../../providers/terminals/terminals.service";

// TODO dirtyForm
@Component({
  selector: 'app-terminal-commands-modal',
  templateUrl: './terminal-commands-modal.component.html',
  styleUrls: ['./terminal-commands-modal.component.scss']
})
export class TerminalCommandsModalComponent implements OnInit {

  availableCommands = Object.values(TerminalCommands);
  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {terminal: Terminal},
              private fb: FormBuilder,
              private dialogs: DialogService,
              private terminalsService: TerminalsService,
              private ref: MatDialogRef<TerminalCommandsModalComponent>) {
    this.form = this.fb.group({
      commands: this.fb.array([]),
    });
  }

  get commands(): FormArray {
    return this.form.get('commands') as FormArray;
  }

  ngOnInit(): void {
    this.data.terminal.commands.forEach(command => this.commands.controls.push(this.fb.control(command, Validators.required)));
  }

  async confirm() {
    this.form.disable();
    try {
      const res = await this.terminalsService.updateCommands(this.data.terminal._id, this.form.value.commands);
      this.ref.close(res);
    } catch (e) {
      this.dialogs.error(e);
    }
    this.form.enable();
  }

  delete(pos: number) {
    this.commands.removeAt(pos);
  }

  addNew() {
    this.commands.controls.push(this.fb.control(null, Validators.required));
  }

}
