import { Sponsor } from './../../dataset/Sponsor';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ActivitySponsorsService } from '../../providers/activity-sponsors/activity-sponsors.service';
import { Activity } from '../../dataset/Activity';

@Injectable({
  providedIn: 'root'
})
export class ActivitySponsorsResolverService implements Resolve<Sponsor[]> {

  constructor(private sponsorsService: ActivitySponsorsService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<Sponsor[]> {
    const activity: Activity = route.parent.data.activity;
    return this.sponsorsService.getAll(activity._id);
  }
}
