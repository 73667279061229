import { NgModule } from '@angular/core';
import { MaterialModule } from "./material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule as AngularCommonModule } from '@angular/common';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { DialogService } from "./alert-dialog/services/dialog.service";
import { MapViewComponent } from "./map-view/map-view.component";
import { MapViewService } from "./map-view/service/map-view.service";
import { FileButtonComponent } from './ui/file-button/file-button.component';
import { NoDataComponent } from './ui/no-data/no-data.component';
import { LoadingComponent } from './ui/loading/loading.component';
import { TranslocoModule } from "@ngneat/transloco";
import { FacadePipe } from './pipes/facade.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from 'ngx-color-picker';
import { SearchAccountDialogComponent } from './search-account-dialog/search-account-dialog.component';
import { QuillModule } from 'ngx-quill';
import { MatQuillModule } from './ui/mat-quill/mat-quill-module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScheduleComponent } from './ui/schedule/schedule.component';

const modules = [
  MaterialModule,
  ColorPickerModule,
  ReactiveFormsModule,
  FormsModule,
  AngularCommonModule,
  TranslocoModule,
  FlexLayoutModule,
  RouterModule,
  QuillModule,
  MatQuillModule,
  DragDropModule,
];

// TODO: map-view тут не нужен. проверить остальное
@NgModule({
  imports: [...modules],
  declarations: [
    AlertDialogComponent,
    MapViewComponent,
    FileButtonComponent,
    NoDataComponent,
    LoadingComponent,
    FacadePipe,
    SearchAccountDialogComponent,
    ScheduleComponent,
  ],
  exports: [
    ...modules,
    MapViewComponent,
    FileButtonComponent,
    NoDataComponent,
    LoadingComponent,
    FacadePipe,
    ScheduleComponent,
  ],
  providers: [
    DialogService,
    MapViewService
  ]
})
export class CommonModule {
}
