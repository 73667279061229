import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivityTariff } from '../../dataset/ActivityTariff';

@Injectable()
export class ActivityTariffsService {

  constructor(private http: HttpClient) {
  }

  async getAll(activityId: string): Promise<ActivityTariff[]> {
    return this.http.get<ActivityTariff[]>(`/manage-tariffs/activity/${activityId}?all=true`).toPromise();
  }

  async getOne(tariffId: string): Promise<ActivityTariff> {
    return this.http.get<ActivityTariff>(`/manage-tariffs/${tariffId}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<ActivityTariff>(`/manage-tariffs`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<ActivityTariff>(`/manage-tariffs/${id}`, data).toPromise();
  }

  async remove(id: string) {
    return this.http.delete<ActivityTariff>(`/manage-tariffs/${id}`).toPromise();
  }
}
