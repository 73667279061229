import * as dUtils from 'svg-path-reverse';

export class GraphLine {

  constructor(public from: string,
              public to: string,
              public d: string,
              public distance: number,
              public fromFloor: number,
              public toFloor: number) {
  }

  reverseIfNeed(lastId: string): string {
    if (lastId === this.to) {
      if (this.fromFloor !== this.toFloor) {
        const l = this.fromFloor;
        this.fromFloor = this.toFloor;
        this.toFloor = l;
      } else {
        this.d = `M${dUtils.reverse(this.d).replace(/Z/, '')}`;
      }
      this.to = this.from;
      this.from = lastId;
    }
    return this.to;
  }
}
