import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import { Activity } from '../../dataset/Activity';
import { Shop } from '../../dataset/Shop';
import { ActivitiesService } from '../../providers/activities/activities.service';

@Injectable()
export class ActivityHallResolveService implements Resolve<Shop[]> {

  constructor(private service: ActivitiesService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Shop[]> {
    return await this.service.getHalls(route.parent.parent.paramMap.get('id'));
  }

}
