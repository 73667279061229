import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { QuillModule } from 'ngx-quill';
import { MatQuill } from './mat-quill';

@NgModule({
  declarations: [MatQuill],
  exports: [MatQuill],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    MatFormFieldModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MatQuillModule {
}
