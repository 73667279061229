import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FuseNavigation } from '@fuse/types';
import { getBuildingNavigation } from './navigationData';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private subject: BehaviorSubject<FuseNavigation[]> = new BehaviorSubject<FuseNavigation[]>(getBuildingNavigation());
  private mallId: string;

  constructor(private _fuseNavigationService: FuseNavigationService,
              private userService: UserService) {
    this._fuseNavigationService.register('main', getBuildingNavigation());
    this._fuseNavigationService.register('default', getBuildingNavigation());
    this._fuseNavigationService.setCurrentNavigation('default');
  }

  getSubject(): BehaviorSubject<FuseNavigation[]> {
    return this.subject;
  }

  updateNavigation(mallId?: string) {
    if (mallId) {
      if (mallId !== this.mallId) {
        this.mallId = mallId;
        // TODO update?
        this._fuseNavigationService.unregister('main');
        this._fuseNavigationService.register('main', getBuildingNavigation(mallId, {
          ...this.userService.userRoles,
          userEmail: this.userService.user.email,
        }));
        this._fuseNavigationService.setCurrentNavigation('main');
        this.subject.next(getBuildingNavigation(mallId, {
          ...this.userService.userRoles,
          userEmail: this.userService.user.email,
        }));
      }
    } else {
      this.mallId = null;
      this._fuseNavigationService.setCurrentNavigation('default');
    }
  }
}
