import {Injectable} from '@angular/core';
import {ActivitiesService} from "../../providers/activities/activities.service";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Activity} from "../../dataset/Activity";
import {Mall} from "../../dataset/Mall";

@Injectable({
  providedIn: 'root'
})
export class ActivitiesResolverService implements Resolve<Activity[]> {

  constructor(private service: ActivitiesService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Activity[]> {
    const mall: Mall = route.parent.data.mall;
    return this.service.getAll(mall._id);
  }
}
