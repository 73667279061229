import { FuseNavigation } from '@fuse/types';

export function getBuildingNavigation(mallId?: string, params?: { isAdmin: boolean, isOrganizer: boolean, userEmail?: string }): FuseNavigation[] {
  if (!mallId) {
    return [];
  }

  // TODO: СРОЧНЫЙ КОСТЫЛЬ, УБРАТЬ, КОГДА ПРЕСТАНЕТ БЫТЬ НУЖНЫМ
  /*if (['tv2@mirror-media.moscow', 'kanzd@mail.ru'].includes(params.userEmail)) {
    return [{
      id: 'banners',
      title: 'banners',
      type: 'item',
      icon: 'video_library',
      url: `/app/malls/${mallId}/banners`,
    }, {
      id: 'terminals',
      title: 'terminals',
      type: 'item',
      icon: 'perm_device_information',
      url: `/app/malls/${mallId}/terminals`,
    }];
  }
  // Организаторы мероприятий видят только мероприятия
  const organizerLinks: FuseNavigation[] = [
    {
      id: 'mall-navigation',
      title: 'Mall Navigation',
      type: 'group',
      children: [{
        id: 'activities',
        title: 'activities',
        type: 'item',
        icon: 'date_range',
        url: `/app/malls/${mallId}/activities`,
      }],
    },
  ];

  if (!params?.isAdmin && params?.isOrganizer) {
    return organizerLinks;
  }*/

  // ссылки для обычной роли
  const standardLinks: FuseNavigation[] = [{
    id: 'mall-navigation',
    title: 'Mall Navigation',
    type: 'group',
    children: [
      {
        id: 'shops',
        title: 'shops',
        type: 'item',
        icon: 'store',
        url: `/app/malls/${mallId}/shops`,
      },
      {
        id: 'catalog',
        title: 'catalog',
        type: 'item',
        icon: 'dashboard',
        url: `/app/malls/${mallId}/catalog`,
      },
      {
        id: 'terminals',
        title: 'terminals',
        type: 'item',
        icon: 'perm_device_information',
        url: `/app/malls/${mallId}/terminals`,
      },
      {
        id: 'terminalGroups',
        title: 'terminal-groups',
        type: 'item',
        icon: 'group',
        url: `/app/malls/${mallId}/terminal-groups`,
      },
      {
        id: 'banners',
        title: 'banners',
        type: 'item',
        icon: 'video_library',
        url: `/app/malls/${mallId}/banners`,
      },
      {
        id: 'bannerGroups',
        title: 'banner-groups',
        type: 'item',
        icon: 'video_library',
        url: `/app/malls/${mallId}/banner-groups`,
      },
      {
        id: 'news',
        title: 'news',
        type: 'item',
        icon: 'feed',
        url: `/app/malls/${mallId}/news`,
      },
      {
        id: 'analytics',
        title: 'analytics.title',
        type: 'item',
        icon: 'insert_chart',
        url: `/app/malls/${mallId}/analytics`,
      },
      {
        id: 'marketing-actions',
        title: 'marketing-actions',
        type: 'item',
        icon: 'loyalty',
        url: `/app/malls/${mallId}/marketing`,
      },
      {
        id: 'access',
        title: 'access',
        type: 'item',
        icon: 'group',
        url: `/app/malls/${mallId}/access`,
      },
      {
        id: 'map-editor',
        title: 'map-editor',
        type: 'item',
        icon: 'map',
        url: `/app/malls/${mallId}/map-editor`,
      },
      {
        id: 'settings',
        title: 'settings',
        type: 'item',
        icon: 'settings',
        url: `/app/malls/${mallId}/settings`,
      },
      {
        id: 'theme',
        title: 'theme',
        type: 'item',
        icon: 'palette',
        url: `/app/malls/${mallId}/theme`,
      },
    ]
  }];

/*  if (params?.isAdmin) {
    standardLinks[0].children.push({
      id: 'organizers',
      title: 'organizers',
      type: 'item',
      icon: 'group',
      url: `/app/malls/${mallId}/organizers`,
    }, {
      id: 'admins',
      title: 'admins',
      type: 'item',
      icon: 'group',
      url: `/app/malls/${mallId}/admins`,
    });
  }*/
  return standardLinks;
}
