import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../resolvers/auth.guard';
import { RootComponent } from './root.component';
import { UserResolver } from '../resolvers/user.resolver';
import { MallComponent } from './mall/mall.component';
import { ShopsComponent } from "./mall/shops/shops.component";
import { TerminalsComponent } from "./mall/terminals/terminals.component";
import { MallResolveService } from "../resolvers/mall/mall-resolve.service";
import { MallShopsResolveService } from "../resolvers/mall-shops/mall-shops-resolve.service";
import { MallTerminalsResolveService } from '../resolvers/mall-terminals/mall-terminals-resolve.service';
import { AnalyticsResolveService } from '../resolvers/analytics/analytics-resolve.service';
import { BannersResolver } from '../resolvers/banners/banners-resolver.service';
import { CatalogComponent } from "./mall/catalog/catalog.component";
import { CategoriesResolveService } from '../resolvers/categories/categories-resolve.service';
import { MarketingResolveService } from '../resolvers/marketing/marketing-resolve.service';
import { MapEditorComponent } from "./mall/map-editor/map-editor.component";
import { ScreenshotsComponent } from "./mall/terminals/screenshots/screenshots.component";
import { TerminalResolveService } from "../resolvers/terminal/terminal-resolve.service";
import { ShopResolveService } from '../resolvers/shop/shop-resolve.service';
import { ShopAgentsComponent } from './mall/shops/shop-agents/shop-agents.component';
import { ActivitiesResolverService } from '../resolvers/activities/activities-resolver.service';
import { MallAdminsResolverService } from '../resolvers/mall-admins/mall-admins-resolver.service';
import { MallOrganizersResolverService } from '../resolvers/mall-organizers/mall-organizers-resolver.service';
import { AdminGuard } from '../resolvers/admin.guard';
import { ActivityNewsResolveService } from "../resolvers/activity-news/activity-news-resolve.service";
import { BannerGroupsResolveService } from '../resolvers/banner-groups/banner-groups-resolve.service';
import { BrandsResolveService } from "../resolvers/brands/brands-resolve.service";
import {TerminalGroupsResolveService} from "../resolvers/mall-terminal-groups/terminalGroups-resolve.service";
import {TerminalGroupsComponent} from "./mall/terminal-groups/terminal-groups.component";
import {MapResolver} from "../resolvers/map/map.resolver";
import {MallThemeResolver} from "../resolvers/mall-theme.resolver";
import {CountriesResolveService} from "../resolvers/countries/countries-resolve.service";
import {QrCodesResolver} from "../resolvers/qr-codes/qr-codes.resolver";

// TODO прибраться. Разнести все в children-ы. Убрать лишнее
const routes: Routes = [{
  path: 'app',
  component: RootComponent,
  canActivate: [AuthGuard],
  resolve: {
    user: UserResolver
  },
  children: [{
    path: 'api-tokens',
    loadChildren: () => import('./api-tokens/api-tokens.module').then(m => m.ApiTokensModule),
    canActivate: [AdminGuard],
  }, {
    path: 'countries',
    loadChildren: () => import('./countries/countries.module').then(m => m.CountriesModule),
    canActivate: [AdminGuard],
  }, {
    path: 'brands',
    loadChildren: () => import('./brands/brands.module').then(m => m.BrandsModule),
    canActivate: [AdminGuard],
  }, {
    path: 'accounts',
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
    canActivate: [AdminGuard],
  }, {
    path: 'me',
    loadChildren: () => import('./me/me.module').then(m => m.MeModule),
  }, {
    path: 'manage-terminals',
    loadChildren: () => import('./manage-terminals/manage-terminals.module').then(m => m.ManageTerminalsModule),
    canActivate: [AdminGuard],
  }, {
    path: 'service-desk',
    loadChildren: () => import('./service-desk/service-desk.module').then(m => m.ServiceDeskModule),
    canActivate: [AdminGuard],
  }, {
    path: 'malls',
    loadChildren: () => import('./malls/malls.module').then(m => m.MallsModule),
  }, {
    path: 'roles',
    loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
  }, {
    path: 'malls/:id',
    component: MallComponent,
    resolve: {
      user: UserResolver,
      mall: MallResolveService
    },
    children: [{
      path: 'shops',
      component: ShopsComponent,
      resolve: {
        user: UserResolver,
        mall: MallResolveService,
        shops: MallShopsResolveService,
        categories: CategoriesResolveService,
        brands: BrandsResolveService,
      }
    }, {
      path: 'shops/:id/agents',
      component: ShopAgentsComponent,
      resolve: {
        mall: MallResolveService,
        shop: ShopResolveService,
      }
    }, {
      path: 'terminals',
      component: TerminalsComponent,
      resolve: {
        user: UserResolver,
        mall: MallResolveService,
        terminals: MallTerminalsResolveService
      }
    }, {
      path: 'terminal-groups',
      component: TerminalGroupsComponent,
      resolve: {
        user: UserResolver,
        mall: MallResolveService,
        terminals: MallTerminalsResolveService,
        terminalGroups: TerminalGroupsResolveService,
        bannerGroups: BannerGroupsResolveService,
      },
    },{
      path: 'terminal/:id/screenshots',
      component: ScreenshotsComponent,
      resolve: {
        user: UserResolver,
        mall: MallResolveService,
        terminal: TerminalResolveService
      }
    }, {
      path: 'banners',
      loadChildren: () => import('./mall/banners/banners.module').then(m => m.BannersModule),
      resolve: {
        user: UserResolver,
        mall: MallResolveService,
        banners: BannersResolver,
        terminals: MallTerminalsResolveService
      }
    }, {
      path: 'banner-groups',
      loadChildren: () => import('./mall/banner-groups/banner-groups.module').then(m => m.BannerGroupsModule),
      resolve: {
        mall: MallResolveService,
        bannerGroups: BannerGroupsResolveService,
      },
    }, {
      path: 'news',
      loadChildren: () => import('./mall/activity-news/activity-news.module').then(m => m.ActivityNewsModule),
      resolve: {
        mall: MallResolveService,
        news: ActivityNewsResolveService,
      },
      data: {
        isMainMenu: true,
      }
    }, {
      path: 'analytics',
      resolve: {
        mall: MallResolveService,
        analytics: AnalyticsResolveService,
        terminals: MallTerminalsResolveService,
        qrCodes: QrCodesResolver,
      },
      loadChildren: () => import('./mall/analytics/analytics.module').then(m => m.AnalyticsModule),
    }, {
      path: 'catalog',
      component: CatalogComponent,
      resolve: {
        user: UserResolver,
        mall: MallResolveService,
        categories: CategoriesResolveService
      }
    }, {
      path: 'marketing',
      loadChildren: () => import('./mall/marketing/marketing.module').then(m => m.MarketingModule),
      resolve: {
        user: UserResolver,
        mall: MallResolveService,
        marketingActions: MarketingResolveService
      }
    }, {
      path: 'access',
      loadChildren: () => import('./mall/access/access.module').then(m => m.AccessModule),
      resolve: {
        mall: MallResolveService
      }
    }, {
      path: 'map-editor',
      component: MapEditorComponent,
      resolve: {
        mall: MallResolveService,
        map: MapResolver,
        qrCodes: QrCodesResolver,
        shops: MallShopsResolveService,
      }
    }, {
      path: 'settings',
      loadChildren: () => import('./mall/settings/settings.module').then(m => m.SettingsModule),
      resolve: {
        mall: MallResolveService,
        categories: CategoriesResolveService,
        activities: ActivitiesResolverService,
        countries: CountriesResolveService,
      }
    }, {
      path: 'theme',
      loadChildren: () => import('./mall/theme/theme.module').then(m => m.ThemeModule),
      resolve: {
        mall: MallResolveService,
        theme: MallThemeResolver,
      }
    }, /*{
      path: 'organizers',
      loadChildren: () => import('./mall/organizers/organizers.module').then(m => m.OrganizersModule),
      canActivate: [AdminGuard],
      resolve: {
        mall: MallResolveService,
        activities: ActivitiesResolverService,
        organizers: MallOrganizersResolverService,
      }
    }, {
      path: 'admins',
      loadChildren: () => import('./mall/admins/admins.module').then(m => m.AdminsModule),
      canActivate: [AdminGuard],
      resolve: {
        mall: MallResolveService,
        activities: ActivitiesResolverService,
        admins: MallAdminsResolverService,
      }
    }*/]
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RootRoutingModule {
}
