import {Injectable} from '@angular/core';
import * as Snap from 'snapsvg-cjs';
import {each, head, last, takeRight} from 'lodash';
import * as dUtils from 'svg-path-reverse';

@Injectable()
export class MapParserService {

  constructor() {
  }

  async adjustPath(content: string): Promise<Snap.Fragment> {

    const snap = Snap.parse(content);
    const pathsContainer = snap.select('g#waypath');
    const pointsContainer = snap.select('g#waypoint');

    if (pointsContainer) {
      const wayPathOffset = pathsContainer.getBBox();
      const wayPointOffset = pointsContainer.getBBox();
      const deltaX = wayPathOffset.x - wayPointOffset.x;
      const deltaY = wayPathOffset.y - wayPointOffset.y;

      const paths = pathsContainer.selectAll('path');
      const circles = pointsContainer.selectAll('circle');
      const points: any[] = [];
      circles.forEach((point: Snap.Element) => {
        const r = parseFloat(point.attr('r'));
        const x = parseFloat(point.attr('cx'));
        const y = parseFloat(point.attr('cy'));
        points.push({
          x: x - deltaX,
          y: y - deltaY,
          r
        });
      });

      ['stores', 'kiosks', 'internals', 'objects'].forEach(name => {
        const stores = snap.selectAll(`g#${name} > g`);
        const storesContainer = snap.select(`g#${name}`);
        if (storesContainer) {
          const storesBB = storesContainer.getBBox();
          stores.forEach((g: Snap.Element) => {
            const box = g.getBBox();
            const _points = g.selectAll('circle');
            _points.forEach((point: Snap.Element) => {
              const r = parseFloat(point.attr('r'));
              const x = parseFloat(point.attr('cx'));
              const y = parseFloat(point.attr('cy'));
              const p = {
                x: x + box.x + storesBB.x - wayPathOffset.x,
                y: y + box.y + storesBB.y - wayPathOffset.y,
                r
              };
              points.push(p);
            });
          });
        }
      });

      paths.forEach((_path: Snap.Element) => {

        const path: any[] = Snap.parsePathString(_path.attr('d'));

        const _first = head(path);
        const _last = last(path);
        const firstCoords = takeRight(_first, 2);
        const lastCoords = takeRight(_last, 2);

        each(points, point => {
          const d = Snap.len(point.x, point.y, firstCoords[0], firstCoords[1]);
          const d2 = Snap.len(point.x, point.y, lastCoords[0], lastCoords[1]);

          if (d < point.r) {
            path[0][_first.length - 2] = point.x;
            path[0][_first.length - 1] = point.y;
          }

          if (d2 < point.r) {
            path[path.length - 1][_last.length - 2] = point.x;
            path[path.length - 1][_last.length - 1] = point.y;
          }

        });

        _path.attr('d', dUtils.normalize(path.toString()));

      });
    }

    return snap;
  }
}
