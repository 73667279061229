import { Injectable } from '@angular/core';
import { ManageAccountsService } from '../../providers/manage-accounts/manage-accounts.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Role } from '../../dataset/Role';
import { Activity } from '../../dataset/Activity';

@Injectable({
  providedIn: 'root'
})
export class ActivityRolesResolverService implements Resolve<Role[]> {

  constructor(private service: ManageAccountsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Role[]> {
    const id: string = route.parent.paramMap.get('id') || route.parent.data.activity._id;
    return await this.service.getAllRoles(id);
  }
}
