import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Mall} from "../../dataset/Mall";
import {MallsService} from "../../providers/malls/malls.service";

@Injectable()
export class MallResolveService implements Resolve<Mall> {

  constructor(private mallsApi: MallsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Mall> {
    return route.parent.data['mall'] || await this.mallsApi.get(route.params['id']);
  }

}
