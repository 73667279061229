import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {PreSignedUploadResult} from "../../dataset/PreSignedUploadResult";

@Injectable()
export class PreSignedUploadService {

  constructor(private http: HttpClient) {
  }

  async sign(file: string, type: string): Promise<PreSignedUploadResult> {
    return this.http.get<PreSignedUploadResult>('/media/pre-sign-upload', {
      params: new HttpParams()
        .set('file', file)
        .set('type', encodeURIComponent(type))
    }).toPromise();
  }

}
