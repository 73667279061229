import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {MallsService} from "../providers/malls/malls.service";
import {Mall, MallTheme} from "../dataset/Mall";

@Injectable({
  providedIn: 'root'
})
export class MallThemeResolver implements Resolve<MallTheme> {
  constructor(private mallsApi: MallsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<MallTheme> {
    const mall: Mall = route.parent.data.mall;
    return this.mallsApi.getTheme(mall._id);
  }
}
