import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MarketingAction } from '../../dataset/MarketingAction';
import { HttpParams } from '@angular/common/http';
import {UploadService} from "../upload/upload.service";

@Injectable()
export class MarketingService {

  constructor(private http: HttpClient,
              private uploadService: UploadService) {
  }

  async getAll(mall: string): Promise<MarketingAction[]> {
    return this.http.get<MarketingAction[]>('/marketing-action', {
      params: new HttpParams().set('mall', mall)
    }).toPromise();
  }

  async get(id: string): Promise<MarketingAction[]> {
    return this.http.get<MarketingAction[]>(`/marketing-action/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<MarketingAction>(`/marketing-action`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<MarketingAction>(`/marketing-action/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<MarketingAction>(`/marketing-action/${id}`).toPromise();
  }

  async upload(marketingActionId: string, file: File): Promise<MarketingAction> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.post<MarketingAction>(`/marketing-action/${marketingActionId}/image`, fileData).toPromise();
  }

}
