export function copyToBuffer(str: string) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function getCoordinatesFromString(coordinate: string): {x: number, y: number} {
  // in case of negative floor
  if (coordinate.startsWith('-')) {
    const [, , coords] = coordinate.split('-');
    const [x, y] = coords.split('x');
    return { x: parseFloat(x), y: parseFloat(y) };
  } else {
    const [, coords] = coordinate.split('-');
    const [x, y] = coords.split('x');
    return { x: parseFloat(x), y: parseFloat(y) };
  }
}

export const phonePatter = /^(\s*)?(\+)([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
export const linkPattern = /https?:\/\/(www\.)?[-a-zA-Zа-я0-9@:%._\+~#=]{1,256}\.[a-zA-Zа-я0-9()]{1,6}\b([-a-zA-Zа-я0-9()@:%_\+.~#?&//=]*)/;
