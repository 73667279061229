import {differenceInDays, format, parseISO} from "date-fns";
import { AbstractControl } from "@angular/forms";

export enum CompareTimeResults {
  BIGGER = 'bigger',
  LESS = 'less',
  EQUAL = 'equal'
}

export function getTerminalPingTime(time: number): string {
  let res = '';
  let s = time / 1000;
  let m = s / 60;
  s = s % 60;
  const h = (m / 60);
  m = m % 60;
  if (h >= 1) {
    res += `${h.toFixed(0)} h `;
  }
  if (m >= 1) {
    res += `${m.toFixed(0)} m `;
  }
  res += `${s.toFixed(0)} s`;
  return res;
}

/**
 * Compares time
 * @param time1 HH:mm
 * @param time2 HH:mm
 */
export function isFirstTimeBigger(time1: string, time2: string): CompareTimeResults {
  const [startHours, startMinutes] = time1.split(':');
  const [endHours, endMinutes] = time2.split(':');
  if (endHours > startHours) {
    return CompareTimeResults.LESS;
  }
  if (endHours === startHours) {
    if (endMinutes === startMinutes) {
      return CompareTimeResults.EQUAL;
    } else {
      return endMinutes > startMinutes ? CompareTimeResults.LESS : CompareTimeResults.BIGGER;
    }
  }
  return CompareTimeResults.BIGGER;
}

// TODO use everywhere
export function combineDateAndTime(date: Date, time: string): string {
  if (!time) {
    return date.toISOString();
  }
  const res = new Date(date.getTime());
  const [startHours, startMinutes] = time.split(':');
  res.setHours(+startHours);
  res.setMinutes(+startMinutes);
  return res.toISOString();
}

// TODO use everywhere
export function getDateAndTime(d: string): {date: Date, time: string} {
  const date = parseISO(d);
  const time = format(date, 'HH:mm');
  return {
    date,
    time,
  };
}

/**
 * Handles time-date validation for control group, which includes start and end dates and times
 * @param group
 */
export function validateDateTimeFormGroup(group: AbstractControl) {
  const startDate = group.get('startDate');
  const endDate = group.get('endDate');
  const startTime = group.get('startTime');
  const endTime = group.get('endTime');
  validateDateTimeControls(startDate, startTime, endDate, endTime);
}

export function validateDateTimeControls(startDate: AbstractControl, startTime: AbstractControl, endDate: AbstractControl, endTime: AbstractControl) {
  if (!startDate.value || !endDate.value || !startTime.value || !endTime.value) {
    return;
  }
  if (differenceInDays(startDate.value, endDate.value) < 0) {
    checkInputValidationState(CompareTimeResults.EQUAL, startTime, endTime);
    return;
  }
  const res = isFirstTimeBigger(startTime.value, endTime.value);
  checkInputValidationState(res, startTime, endTime);
}

function checkInputValidationState(res: CompareTimeResults, startInput: AbstractControl, endInput: AbstractControl) {
  switch (res) {
    case CompareTimeResults.LESS:
    case CompareTimeResults.EQUAL:
      endInput.setErrors(null);
      break;
    case CompareTimeResults.BIGGER:
      endInput.setErrors({
        wrong: true,
      });
      break;
  }
}
