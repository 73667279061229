import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../dataset/User';
import {Mall} from '../../../dataset/Mall';
import {Banner} from '../../../dataset/Banner';
import {BannersService} from '../../../providers/banners/banners.service';
import {BannersEditDialogComponent} from './banners-edit-dialog/banners-edit-dialog.component';
import {DialogService} from '../../../common/alert-dialog/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import {TranslocoService} from "@ngneat/transloco";

const DISPLAYED_COLUMNS = ['index', 'name', 'availableFrom', 'availableTo', 'updatedAt', 'isActive', 'menu'];
const SORT_ACTIVE = 'index';
const SORT_DIRECTION = 'asc';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  mall: Mall;
  banners: Banner[] = [];
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  sorted: Banner[] = [];
  options: SortableOptions;
  isTableView = false;
  isSaved = true;
  private initialSort: MatSortable = {
    id: SORT_ACTIVE,
    start: SORT_DIRECTION,
    disableClear: false
  };

  static sortBanners(a, b) {
    if (a.index < b.index) { return -1; }
    if (a.index > b.index) { return 1; }
    return 0;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private bannersService: BannersService,
              private translocoService: TranslocoService,
              private dialog: MatDialog,
              private dialogs: DialogService,
              public snackBar: MatSnackBar) {
    this.onDrag = this.onDrag.bind(this);
    this.displayedColumns = DISPLAYED_COLUMNS.slice();
    this.route.data.subscribe((data: DataParams) => {
      this.mall = data.mall;
      this.sorted = data.banners.slice().sort(BannersComponent.sortBanners);
      this.banners = this.sorted.slice();
      this.dataSource.data = this.sorted;
    });
    this.route.queryParamMap.subscribe(params => {
      if (params.has('create')) {
        this.edit();
        return;
      }
      if (params.has('edit')) {
        const banner = (this.dataSource.data as Banner[]).find(item => item._id === params.get('edit'));
        if (!banner) {
          this.dialogs.alert({
            title: 'Error',
            message: 'The banner has not found',
          });
          return;
        }
        this.edit(banner);
      }
    });
  }

  ngOnInit() {
    this.options = {
      onUpdate: this.onDrag,
      draggable: ".mat-row",
      handle: '.draggable'
    };
    this.sort.sort(this.initialSort);
    this.dataSource.sort = this.sort;
  }

  onSortChange(sort: Sort) {
    const isDefaultSort = sort.active === SORT_ACTIVE && sort.direction === SORT_DIRECTION;
    this.options.disabled = !isDefaultSort;
    if (isDefaultSort) {
      this.displayedColumns.unshift('draggable');
    } else {
      this.displayedColumns = DISPLAYED_COLUMNS.slice();
    }
  }

  async edit(banner?: Banner) {
    const ref = this.dialog.open(BannersEditDialogComponent, {
      data: {
        mall: this.mall,
        banner
      },
      minWidth: '1100px'
    });
    const res = await ref.afterClosed().toPromise();
    this.router.navigate([], { queryParams: null });
    if (banner) {
      Object.assign(banner, res);
    }
    this.banners = await this.bannersService.getAll(this.mall._id);
    this.dataSource.data = [...this.banners];
  }

  async remove(banner: Banner) {
    try {
      if (await this.dialogs.confirm({
        title: this.translocoService.translate('are-you-sure'),
        message: this.translocoService.translate('remove', {item: banner.name}),
      })) {
        await this.bannersService.delete(banner._id);
        this.banners = await this.bannersService.getAll(this.mall._id);
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

  getNumberOfDaysTillTheEnd(banner: Banner) {
    return differenceInCalendarDays(parseISO(banner.availableTo), new Date());
  }

  onDrag() {
    this.isSaved = false;
    this.sorted.map((item, index) => item.index = index + 1);
  }

  saveOrder() {
    let isSaved = true;
    this.sorted.forEach(async (item) => {
      isSaved = isSaved && !!(await this.bannersService.update(item._id, item));
    });
    if (isSaved) {
      this.isSaved = true;
      this.banners = this.sorted.slice();
      this.snackBar.openFromComponent(OrderIsSavedComponent, {
        duration: 5000,
        panelClass: ['order-snackbar']
      });
    }
  }
}

@Component({
  selector: 'app-snackbar',
  template: '<p i18n>The order is saved!</p>',
  styles: [`
    p {
      color: #fff;
    }
  `],
})
export class OrderIsSavedComponent {}

interface DataParams {
  user: User;
  mall: Mall;
  banners: Banner[];
}

interface SortableOptions {
  disabled?: boolean;
  onUpdate: () => void;
  draggable: string;
  handle: string;
}
