import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AverageSessionHit, CategoryHit, EmptySearchHit, Hit, MapObjectHit} from '../../dataset/Hit';
import {Observable} from "rxjs";

export interface AnalyticsServiceOptions {
  terminals?: string;
  malls?: string;
  from: string;
  to: string;
}

export interface AnalyticsServiceOnlyOptions extends AnalyticsServiceOptions {
  'group-by'?: string;
  'filter-data-by'?: string;
}

@Injectable()
export class AnalyticsService {

  constructor(private http: HttpClient) {
  }

  async get(options: AnalyticsServiceOptions): Promise<Hit[]> {
    const data: any = {...(options)};
    return this.http.get<Hit[]>(`v1/hit`, {
      params: data
    }).toPromise();
  }

  getOnly(name: string, options: AnalyticsServiceOnlyOptions): Observable<Hit[] | CategoryHit[] | MapObjectHit[] | AverageSessionHit[] | EmptySearchHit[]> {
    const params: any = {...(options)};
    return this.http.get<any>(`v1/hit/${name}`, {params});
  }

}
