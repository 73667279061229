import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TerminalsService} from "../../../../providers/terminals/terminals.service";
import {Terminal} from "../../../../dataset/Terminal";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {map} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import {idGen} from "../../../../dataset/map/Graph";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-attach-terminal-modal',
  templateUrl: './attach-terminal-modal.component.html',
  styleUrls: ['./attach-terminal-modal.component.scss']
})
export class AttachTerminalModalComponent implements OnInit, OnDestroy {

  terminals: { terminal: Terminal; attached: boolean; boundToThisPoint: boolean }[] = [];
  filteredTerminals: BehaviorSubject<{ terminal: Terminal; attached: boolean; boundToThisPoint: boolean }[]>;
  searchControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<AttachTerminalModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { mall: string; nodes: string[]; id: string },
              private terminalsService: TerminalsService) {
  }

  async ngOnInit() {
    const id = t => t.position ? idGen(t.position.x, t.position.y, t.floor) : undefined;
    const data = await this.terminalsService.getAll({mall: this.data.mall});
    this.terminals = data.map(terminal => ({
      terminal,
      attached: this.data.nodes.includes(id(terminal)),
      boundToThisPoint: id(terminal) === this.data.id,
    }));
    const attached = (a, b) => a.attached === b.attached;
    this.terminals.sort((a, b) => attached(a, b) ? 0 : a.attached ? 1 : 0);
    this.filteredTerminals = new BehaviorSubject(this.terminals);
    this.searchControl.valueChanges
      .pipe(
        untilDestroyed(this),
        map(v => this.terminals.filter(s => s.terminal.name.toLowerCase().indexOf(v.toLowerCase()) !== -1))
      )
      .subscribe(this.filteredTerminals);
  }

  select(terminal: Terminal) {
    this.dialogRef.close(terminal);
  }

  ngOnDestroy(): void {
  }

}
