import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {QrCode} from "../../dataset/QrCode";

@Injectable({
  providedIn: 'root'
})
export class QrCodesService {

  constructor(private http: HttpClient) {
  }

  getAll(mallId: string): Promise<QrCode[]> {
    return this.http.get<QrCode[]>('qrcodes', {
      params: {
        mallId,
      }
    }).toPromise();
  }

  add(data: any): Promise<QrCode> {
    return this.http.post<QrCode>('qrcodes', data).toPromise();
  }

  update(id: string, data: any): Promise<QrCode> {
    return this.http.put<QrCode>(`qrcodes/${id}`, data).toPromise();
  }

  remove(id: string): Promise<void> {
    return this.http.delete<void>(`qrcodes/${id}`).toPromise();
  }
}
