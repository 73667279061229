import { NgModule } from '@angular/core';
import { InviteComponent } from './invite.component';
import { CommonModule } from '../common/common.module';
import { AuthRoutingModule } from '../auth/auth-routing.module';
import { FlexModule } from '@angular/flex-layout';
import { CommonModule as AngularCommon } from '@angular/common';

@NgModule({
  declarations: [InviteComponent],
  imports: [
    CommonModule,
    AngularCommon,
    AuthRoutingModule,
    FlexModule
  ]
})
export class InviteModule { }
