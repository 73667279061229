import { Injectable } from '@angular/core';
import { ActivitiesService } from '../../providers/activities/activities.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ActivityFile } from '../../dataset/Activity';

@Injectable({
  providedIn: 'root'
})
export class ActivitySupportFilesResolveService implements Resolve<ActivityFile[]> {

  constructor(private activitiesService: ActivitiesService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<ActivityFile[]> {
    return this.activitiesService.getSupportFiles(route.parent.paramMap.get('id'));
  }
}
