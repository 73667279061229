import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DirtyFormModalService} from "../../../../../providers/dirty-form-modal/dirty-form-modal.service";
import {QrCode} from "../../../../../dataset/QrCode";
import {DialogService} from "../../../../../common/alert-dialog/services/dialog.service";
import {QrCodesService} from "../../../../../providers/qr-codes/qr-codes.service";

@Component({
  selector: 'app-edit-qr-code',
  templateUrl: './edit-qr-code.component.html',
  styleUrls: ['./edit-qr-code.component.scss']
})
export class EditQrCodeComponent implements OnInit {

  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditQrCodeComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { qrCode: QrCode, mallId: string },
              private fb: FormBuilder,
              private dialogs: DialogService,
              private qrCodesService: QrCodesService,
              private dirtyFormService: DirtyFormModalService) {
    this.form = fb.group({
      title: [null, Validators.required],
      bearing: [0, Validators.required],
      is3d: false,
      isProd: false,
    });
    this.dirtyFormService.registerForm(this.form, dialogRef);
  }

  ngOnInit() {
    const {qrCode} = this.data;
    if (qrCode) {
      this.form.patchValue(qrCode);
    }
  }

  async confirm() {
    const data = Object.assign({
      mall: this.data.mallId,
    }, this.form.value);
    try {
      let res;
      if (this.data.qrCode) {
        res = await this.qrCodesService.update(this.data.qrCode._id, data);
      } else {
        res = await this.qrCodesService.add(data);
      }
      this.dialogRef.close(res);
    } catch (e) {
      this.dialogs.error(e);
    }
  }

  cancel() {
    this.dirtyFormService.closeSafely();
  }

}
