import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {TerminalGroups} from "../../dataset/TerminalGroups";
import {TerminalsService} from "../../providers/terminals/terminals.service";
import {Mall} from "../../dataset/Mall";

@Injectable({
  providedIn: 'root'
})
export class TerminalGroupsResolveService implements Resolve<TerminalGroups[]> {

  constructor(private terminalsService: TerminalsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<TerminalGroups[]> {
    const mall: Mall = route.parent.data.mall;
    return await this.terminalsService.getGroups(mall._id);
  }

}
