import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Activity, ActivityFile, ActivityFileTypes } from "../../dataset/Activity";
import { Facade } from '../../dataset/Facade';
import { Shop } from '../../dataset/Shop';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  constructor(private http: HttpClient) {
  }

  async getAll(mall: string): Promise<Activity[]> {
    return this.http.get<Activity[]>('/activities', {params: {mall}}).toPromise();
  }

  async get(id: string): Promise<Activity> {
    return this.http.get<Activity>(`/activity/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Activity>(`/activity`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<Activity>(`/activity/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<Activity>(`/activity/${id}`).toPromise();
  }

  async createChat(id: string, data: { name: string, description: string }) {
    return this.http.post(`/activity/${id}/chat`, data).toPromise();
  }

  async getFiles(id: string): Promise<ActivityFile[]> {
    return this.http.get<ActivityFile[]>(`/activity-docs/${id}/files`).toPromise();
  }

  async getSupportFiles(id: string): Promise<ActivityFile[]> {
    return this.http.get<ActivityFile[]>(`/activity-docs/${id}/supportFiles`).toPromise();
  }

  async addFile(activityId: string, file: File, facade: Facade<{ title: string, description: string }>, fileIndex: number, type: ActivityFileTypes): Promise<Activity> {
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('facade', JSON.stringify(facade));
    fileData.append('type', type);
    if (fileIndex) {
      fileData.append('fileIndex', String(fileIndex));
    }
    return this.http.put<Activity>(`/activity-docs/${activityId}/file`, fileData).toPromise();
  }

  async updateFile(activityId: string, fileUrl: string, facade: Facade<{ title: string, description: string }>, fileIndex: number, type: ActivityFileTypes): Promise<Activity> {
    const fileData = new FormData();
    fileData.append('facade', JSON.stringify(facade));
    fileData.append('type', type);
    if (fileIndex) {
      fileData.append('fileIndex', String(fileIndex));
    }
    return this.http.put<Activity>(`/activity-docs/${activityId}/update-file?fileUrl=${fileUrl}`, fileData).toPromise();
  }

  async deleteFile(activityId: string, fileUrl: string, type: ActivityFileTypes) {
    return this.http.delete(`/activity-docs/${activityId}/file`, {params: {fileUrl, type}}).toPromise();
  }

  async copyMap(activityId: string): Promise<Activity> {
    return this.http.get<Activity>(`/${activityId}/copy-map`).toPromise();
  }

  async saveMap(activityId: string, data) {
    return this.http.put(`${activityId}/save-map`, data).toPromise();
  }

  async deleteMap(activityId: string) {
    return this.http.delete<Activity>(`${activityId}/delete-map`).toPromise();
  }

  async getMap(activityId: string) {
    return this.http.get<Activity>(`${activityId}/map`).toPromise();
  }

  async getHalls(activityId: string) {
    return this.http.get<Shop[]>(`${activityId}/select-halls`).toPromise();
  }
  async deleteHall(activityId: string, shopId, deleteShop) {
    return this.http.delete<Shop>(`${activityId}/delete-hall/${shopId}${deleteShop ? '?deleteShop=true' : ''}`).toPromise();
  }

  async createHallInActivity(activityId: string, data) {
    return this.http.post<Shop>(`${activityId}/create-hall`, data).toPromise();
  }
  async updateHallInActivity(activityId: string, shopId, data) {
    return this.http.put<Shop>(`${activityId}/update-hall/${shopId}`, data).toPromise();
  }
}
