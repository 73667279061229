import { Injectable } from '@angular/core';
import { ActivityPromoService } from '../../providers/activity-promo/activity-promo.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Promo } from '../../dataset/Promo';
import { Activity } from '../../dataset/Activity';

@Injectable({
  providedIn: 'root'
})
export class ActivityPromoResolverService implements Resolve<Promo[]> {

  constructor(private service: ActivityPromoService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Promo[]> {
    const activity: Activity = route.parent.data.activity;
    return await this.service.getAll(activity._id);
  }
}
