import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {Mall} from "../../../dataset/Mall";
import {ActivatedRoute, Router} from "@angular/router";
import {Terminal, TerminalCommands} from '../../../dataset/Terminal';
import {DialogService} from '../../../common/alert-dialog/services/dialog.service';
import {TerminalsEditModalComponent} from './terminals-edit-modal/terminals-edit-modal.component';
import {TerminalsService} from '../../../providers/terminals/terminals.service';
import {ActivateTerminalComponent} from "./activate-terminal/activate-terminal.component";
import { debounceTime, map, takeUntil, tap } from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../../../providers/search/search.service';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {copyToBuffer} from "../../../../utils/utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslocoService} from "@ngneat/transloco";
import {TerminalCommandsModalComponent} from "./terminal-commands-modal/terminal-commands-modal.component";
import {MallsService} from "../../../providers/malls/malls.service";
import {getTerminalPingTime} from "../../../../utils/time.utils";

@Component({
  selector: 'app-terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.scss']
})
export class TerminalsComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  mall: Mall;
  displayedColumns = [
    'name',
    'floor',
    'mapType',
    'started_at',
    'last_ping_delta',
    'front_ping_delta',
    'frontend_version',
    'wrapper_version',
    'mall_version',
    'isProd',
    'menu',
  ];
  dataSource = new MatTableDataSource<Terminal>();

  loading = false;
  getTerminalPingTime = getTerminalPingTime;
  private $stop = new Subject();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialogs: DialogService,
              private dialog: MatDialog,
              private terminalsService: TerminalsService,
              protected sanitizer: DomSanitizer,
              private snackBar: MatSnackBar,
              private translocoService: TranslocoService,
              private mallService: MallsService,
              private searchService: SearchService) {
    this.route.data.subscribe((data: { mall: Mall, terminals: Terminal[] }) => {
      this.mall = data.mall;
      this.dataSource.data = data.terminals;
    });
    this.searchService.textObservable
      .pipe(takeUntil(this.$stop), debounceTime(200))
      .subscribe(searchToken => {
        this.router.navigate([], {
          queryParams: {searchToken},
          queryParamsHandling: 'merge'
        });
      });
    this.dataSource.filterPredicate = (data: Terminal, filter: string) => {
      return data.name.toLowerCase().includes(filter.toLowerCase());
    };
    this.route.queryParamMap
      .pipe(
        map(m => m.get('searchToken')),
        tap(v => this.searchService.setText(v))
      )
      .subscribe(searchToken => this.dataSource.filter = searchToken);
    this.route.queryParamMap.subscribe(params => {
      if (params.has('create')) {
        this.edit();
        return;
      }
      if (params.has('edit') || params.has('activate') || params.has('showCommands')) {
        const terminal = this.dataSource.data
          .find(item => item._id === params.get('edit') || item._id === params.get('activate') || item._id === params.get('showCommands'));
        if (!terminal) {
          this.dialogs.alert({
            title: 'Error',
            message: 'The terminal has not found',
          });
          return;
        }
        switch (true) {
          case params.has('edit'):
            this.edit(terminal);
            return;
          case params.has('activate'):
            this.activate(terminal);
            return;
          case params.has('showCommands'):
            this.showCommands(terminal);
            return;
          default:
            return;
        }
      }
    });
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    const sortState: Sort = {active: 'name', direction: 'asc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    // this.sort.sort(<MatSortable>{
    //     id: 'name',
    //     start: 'asc'
    //   }
    // );
  }

  ngOnDestroy() {
    this.searchService.setExpanded(false);
    this.$stop.complete();
  }

  async activate(terminal: Terminal) {
    try {
      const ref = this.dialog.open(ActivateTerminalComponent, {
        minWidth: '300px'
      });
      const res = await ref.afterClosed().toPromise();
      this.router.navigate([], { queryParams: null });
      const {pin} = res;
      if (pin) {
        await this.terminalsService.activateByPin(terminal._id, pin);
        this.dialogs.alert({
          title: this.translocoService.translate('success'),
          message: this.translocoService.translate('activation-successful'),
        });
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

  async edit(terminal?: Terminal) {
    try {
      const ref = this.dialog.open(TerminalsEditModalComponent, {
        data: {terminal},
        minWidth: '300px'
      });
      const result: Terminal = await ref.afterClosed().toPromise();
      this.router.navigate([], { queryParams: null });
      if (result) {
        if (terminal) {
          const updatedTerminal = await this.terminalsService.update(terminal._id, result);
          Object.assign(terminal, updatedTerminal);
        } else {
          result.mall = this.mall._id;
          const createdTerminal = await this.terminalsService.create(result);
          const data = this.dataSource.data;
          data.push(createdTerminal);
          this.dataSource.data = data;
        }
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

  async refresh() {
    this.loading = true;
    try {
      const data = await Promise.all([
        this.terminalsService.getAll({mall: this.mall._id}),
        this.mallService.get(this.mall._id),
      ]);
      this.dataSource.data = data[0];
      this.mall = data[1];
    } catch (err) {
      this.dialogs.error(err);
    }
    this.loading = false;
  }

  async remove(terminal: Terminal) {
    try {
      if (await this.dialogs.confirm({
        title: this.translocoService.translate('are-you-sure'),
        message: this.translocoService.translate('remove', {item: terminal.name}),
      })) {
        await this.terminalsService.delete(terminal._id);
        const data = this.dataSource.data;
        const index = data.indexOf(terminal);
        data.splice(index, 1);
        this.dataSource.data = data;
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

  screenshots(terminal: Terminal) {
    this.router.navigate(['app', 'malls', this.mall._id, 'terminal', terminal._id, 'screenshots']);
  }

  copyToken(terminal: Terminal) {
    copyToBuffer(terminal.token);
    this.snackBar.open(this.translocoService.translate('value-copied'), this.translocoService.translate('close'), {
      duration: 2000,
    });
  }

  openWebInterface(terminal: Terminal, version: 'old' | '3d' | 'new') {
    let url = environment.web_terminal_url;
    switch (version) {
      case '3d':
        url = environment.new_web_terminal_url;
        break;
      case 'new':
        url = environment.next_web_terminal_url;
        break;
    }
    window.open(`${url}?token=${terminal.token}`, '_blank');
  }

  async showCommands(terminal: Terminal) {
    const res: TerminalCommands[] = await this.dialog.open(TerminalCommandsModalComponent, {
      data: {
        terminal,
      },
      minWidth: '500px',
    }).afterClosed().toPromise();
    this.router.navigate([], { queryParams: null });
    if (res) {
      terminal.commands = res;
    }
  }
}
