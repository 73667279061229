import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MapViewService} from "../../../../common/map-view/service/map-view.service";
import {Floor} from "../../../../dataset/map/Floor";

interface ImportData {
  floor: Floor;
  file: File;
}

@Component({
  selector: 'app-import-map-modal',
  templateUrl: './import-map-modal.component.html',
  styleUrls: ['./import-map-modal.component.scss']
})
export class ImportMapModalComponent implements OnInit {

  form: FormGroup;

  processing: boolean;

  operation: string;
  progress: number;
  floor: Floor;
  warnings: string[] = [];
  error: string;

  constructor(public dialogRef: MatDialogRef<ImportMapModalComponent, Floor>,
              private mapViewService: MapViewService,
              @Inject(MAT_DIALOG_DATA) public data: ImportData,
              private fb: FormBuilder) {
    this.form = this.fb.group({
      file: [data.file, Validators.required],
      pull_ratio: [10, Validators.required],
      graph_averaging_coefficient: [10, Validators.required]
    });
  }

  ngOnInit() {
  }

  async import() {
    try {
      this.processing = true;
      const file = this.form.value.file as File;
      const data = await this.readFile(file);
      const progressCallback = (operation, progress) => {
        this.operation = operation;
        this.progress = progress;
      };
      const {pull_ratio, graph_averaging_coefficient} = this.form.value;
      const result = await this.mapViewService.parse(data, this.data.floor.number, {
        pull_ratio,
        graph_averaging_coefficient
      }, progressCallback);
      if (!result.warnings.length) {
        this.dialogRef.close(result.floor);
      } else {
        this.floor = result.floor;
        this.warnings = result.warnings;
      }
      this.processing = false;
    } catch (e) {
      this.error = e;
      this.processing = false;
    }
  }

  async readFile(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function (e) {
        resolve(<string>(e.target as FileReader).result);
      };
      reader.onerror = function (e) {
        reject(e);
      };
      reader.readAsText(file);
    });
  }

}
