import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';
import { Drafty, Tinode } from 'tinode-sdk';
import { range } from 'lodash';
import { environment } from '../../../environments/environment';
import { Channel } from '../../dataset/Channel';
import {DomSanitizer} from "@angular/platform-browser";
import {Attachment, AttachmentType, ContactItem, Message, MessagesMap} from "../../dataset/Chat";
import {UploadService} from "../upload/upload.service";

const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/gif', 'image/png', 'image/svg', 'image/svg+xml'];
const MIME_EXTENSIONS = ['jpg', 'gif', 'png', 'svg', 'svg'];

enum SystemActionType {
  DELETE_MESSAGE = 'delete_message',
  LIKE_MESSAGE = 'like_message',
  REMOVE_LIKE_MESSAGE = 'remove_like_message',
  REPLAY_MESSAGE = 'replay_message',
}

interface SystemMessage {
  action: SystemActionType;
  topicId: string;
  messageId: number;
  data?: {
    messageId: number;
    txt: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private tinode: any;
  public userId: string;
  private meTopic: any;
  private contactsIds = [];
  public loadedTopics: Array<string> = [];

  private _messages: BehaviorSubject<MessagesMap> = new BehaviorSubject<MessagesMap>({});
  private _likedMessages: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public isOnline: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _contactList: BehaviorSubject<ContactItem[]> = new BehaviorSubject<ContactItem[]>([]);
  private _appeal: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public appeal$ = this._appeal.asObservable();

  public contactList$ = this._contactList.asObservable();
  public messages$ = this._messages.asObservable();
  private config = {
    appname: 'tango-tinode-chat',
    host: environment.chat_url,
    apiKey: environment.chat_api_token,
    transport: 'ws',
    secure: environment.isWSS,
    platform: 'web',
  };
  // после потери связи тинод необходимо пересоздать в onconnection во всех случаях кроме первого запуска
  private doNotRecreateTinodeAfterConnection = true;
  // после подключения надо всем чатам сообщить о том, что необходимо переподписаться
  updateTopicsSubject: Subject<void> = new Subject<void>();

  get messages() {
    return this._messages.getValue();
  }

  set messages(value) {
    this._messages.next(value);
  }

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private uploader: UploadService,
  ) {
    this.createTinode();
    // tinode не узнает про офлайн, пока не отправит сообщение или не чекнет статус
    setInterval(async () => {
      if (this.tinode?.isConnected()) {
        await this.tinode.networkProbe();
      }
    }, 10000);
  }

  createTinode() {
    if (!this.tinode) {
      this.tinode = new Tinode(this.config);
      this.tinode.onConnect = async () => {
        console.log('chats connected');
        this.isOnline.next(true);
        if (!this.doNotRecreateTinodeAfterConnection) {
          console.log('recreating tinode');
          this.getTinodeTokenAndConnect();
        }
      };
      this.tinode.onDisconnect = () => {
        console.log('chats disconnected');
        this.doNotRecreateTinodeAfterConnection = false;
        this.isOnline.next(false);
      };
      this.tinode.onAutoreconnectIteration = data => {
        console.log('chats are trying to reconnect', data);
      };
    }
  }

  async getTinodeTokenAndConnect() {
    try {
      const chatAuthData = await this.getToken();
      const { token, user: userId } = chatAuthData.ctrl.params;
      this.userId = userId;
      await this.tinode.connect();
      await this.tinode.loginToken(token);
      this.meTopic = await this.tinode.getMeTopic();
      this.meTopic.onContactUpdate = await this.contactsUpdate.bind(this);
      this.meTopic.onSubsUpdated = await this.meSubsUpdate.bind(this);
      this.meTopic.subscribe(
        this.meTopic.
        startMetaQuery().
        withLaterSub().
        withDesc().
        withTags().
        withCred().
        build()
      ).then(async () => {
        await this.updateContactsList();
        this.updateTopicsSubject.next();
      });
      this.doNotRecreateTinodeAfterConnection = false;
    } catch (e) {
      console.error(e);
    }
  }

  private releaseDeleteMessage(topicId: string, messageId: number) {

  }

  setAppeal(appeal: any) {
    this._appeal.next(appeal);
  }

  removeAppeal() {
    this._appeal.next(null);
  }

  releaseSystemMessage(actionData: SystemMessage) {
    switch (actionData.action) {
      case SystemActionType.DELETE_MESSAGE:
        this.releaseDeleteMessage(actionData.topicId, actionData.messageId);
        break;
      case SystemActionType.REPLAY_MESSAGE: {
        // сразу добавим инфу о цетирование в сообщение
        break;
      }
      default:
        break;
    }
  }

  getNewMessage(topic: string, data: any) {
    const currentTopicMsgArray = this.messages[topic] || [];
    let attachments = [];
    const content = data.content;
    let replayData = null;
    if (Drafty.isValid(content)) {
      // есть прикреплённый файл
      // так же может быть json который описывает различные действия с сообщениями
      // например удаление, цитирование, лайки и т.п.
      if (Drafty.hasAttachments(content)) {
        Drafty.attachments(content, (att: any, i: string) => {
          // условия что есть json который описывает какое либо действие над сообщениями
          // например удаление, цитирование, лайки и т.п.
          if (att.mime === 'application/json' && att?.val?.type === 'system_action') {
            const systemMessage: SystemMessage = {
              action: att.val.action,
              topicId: topic,
              messageId: att.val.messageId,
              data: att.val.data,
            };
            if (att.val.action === SystemActionType.REPLAY_MESSAGE) {
              replayData = {
                replayingMessageId: att.val.messageId,
                txt: att.val.data?.txt.trim() || 'Вложение',
              };
            }
            this.releaseSystemMessage(systemMessage);
          } else {
            // какое либо вложение, например файл
            if (att) {
              attachments.push({
                ...att,
                type: AttachmentType.FILE,
                src: Drafty.getDownloadUrl(att),
                ref: att.ref,
              });
            }
          }
        });
        // есть картинка
      } else {
        const formatingImageAttachment = Drafty.format(content, this.draftyFormatter, this);
        attachments = formatingImageAttachment.filter(Boolean);
      }
    }
    // tslint:disable-next-line: no-shadowed-variable
    const text = Drafty.toPlainText(content);
    if (text || attachments.length) {
      const newMessage: Message = {
        likedBy: data.head,
        messageId: data.seq,
        text,
        author: {
          chatId: data.from
        },
        createdAt: data.ts,
        attachments: attachments,
      };
      if (replayData) {
        newMessage.replayData = replayData;
      }
      const newMessageArray = [newMessage, ...currentTopicMsgArray].sort((a, b) => {
        const d1 = new Date(a.createdAt);
        const d2 = new Date(b.createdAt);
        return d1.getTime() - d2.getTime();
      });
      this._messages.next({
        ...this.messages,
        [topic]: [...newMessageArray],
      });
      if (newMessageArray.length) {
        const lastMessage = newMessageArray[newMessageArray.length - 1].text;
        const contactList = this._contactList.getValue().map(c => {
          if (c.topic === topic) {
            return { ...c, lastMessage: lastMessage };
          }
          return c;
        });

        this._contactList.next([...contactList]);
      }
    }
  }

  setLastMessage(topic: string, lastMessage: string) {
    const contactList = this._contactList.getValue()
      .map((c: ContactItem) => c.topic === topic ? { ...c, lastMessage: lastMessage } : c);
    this._contactList.next([...contactList]);
  }

  async connect() {
    await this.getTinodeTokenAndConnect();
  }

  async meSubsUpdate(data: string[]) {
    const oldList = this.contactsIds;
    this.contactsIds = data;
    const topics = [];
    if (oldList && oldList.length && data && data.length) {
      for (const item of data) {
        if (!oldList.includes(item)) {
          // если получать большее количество сообшении то всё очень плохо ws закрываться с 1006 ошибкой
          // так что получил лишь одно последнее сообщение
          const topic = await this.connectToTopic(item, 1);
          topics.push(topic);
        }
      }
    }
    await this.updateContactsList();
  }

  // при получение сообщения или при добавление текушего пользователя в контакты к другому пользователю
  async contactsUpdate(action: string, data: any) {
    if (action === 'on' || action === 'off') {
      this.setTopicOnlineStatus(data.topic, action === 'on');
    }
    if (action === 'msg') {
    }
    await this.updateContactsList();
  }

  private setTopicOnlineStatus(topic: string, isOnline: boolean) {
    const contactsList = this._contactList.getValue();
    const contactIndex = contactsList.findIndex(c => c.topic === topic);
    if (contactIndex > -1) {
      contactsList[contactIndex].isOnline = isOnline;
      this._contactList.next([...contactsList]);
    }
  }

  async updateContactsList() {
    const contactsArray = [];
    await this.tinode.getMeTopic().contacts(async (c: any) => {
      // update last message
      if (c) {
        const contactList = this._contactList.getValue();
        const lastMessage = contactList.find(i => i.topic === c.topic)?.lastMessage;
        let name = c.public?.fn;
        if (!name && c.public && c.public[0]) {
          name = c.public[0]?.Value;
        }
        const contact: ContactItem = {
          photo: c.public?.photo || undefined,
          topic: c.topic ? c.topic : c.name,
          name,
          unreadCount: c.unread,
          touched: c.touched,
          updated: c.updated,
          isOnline: c.online,
          lastMessage,
          lastReadMessageId: c.read,
          lastMessageId: c.seq,
          unreadeMessagesId: range(c.read + 1, c.seq + 1),
        };
        contactsArray.push(contact);
      }

    });
    contactsArray.sort((a, b) => {
      const d1 = new Date(a.touched);
      const d2 = new Date(b.touched);
      return d2.getTime() - d1.getTime();
    });
    this._contactList.next([...contactsArray]);
    return [...contactsArray];
  }

  async connectToTopic(topicId: string, pageSize = 50) {
    const oUser = this.tinode.getTopic(topicId);
    if (!oUser.isSubscribed()) {
      try {
        oUser.onData = this.getDataFromServer.bind(this);
        const conn = await oUser.subscribe(
          oUser
            .startMetaQuery()
            .withLaterDesc()
            .withLaterSub()
            // поличим одно последнее сообщение
            .withLaterData(pageSize)
            .withLaterDel()
            .build()
        );
        return conn;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    return null;
  }

  async connectToTopic2(topicId: string, pageSize = 50) {
    if (topicId) {
      const oUser = this.tinode.getTopic(topicId);
      if (!oUser.isSubscribed()) {
        await this.connectToTopic(topicId, pageSize);
        await this.meTopic.onSubsUpdated();
        await this.updateContactsList();
      } else {
        this.tinode.getTopic(topicId).getMessagesPage(pageSize);
      }
    }
  }

  async getNewMessages(topicId: string, pageSize: number) {
    await this.tinode.getTopic(topicId).getMessagesPage(pageSize);
  }

/*  async sendNoteAllRead(topicId: string, lastMessageId: number) {
    const findContact = this._contactList.value.filter(cont => cont.topic === topicId)[0];
    const topic = await this.tinode.getTopic(topicId);
    if (!topic.isSubscribed()) {
      this.connectToTopic(topicId);
    }
    if (findContact.unreadeMessagesId && findContact.unreadeMessagesId.length) {
      findContact.unreadeMessagesId.forEach(messageId => {
        topic.noteRead(messageId);
      });
    } else {
      topic.noteRead(lastMessageId);
    }
  }*/

  isConnected(): boolean {
    return this.tinode.isConnected();
  }

  getLikeMassage(data) {
    let likedMessagesArray = [];
    if (this._likedMessages.getValue().length) {
      likedMessagesArray = [...this._likedMessages.getValue()];
    }
    likedMessagesArray.push(data);
    this._likedMessages.next(likedMessagesArray);
  }

  private getDataFromServer(data: any) {
    if (data) {
      const { content, topic } = data;
      if (content && topic) {
        this.getNewMessage(topic, data);
      } else {
        // this.getLikeMassage(data);
      }
    }
  }

  async getTopicMeta(topicId: string, func: Function) {
    if (this.tinode && this.tinode.isConnected()) {
      const topic = this.tinode.getTopic(topicId);
      func(await topic.getMeta({ what: 'sub' }));
    } else {
      // TODO take(1)?
      this.isOnline
        .subscribe(async (value) => {
          if (value) {
            const topic = await this.tinode.getTopic(topicId);
            func(await topic.getMeta({ what: 'sub' }));
          }
        });
    }
  }

/*  async getTopicMode(topicId: string) {
    const topic = this.tinode.getTopic(topicId);
    return await topic.getMeta({ what: 'sub' });
  }*/

  async deleteMessage(topicId: string, messageId: number, hard = false): Promise<any> {
    const topic = this.tinode.getTopic(topicId);
    if (topic) {
      // await topic.delMessagesList([messageId], true);
      await this.http.delete(`/chat/${topicId}/${messageId}`).toPromise();
      const messageByTopicId = this.messages[topicId];
      const neededMessageIndex = messageByTopicId?.findIndex(m => m.messageId === messageId);
      if (neededMessageIndex > -1) {
        const newMessageArr = this.messages[topicId].filter(m => m.messageId !== messageId);
        this._messages.next({
          ...this.messages,
          [topicId]: [...newMessageArr],
        });
      }
    }
  }

  // TODO сначала надо проверить, что отправилось сообщение в тиноде, а уже потом дергать метод нотификации
  replayMessage(topicId: string, txt: string, replaingMsgId: number, replaingTxt): Promise<any> {
    const draftyText = Drafty.parse(txt);
    const jsonData = {
      type: 'system_action',
      action: SystemActionType.REPLAY_MESSAGE,
      topicId,
      messageId: replaingMsgId,
      data: {
        messageId: replaingMsgId,
        txt: replaingTxt,
      }
    };
    return Promise.all([
      this.tinode.publish(topicId, Tinode.Drafty.attachJSON(draftyText, jsonData)),
      this.http.post<any>(`/chat/notify-message`, {
        toAccount: topicId,
        from: this.userId,
        message: replaingTxt
      }).toPromise(),
    ]);
  }

  send(txt: string, topicId: string) {
    this.tinode.publish(topicId, txt);
    this.http.post<any>(`/chat/notify-message`, {
      toAccount: topicId,
      from: this.userId,
      message: txt
    }).toPromise();
  }

  getToken() {
    return this.http.post<any>(`/chat/login`, {}).toPromise();
  }

  removeMessageFromList(message: Message) {
    const topics = this._messages.getValue();
    const arr = topics[message.author.chatId].filter(mes => mes.messageId !== message.messageId);
    this.messages = {...this.messages, [message.author.chatId]: arr};
  }

  likeMessage(topicId: string, messageId: number) {
    return this.http.put<any>(`/chat/${topicId}/like/${messageId}`, {}).toPromise();
  }

  draftyFormatter(style: string, data: any, values: any, key: any): Attachment {
    const attr = Drafty.attrValue(style, data);
    if (attr) {
      switch (style) {
        // прикреплена картинка
        case 'IM':
          if (data) {
            const dst = this.fitImageSize(attr['data-width'], attr['data-height'], 295, 500);
            return {
              type: AttachmentType.IMAGE,
              name: attr.title,
              src: this.sanitizer.bypassSecurityTrustResourceUrl(attr.src),
              mime: attr['data-mime'],
              height: dst?.dstHeight,
              width: dst?.dstWidth,
              size: attr['data-size'],
            };
          }
          break;
        default:
          break;
      }
    }
  }

  fitImageSize(width = 0, height = 0, maxWidth = 0, maxHeight = 0) {
    if (width <= 0 || height <= 0 || maxWidth <= 0 || maxHeight <= 0) {
      return null;
    }
    const scale = Math.min(
      Math.min(width, maxWidth) / width,
      Math.min(height, maxHeight) / height
    );
    const size = {
      dstWidth: width * scale,
      dstHeight: height * scale,
    };
    return size;
  }

  async sendWidthData(drafty: any, txt: string, topicId: string) {
    const topic = this.tinode.getTopic(topicId);
    drafty.content.txt = txt;
    await topic.publishMessage(drafty, Promise.resolve());
  }

  async uploadImage(file: File, topicId: string, onSuccess: Function) {
    if (file.size >= 262144) {
      await this.uploadFIle(file, topicId, (result) => {
        onSuccess(result);
      });
    } else {
      const topic = this.tinode.getTopic(topicId);
      this.imageFileToBase64(
        file,
        async (
          bits: string, mime: string, width: number,
          height: number, fname: string, base64: string
        ) => {
          const preview = base64.split(',')[1];
          const a = { mime, width, height, filename: fname, preview };
          const msg = Drafty.insertImage(null, 0, a);
          const draftMsg = topic.createMessage(msg, false);
          onSuccess(draftMsg);
        },
      );
    }
  }

  async uploadFIle(file: File, topicId: string, onSuccess: Function) {
    const topic = this.tinode.getTopic(topicId);
    const formData = new FormData();
    formData.append('file', file, file.name);
    const uploadData = await this.uploader.uploadFileToChat(formData).toPromise();
    const msg = Drafty.attachFile(null, file.type, null, file.name, file.size, uploadData.ctrl.params.url);
    msg.ent[0] = {
      ...msg.ent[0],
      data: {
        mime: null,
        name: file.name,
        ref: uploadData.ctrl.params.url,
        size: file.size || 0
      }
    };
    const draftMsg = topic.createMessage(msg, false);
    onSuccess(draftMsg);
  }

  imageFileToBase64(file: File, onSuccess: Function) {
    const reader = new FileReader();
    const self = this;
    reader.onloadend = () => {
      const parts = (reader.result as string).split(',');
      const mime = self.getMimeType(parts[0]);
      // Получим размер картикнки
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        onSuccess(
          parts[1], mime, img.width, img.height, self.fileNameForMime(file.name, mime), (reader.result as string)
        );
      };
      try {
        img.src = URL.createObjectURL(file);
      } catch (error) {
        img.src = (reader.result as string);
      }
    };
    reader.readAsDataURL(file);
  }

  getMimeType(header: string) {
    const mime = /^data:(image\/[-+a-z0-9.]+);base64/.exec(header);
    return (mime && mime.length > 1) ? mime[1] : null;
  }

  fileNameForMime(fname: string, mime: string) {
    const idx = SUPPORTED_IMAGE_FORMATS.indexOf(mime);
    const ext = MIME_EXTENSIONS[idx];

    const at = fname.lastIndexOf('.');
    if (at >= 0) {
      fname = fname.substring(0, at);
    }
    return fname + '.' + ext;
  }

  async downloadAttachment(attachment: Attachment) {
    const { ref, name, mime } = attachment;
    const slug = ref.split('/').pop();
    const requestData = await this.http.get(
      `/chat/download/${slug}`, { responseType: 'arraybuffer'}
    ).toPromise();
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([requestData], {type: mime}));
    if (name) {
      downloadLink.setAttribute('download', name);
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  async hideChannel(channelId: string): Promise<Channel> {
    return await this.http.put<Channel>(`/chat/${channelId}/hide`, {}).toPromise();
  }

  async showChannel(channelId: string): Promise<Channel> {
    return await this.http.put<Channel>(`/chat/${channelId}/show`, {}).toPromise();
  }

  async getAllChannels(activityId: string): Promise<Channel[]> {
    return this.http.get<Channel[]>(`/chat?activity=${activityId}`).toPromise();
  }

  async updateChannel(channelId: string, data): Promise<Channel> {
    return this.http.put<Channel>(`/chat/${channelId}`, data).toPromise();
  }

  async pinChannel(activityId: string, channelId: string) {
    return await this.http.put('/activity-channels/activity-channels/add', {}, {
      params: {
        activityId,
        channelId,
      }
    }).toPromise();
  }

  async unPinChannel(activityId: string, channelId: string) {
    return await this.http.delete('/activity-channels/activity-channels/remove', {
      params: {
        activityId,
        channelId,
      }
    }).toPromise();
  }
}
