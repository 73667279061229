import {Graph} from './Graph';
import * as Snap from 'snapsvg-cjs';
import {Portal} from "./Portal";
import {MapObjectId} from "./MapObjectId";
import {values, compact} from 'lodash';

const ID = 'id';

export class Floor {

  locales: string[] = [];
  snap: Snap.Fragment;
  graph: Graph;
  graphTranslation: { x: number; y: number; };
  idToPoints: { [name: string]: string[] };
  pointsToId: { [name: string]: string } = {};
  number: number;
  name: string | number;
  portals: Portal[] = [];

  elements: { [id: string]: Snap.Element } = {};
  elementsByType: { [id: string]: string[] } = {};
  private disabled: string[] = [];

  constructor(number: number) {
    this.number = number;
  }

  private static getLang(str: string): string {
    return str.split(' ')[0].replace(/^logo/g, '').substring(0, 2);
  }

  cache() {
    console.log(`caching floor ${this.number}`);
    if (this.idToPoints) {
      const keys = Object.keys(this.idToPoints);
      keys.reduce((prev, current) => {
        this.idToPoints[current].forEach(k => prev[k] = current);
        return prev;
      }, this.pointsToId);
    }
    this.snap.select('g#objects').children().forEach(el => {
      // why try catch? because snap svg generates additional #text nodes which don't have a query selector
      try {
        const logos = el.selectAll('[id^="logo"]');

        logos.forEach(logoEl => {
          const lang = Floor.getLang(logoEl.attr(ID));
          if (!this.locales.includes(lang)) {
            this.locales.push(lang);
          }
        });

        const mapId = new MapObjectId(el.attr(ID));
        this.elements[mapId.id] = el;
        if (!this.elementsByType[mapId.type]) {
          this.elementsByType[mapId.type] = [];
        }
        this.elementsByType[mapId.type].push(mapId.id);
        if (mapId.accessible) {
          this.disabled.push(mapId.id);
        }
      } catch (e) {
        console.error('error caching element', el);
      }
    });

    this.locales = compact(this.locales);
  }

  getIdProjection(id): string[] {
    return this.idToPoints[id];
  }

  getElement(id: string): Snap.Element {
    return this.elements[id];
  }

  getIdsForType(type: string, disabled = false): string[] {
    const ids = this.elementsByType[type];
    if (!disabled || !ids) {
      return ids;
    }
    return ids.filter(i => this.disabled.includes(i));
  }

  getIds() {
    return Object.keys(this.elements);
  }

  getElements() {
    return values(this.elements);
  }
}
