import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Terminal} from '../../dataset/Terminal';
import {TerminalsService} from '../../providers/terminals/terminals.service';
import {Mall} from '../../dataset/Mall';

@Injectable()
export class MallTerminalsResolveService implements Resolve<Terminal[]> {

  constructor(private terminalsService: TerminalsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Terminal[]> {
    const mall: Mall = route.parent.data.mall;
    if (!mall.terminals) {
      mall.terminals = await this.terminalsService.getAll({mall: mall._id});
    }
    return mall.terminals;
  }

}
