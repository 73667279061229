import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SponsorGroup } from '../../dataset/SponsorGroup';
import { Sponsor } from '../../dataset/Sponsor';
import {UploadService} from "../upload/upload.service";

@Injectable({
  providedIn: 'root'
})
export class ActivitySponsorsService {

  constructor(private http: HttpClient,
              private uploadService: UploadService) {

  }

  async getAll(activity: string): Promise<Sponsor[]> {
    return this.http.get<Sponsor[]>(`/activity/${activity}/sponsors`).toPromise();
  }

  async create(activity: string, data: any): Promise<Sponsor> {
    return this.http.post<Sponsor>(`/activity/${activity}/sponsors`, data).toPromise();
  }

  async update(activityId: string, sponsorId: string, data: any): Promise<Sponsor> {
    return this.http.put<Sponsor>(`/activity/${activityId}/sponsors/${sponsorId}`, data).toPromise();
  }

  async delete(activityId: string, sponsorId: string) {
    return this.http.delete(`/activity/${activityId}/sponsors/${sponsorId}`).toPromise();
  }

  async getAllGroups(activity: string): Promise<SponsorGroup[]> {
    return this.http.get<SponsorGroup[]>(`/activity/${activity}/sponsor-groups`).toPromise();
  }

  async createGroup(activity: string, data: any): Promise<SponsorGroup> {
    return this.http.post<SponsorGroup>(`/activity/${activity}/sponsor-groups`, data).toPromise();
  }

  async updateGroup(activity: string, sponsorGroup: string, data: any): Promise<SponsorGroup> {
    return this.http.put<SponsorGroup>(`/activity/${activity}/sponsor-groups/${sponsorGroup}`, data).toPromise();
  }

  async deleteGroup(activity: string, sponsorGroup: string) {
    return this.http.delete(`/activity/${activity}/sponsor-groups/${sponsorGroup}`).toPromise();
  }

  async upload(activityId: string, sponsorId: string, file: File): Promise<Sponsor> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.post<Sponsor>(`/activity/${activityId}/sponsors/${sponsorId}/logo`, fileData).toPromise();
  }
}
