import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from '../../dataset/Role';
import {Account, AccountInActivity, AccountsPagination} from '../../dataset/Account';
import { Subject } from 'rxjs';
import { ActivityOrganizer } from '../../dataset/ActivityOrganizer';
import { AccountImportResult } from "../../root/mall/activities/activity/activity-accounts/activity-accounts-list/import-errors/import-error-types";
import {UploadService} from "../upload/upload.service";
import {GalleryPost} from "../../dataset/GalleryPost";

@Injectable({
  providedIn: 'root'
})
export class ManageAccountsService {

  importAccountsSubject: Subject<void> = new Subject<void>();
  exportAccountsSubject: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient,
              private uploadService: UploadService) {
  }

  async getAll(activity: string, limit: number, page: number): Promise<AccountsPagination> {
    return this.http.get<AccountsPagination>(`/manage-accounts/${activity}/accounts?limit=${limit}&page=${page}`).toPromise();
  }

  async createAccount(activity: string, data: any): Promise<AccountInActivity> {
    return this.http.post<AccountInActivity>(`/manage-accounts/${activity}/create-account`, data).toPromise();
  }

  async deleteAccount(activity: string, accountId: string) {
    return this.http.delete(`/manage-accounts/${activity}/remove-account?accountId=${accountId}`).toPromise();
  }

  async updateAccount(activity: string, accountId: string, data: any): Promise<AccountInActivity> {
    return this.http.put<AccountInActivity>(`/manage-accounts/${activity}/account?accountId=${accountId}`, data).toPromise();
  }

  async getAllRoles(activity: string): Promise<Role[]> {
    return this.http.get<Role[]>(`/manage-accounts/${activity}/activity-roles`).toPromise();
  }

  async createRole(activity: string, data: any) {
    return this.http.post<Role>(`/manage-accounts/${activity}/create-role`, data).toPromise();
  }

  async updateRole(activity: string, roleId: string, data: any) {
    return this.http.put<Role>(`/manage-accounts/${activity}/update-role?roleId=${roleId}`, data).toPromise();
  }

  async deleteRole(activity: string, roleId: string) {
    return this.http.delete<Role>(`/manage-accounts/${activity}/delete-role?roleId=${roleId}`).toPromise();
  }

  async getAccountsCSV(activity: string) {
    return this.http.get(`/manage-accounts/${activity}/export-accounts`, {
      responseType: 'blob',
    }).toPromise();
  }

  async uploadAccountsCSV(activityId: string, file: File): Promise<AccountImportResult[]> {
    const fileData = new FormData();
    fileData.append('file', file);
    return this.http.post<AccountImportResult[]>(`/manage-accounts/${activityId}/import-accounts`, fileData).toPromise();
  }

  async getActivityOrganizers(mall: string): Promise<ActivityOrganizer[]> {
    return this.http.get<ActivityOrganizer[]>(`/manage-accounts/${mall}/activity-organizers`).toPromise();
  }

  async getAdmins(mall: string): Promise<ActivityOrganizer[]> {
    return this.http.get<ActivityOrganizer[]>(`/manage-accounts/${mall}/activity-admins`).toPromise();
  }

  async inviteAdminOrOrganizer(mall: string, data: any): Promise<ActivityOrganizer> {
    return this.http.post<ActivityOrganizer>(`/manage-accounts/${mall}/invite-admin-or-organizer`, data).toPromise();
  }

  async createAdminOrOrganizer(mall: string, data: any): Promise<ActivityOrganizer> {
    return this.http.post<ActivityOrganizer>(`/manage-accounts/${mall}/create-admin-or-organizer`, data).toPromise();
  }

  async assignRole(mall: string, data: any): Promise<ActivityOrganizer> {
    return this.http.put<ActivityOrganizer>(`/manage-accounts/${mall}/assign-user-role`, data).toPromise();
  }

  async expelRole(mall: string, data: any): Promise<ActivityOrganizer[]> {
    return this.http.put<ActivityOrganizer[]>(`/manage-accounts/${mall}/expel-role`, data).toPromise();
  }

  async confirmInvite(data: any) {
    return this.http.post('/manage-accounts/user/confirm-invite', data).toPromise();
  }

  async upload(accountId: string, file: File): Promise<Account> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.post<Account>(`/account/${accountId}/avatar-upload`, fileData).toPromise();
  }
}
