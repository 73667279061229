import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from "./not-found/not-found.component";
import { InviteComponent } from './invite/invite.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'app/malls',
    pathMatch: 'full'
  },
  {
    path: 'invite',
    component: InviteComponent,
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
