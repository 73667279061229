import {Injectable} from '@angular/core';
import {SpeakerSession} from "../../dataset/SpeakerSession";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {SpeakerSessionsService} from "../../providers/speaker-sessions/speaker-sessions.service";

@Injectable({
  providedIn: 'root'
})
export class ActivitySpeakerSessionsResolverService implements Resolve<SpeakerSession[]> {

  constructor(private service: SpeakerSessionsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<SpeakerSession[]> {
    const id = route.parent.paramMap.get('id') || route.parent.parent.paramMap.get('id');
    return this.service.getAll(id);
  }
}
