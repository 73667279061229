import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "../../dataset/User";
import { Subscription } from "rxjs";
import { BehaviorSubject } from 'rxjs';
import { setUser } from "@sentry/browser";

// админы видят дополнительные блоки
const adminRoles = ['platform_administrator'];
// организаторы не видят ничего кроме мероприятий
const organizerRoles = ['activity_organizer'];

export enum UserRoleType {
  ADMIN,
  ORGANIZER,
  DEFAULT,
}

@Injectable()
export class UserService {

  private _user: BehaviorSubject<User> = new BehaviorSubject(null);

  get userRoles(): { isAdmin: boolean, isOrganizer: boolean } {
    if (!this.user) {
      return {
        isAdmin: false,
        isOrganizer: false,
      };
    }
    const userRoles = this.user.roles.map(role => role.name);
    let isAdmin = false;
    let isOrganizer = false;
    for (const role of userRoles) {
      if (adminRoles.includes(role)) {
        isAdmin = true;
        isOrganizer = false;
        break;
      }
      if (organizerRoles.includes(role)) {
        isOrganizer = true;
      }
    }
    return {
      isAdmin,
      isOrganizer,
    };
  }

  get user(): User {
    return this._user.getValue();
  }

  set user(user: User) {
    this._user.next(user);
    if (user) {
      setUser({
        id: user._id,
        email: user.email,
        username: user.name,
      });
    }
  }

  constructor(private http: HttpClient) {
  }

  subscribe(next?: (value: User) => void,
            error?: (error: any) => void,
            complete?: () => void): Subscription {
    return this._user.subscribe(next, error, complete);
  }

  async signIn(email: string, password: string): Promise<void> {
    const data = await this.http.post<{ apiKey: string }>('/user/sign-in', {
      email,
      password,
    }).toPromise();
    localStorage.setItem('access_token', data.apiKey);
  }

  async forgot(email: string): Promise<void> {
    await this.http.post<{}>('/user/forgot-password', {
      email,
    }).toPromise();
  }

  async me(force: boolean = false): Promise<User> {
    if (this.user && !force) {
      return this.user;
    }
    return this.user = await this.http.get<User>('/user/me').toPromise();
  }

  async logout() {
    this.user = null;
    localStorage.setItem('access_token', null);
  }

  async updateMe(data: any) {
    if (!data.password) {
      delete data.password;
    }
    this.user = await this.http.put<User>('/user/me', data).toPromise();
  }
}
