import {Facade} from "../app/dataset/Facade";

export function filterFacade<T extends {facade: {[locale: string]: any}}>(item: T, locale: string, str: string, key?: string): boolean {
  if (item.facade[locale]) {
    return item.facade[locale][key || 'name'].toLowerCase().includes(str.toLowerCase().trim());
  }
  if (item.facade['en']) {
    return item.facade['en'][key || 'name'].toLowerCase().includes(str.toLowerCase().trim());
  }
  return false;
}

export function sortFacadeByName(a: Facade<{name: string}>, b: Facade<{name: string}>, locale: string): number {
  const firstFacade = a[locale]?.name || '';
  const secondFacade = b[locale]?.name || '';
  if (firstFacade < secondFacade) {
    return -1;
  }
  if (firstFacade > secondFacade) {
    return 1;
  }
  return 0;
}
