import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country } from '../../dataset/Country';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(private http: HttpClient) {
  }

  async getAll(): Promise<Country[]> {
    return this.http.get<Country[]>('/country/get-countries').toPromise();
  }

  async get(id: string): Promise<Country> {
    return this.http.get<Country>(`/country/get-country/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Country>(`/country/create-country`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<Country>(`/country/update-country/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<Country>(`/country/remove-country/${id}`).toPromise();
  }
}
