import { Injectable } from '@angular/core';
import { MarketingService } from '../../providers/marketing/marketing.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MarketingAction } from '../../dataset/MarketingAction';
import { Mall } from '../../dataset/Mall';

@Injectable()
export class MarketingResolveService implements Resolve<MarketingAction[]> {

  constructor(private marketingService: MarketingService) {

  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<MarketingAction[]> {
    const mall: Mall = route.parent.data.mall;
    return await this.marketingService.getAll(mall._id);
  }

}
