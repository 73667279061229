import {SafeResourceUrl} from '@angular/platform-browser';

export class Message {
  _id?: string;
  messageId?: number;
  text: string;
  createdAt: Date;
  likedBy?: any;
  author: Author;
  attachments?: Attachment[];
  replayData?: {
    replayingMessageId: number;
    txt: string;
  };
  isReaded?: boolean;
}

interface Author {
  chatId: string;
  name?: string;
  avatar?: string;
}

export interface Attachment {
  type: AttachmentType;
  mime: string;
  name: string;
  size: number;
  src?: SafeResourceUrl;
  ref?: string;
  height?: number;
  width?: number;
}

export enum AttachmentType {
  FILE = 'file',
  IMAGE = 'image',
}

export interface MessagesMap {
  [key: string]: Message[];
}

export interface ContactItem {
  photo?: string;
  topic: string;
  name: string;
  unreadCount: number;
  touched: Date;
  updated: Date;
  isOnline: boolean;
  lastMessage?: string;
  lastReadMessageId: number;
  lastMessageId: number;
  unreadeMessagesId: number[];
}

export interface MetaInfoItem {
  online: boolean;
  public?: {
    fn: string;
    photo?: {
      url: string;
    };
  };
  user: string;
}
