import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {MeetingsService} from "../../providers/meetings/meetings.service";
import {MeetingPlace} from "../../dataset/MeetingPlace";
import { Activity } from '../../dataset/Activity';

@Injectable()
export class ActivityMeetingPlacesResolveService implements Resolve<MeetingPlace[]> {

  constructor(private meetingsService: MeetingsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<MeetingPlace[]> {
    const activity: Activity = route.parent.data.activity;
    return await this.meetingsService.getMeetingPlaces(activity._id);
  }

}
