import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivitiesService } from '../../providers/activities/activities.service';
import { ActivityFile } from '../../dataset/Activity';

@Injectable({
  providedIn: 'root'
})
export class ActivityFilesResolver implements Resolve<any> {

  constructor(private activitiesService: ActivitiesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ActivityFile[]> {
    return this.activitiesService.getFiles(route.parent.paramMap.get('id'));
  }
}
