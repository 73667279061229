import {Component, Inject, OnInit} from '@angular/core';
import {Shop} from "../../../../dataset/Shop";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {map} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import {Mall} from "../../../../dataset/Mall";
import {CustomObjectTypes} from "../../../../dataset/map/MapObjectId";
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-attach-shop-modal',
  templateUrl: './attach-shop-modal.component.html',
  styleUrls: ['./attach-shop-modal.component.scss']
})
export class AttachShopModalComponent implements OnInit {

  shops: { shop: Shop, attached: boolean }[] = [];
  filteredShops: BehaviorSubject<{ shop: Shop, attached: boolean }[]>;
  searchControl = new FormControl();
  locale = 'en';
  selectedShop: Shop;
  selectedType: CustomObjectTypes = CustomObjectTypes.OBJECT;
  customObjectTypes = Object.values(CustomObjectTypes);

  constructor(public dialogRef: MatDialogRef<AttachShopModalComponent>,
              private translocoService: TranslocoService,
              @Inject(MAT_DIALOG_DATA) public data: { shops: Shop[], attached: string[], mall: Mall }) {
  }

  async ngOnInit() {
    this.locale = this.translocoService.getActiveLang();
    const name = s => s.shop?.facade[this.locale]?.name;
    const attached = (a, b) => a.attached === b.attached;
    this.shops = this.data.shops.map(shop => ({shop, attached: this.data.attached.includes(shop._id)}));
    this.selectedShop = this.shops.find(item => item.attached)?.shop || null;
    this.shops.sort((a, b) => attached(a, b) ? (name(a) === name(b) ? 0 : name(a) > name(b) ? 1 : -1) : a.attached ? 1 : -1);
    this.filteredShops = new BehaviorSubject(this.shops);
    this.searchControl.valueChanges
      .pipe(
        map(v => this.shops.filter(s => s.shop.facade[this.locale].name.toLowerCase().indexOf(v.toLowerCase()) !== -1))
      )
      .subscribe(this.filteredShops);
  }

  select(shop: Shop) {
    this.selectedShop = shop;
  }

  apply() {
    this.dialogRef.close({shop: this.selectedShop, type: this.selectedType});
  }

}
