import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Mall} from "../../../dataset/Mall";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ImportMapModalComponent} from "./import-map-modal/import-map-modal.component";
import {clone, difference, keyBy} from "lodash";
import {MallsService} from "../../../providers/malls/malls.service";
import {DialogService} from "../../../common/alert-dialog/services/dialog.service";
import {plainToClass} from "class-transformer";
import * as Snap from 'snapsvg-cjs';
import {AttachTerminalModalComponent} from "./attach-terminal-modal/attach-terminal-modal.component";
import {Terminal} from "../../../dataset/Terminal";
import {TerminalsService} from "../../../providers/terminals/terminals.service";
import {AttachShopModalComponent} from "./attach-shop-modal/attach-shop-modal.component";
import {Shop} from "../../../dataset/Shop";
import {distance} from "../../../../utils/shape.utils";
import {CustomObjectTypes, MapObjectId} from "../../../dataset/map/MapObjectId";
import {Graph, idGen} from "../../../dataset/map/Graph";
import {Floors} from "../../../dataset/map/Floors";
import {Floor} from "../../../dataset/map/Floor";
import {GraphEdge} from "../../../dataset/map/GraphEdge";
import {MapViewComponent} from "../../../common/map-view/map-view.component";
import { getSortedAvailableLocales } from "../../../../utils/locale.utils";
import { TranslocoService } from "@ngneat/transloco";
import {FacadePipe} from "../../../common/pipes/facade.pipe";
import {getCoordinatesFromString} from "../../../../utils/utils";
import {AttachOrRemoveModalComponent, AttachOrRemoveResult} from "./attach-or-remove-modal/attach-or-remove-modal.component";
import {UUID} from "angular2-uuid";
import {ThreedSettingsModalComponent} from "./threed-settings-modal/threed-settings-modal.component";
import {MapConfig} from "../../../dataset/map/MapConfig";
import {AttachTerminalOrQrComponent, AttachTerminalOrQrResult} from "./attach-terminal-or-qr/attach-terminal-or-qr.component";
import {QrCodesService} from "../../../providers/qr-codes/qr-codes.service";
import {AttachQrModalComponent} from "./attach-qr-modal/attach-qr-modal.component";
import {QrCode} from "../../../dataset/QrCode";

@Component({
  selector: 'app-map-editor',
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.scss']
})
export class MapEditorComponent implements OnInit {

  @ViewChild(MapViewComponent, {static: true}) map: MapViewComponent;
  @ViewChild('floorsList') floorsList;
  @ViewChild('configInput') configInput: ElementRef<HTMLTextAreaElement>;

  mall: Mall;
  floors: Floor[] = [];
  currentFloor: Floor;

  currentFloorNumber = Infinity;

  floorsObject = new Floors([]);

  fromId: string;
  fromFloor: number;

  loading = false;

  shops: Shop[] = [];
  shopsMap: { [_id: string]: Shop } = {};

  terminals: Terminal[];
  qrCodes: QrCode[] = [];
  locale = 'en';
  locales: string[];

  showFloorMenu = false;
  showQrMenu = false;

  mapConfig: Object | MapConfig = {};
  // if map is big, we upload it to different location
  isBigMap = false;
  showBoundPointsLabels = false;

  constructor(private route: ActivatedRoute,
              private dialog: MatDialog,
              private dialogs: DialogService,
              private mallsService: MallsService,
              private translocoService: TranslocoService,
              private facadePipe: FacadePipe,
              private terminalsService: TerminalsService,
              private qrCodesService: QrCodesService) {
    this.route.data.subscribe(async (data: {mall: Mall, map: { floors: any[], mapConfig: any }, qrCodes: QrCode[], shops: Shop[]}) => {
      this.mall = data.mall;
      this.shops = data.shops;
      this.isBigMap = data.mall.isBigMap;
      this.qrCodes = data.qrCodes;
      this.locale = this.translocoService.getActiveLang();
      this.locales = getSortedAvailableLocales(data.mall.settings.frontend_available_locales, this.locale);
      this.shopsMap = keyBy(this.shops, s => s._id);
      let floorsObject;
      if (data.mall.isBigMap) {
        const config = await this.mallsService.getBigMapByUrl(data.mall.bigMapUrl);
        floorsObject = config.floors;
        this.mapConfig = config.mapConfig;
      } else {
        floorsObject = data.map.floors;
        this.mapConfig = data.map.mapConfig;
      }
      this.floors = floorsObject.map(f => {
        const graph = new Graph(f.graph.nodes, f.graph.edges.map(e => plainToClass(GraphEdge, e)));
        const {portals, graphTranslation, idToPoints, number, name} = f;
        const snapFragment: Snap.Fragment = Snap.parse(f.snap);
        const snap = snapFragment.select('svg > g');
        const floor = new Floor(number);
        floor.snap = snap;
        floor.graph = graph;
        floor.portals = portals;
        floor.name = name;
        floor.graphTranslation = graphTranslation;
        floor.idToPoints = idToPoints;
        floor.cache();
        return floor;
      }).sort((a, b) => a.number - b.number);
      this.floorsObject = new Floors(this.floors);
      this.currentFloorNumber = this.floors.length ? this.floors[0].number : Infinity;
    });
  }

  async ngOnInit() {
    const data = await this.terminalsService.getAll({mall: this.mall._id});
    data.forEach(t => t.mapId = t.position ? idGen(t.position.x, t.position.y, t.floor) : undefined);
    this.terminals = data;
    this.map.selectObjects(difference(this.floorsObject.getAllIds(), Object.keys(this.shopsMap)));
    if (this.mall.dataSetProxy?.url && this.mall.dataSetProxy?.shops) {
      this.dialogs.alert({
        title: this.translocoService.translate('warning'),
        message: this.translocoService.translate('proxyInfo'),
      });
    }
  }

  addFloor() {
    this.currentFloor = new Floor(this.floors.length + 1);
    this.floors.push(this.currentFloor);
    this.floors.sort((a, b) => a.number - b.number);
    this.floorsObject = new Floors(this.floors);
    this.currentFloorNumber = this.currentFloor.number;
    // Перед тем как пролистывать в конец списка дадим время перерисовать компонент
    setTimeout(() =>
      this.floorsList.nativeElement.scrollTop
        = this.floorsList.nativeElement.scrollHeight, 100);
  }

  selectFloor(floor: Floor) {
    this.currentFloor = floor;
    this.currentFloorNumber = floor.number;
  }

  async importFloor(file: File) {
    const ref = this.dialog.open(ImportMapModalComponent, {
      data: {
        floor: this.currentFloor,
        file,
        terminals: this.terminals.filter(t => t.floor === this.currentFloor.number)
      },
      minWidth: '600px',
      disableClose: true
    });
    const res: Floor = await ref.afterClosed().toPromise();
    if (res) {
      if (this.currentFloor.snap) {
        this.currentFloor.snap.remove();
      }
      res.cache();
      Object.assign(this.currentFloor, res);
      this.floorsObject = new Floors(this.floors);
      this.currentFloorNumber = this.currentFloor.number;
    }
  }

  floorNumberChange(index: number) {
    this.currentFloor = this.floorsObject.get(index);
  }

  exportFloor() {
    this.downloadFile();
  }

  downloadFile() {
    const data = (this.currentFloor.snap as Snap.Paper).toString();
    const blob = new Blob([`<svg fill="none" xmlns="http://www.w3.org/2000/svg">${data}</svg>`], {type: 'image/svg+xml'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = `${this.mall.name}-${this.currentFloor.number}-floor.svg`;
    a.href = url;
    a.click();
  }

  async reAttach() {
    try {
      const terminals = this.terminals.filter(t => t.floor === this.currentFloor.number && t.position && !this.currentFloor.graph.nodes.includes(t.mapId));
      if (!terminals.length) {
        return;
      }
      const points = this.currentFloor.graph.nodes.map(point => getCoordinatesFromString(point));
      await Promise.all(terminals.map(async t => {
        for (const p of points) {
          if (distance(p, t.position) < 5) {
            t.position = p;
            t.mapId = idGen(p.x, p.y, this.currentFloor.number);
            await this.terminalsService.update(t._id, {position: t.position});
            break;
          }
        }
      }));
      const lost = this.terminals.filter(t => t.floor === this.currentFloor.number && t.position && !this.currentFloor.graph.nodes.includes(t.mapId));
      if (lost.length) {
        this.dialogs.alert({message: `${lost.map(t => t.name).join(', ')} is out of path graph`});
      }
    } catch (e) {
      console.log(e);
      this.dialogs.error(e);
    }
  }

  async reAttachQrCodes() {
    try {
      const qrCodes = this.qrCodes.filter(code => code.floor === this.currentFloor.number && code.position && !this.currentFloor.graph.nodes.includes(idGen(code.position.x, code.position.y, code.floor)));
      if (!qrCodes.length) {
        return;
      }
      const points = this.currentFloor.graph.nodes.map(point => getCoordinatesFromString(point));
      await Promise.all(qrCodes.map(async code => {
        for (const p of points) {
          if (distance(p, code.position) < 5) {
            code.position = p;
            await this.qrCodesService.update(code._id, {position: code.position});
            break;
          }
        }
      }));
      const lost = this.qrCodes.filter(code => code.floor === this.currentFloor.number && code.position && !this.currentFloor.graph.nodes.includes(idGen(code.position.x, code.position.y, code.floor)));
      if (lost.length) {
        this.dialogs.alert({message: `${lost.map(t => t.title).join(', ')} is out of path graph`});
      }
    } catch (e) {
      console.log(e);
      this.dialogs.error(e);
    }
  }

  async save() {
    this.loading = true;
    try {
      const floors = this.floors.map((f, index) => {
        const object = clone(f);
        const snap = object.snap;
        delete object.snap;
        snap.selectAll('.active-object').forEach(obj => obj.removeClass('active-object'));
        snap.selectAll('.hidden').forEach(obj => obj.removeClass('hidden'));
        object.snap = `<svg>${
          snap.toString()
            .replace(/(\n|\t)/g, '')
            .replace(/(>(\s+)<)/g, '><')
        }</svg>`;
        return object;
      });
      // check mysterious empty elements error
      for (const floor of floors) {
        if (!floor.elements || !floor.elementsByType || Object.keys(floor.elements).length === 0 || Object.keys(floor.elementsByType).length === 0) {
          throw new Error(`It looks like floor ${floor.number} doesn't have any Elements. There is likely an error, please contact the support`);
        }
      }
      const data = {
        floors: floors.map(floor => ({
          ...floor,
          snap: encodeURIComponent(floor.snap)
        })),
        mapConfig: this.mapConfig,
        isBigMap: this.isBigMap,
        mallVersion: this.mall.mallVersion,
      };
      if (!this.mapConfig) {
        delete data.mapConfig;
      }
      await this.mallsService.updateMap(this.mall, data);
      await this.reAttach();
      await this.reAttachQrCodes();
    } catch (err) {
      this.dialogs.error(err);
    }
    this.loading = false;
    this.map.selectObjects(difference(this.floorsObject.getAllIds(), Object.keys(this.shopsMap)));
  }

  async removeFloor() {
    if (await this.dialogs.confirm({
      title: this.translocoService.translate('are-you-sure'),
      message: this.translocoService.translate('floor-delete', {floor: this.currentFloor.number})
    })) {
      let floorIndex = this.floors.indexOf(this.currentFloor);
      this.floors.splice(floorIndex, 1);
      this.floors.sort((a, b) => a.number - b.number);
      this.floorsObject = new Floors(this.floors);
      if (!this.floors.length) this.addFloor();
      if (this.floors.length === floorIndex) floorIndex -= 1;
      this.currentFloorNumber = this.floors.length ? this.floors[floorIndex].number : Infinity;
    }
  }

  async graphPointClicked(coordinates: { x: number; y: number }) {

    const {x, y} = coordinates;
    const modalResult = await this.dialog.open(AttachTerminalOrQrComponent, {
      minWidth: 500,
    }).afterClosed().toPromise();
    if (modalResult !== null) {
      const dataFormModal = {
        id: idGen(x, y, this.currentFloorNumber),
        mall: this.mall._id,
        nodes: this.floorsObject.graph.nodes
      };
      if (modalResult === AttachTerminalOrQrResult.TERMINAL) {
        const ref = this.dialog.open(AttachTerminalModalComponent, {
          minWidth: '600px',
          data: dataFormModal,
        });
        const res: Terminal = await ref.afterClosed().toPromise();
        if (res && await this.dialogs.confirm({
          title: this.translocoService.translate('are-you-sure'),
          message: this.translocoService.translate('terminal-attach', {shop: res.name, x, y}),
        })) {
          try {
            res.floor = this.currentFloor.number;
            res.position = {x, y};
            await this.terminalsService.update(res._id, res);
            this.dialogs.alert({
              title: this.translocoService.translate('success'),
              message: this.translocoService.translate('terminal-attached'),
            });
          } catch (err) {
            this.dialogs.error(err);
          }
        }
      } else {
        const ref = this.dialog.open(AttachQrModalComponent, {
          minWidth: '600px',
          data: dataFormModal,
        });
        const res: QrCode = await ref.afterClosed().toPromise();
        if (res && await this.dialogs.confirm({
          title: this.translocoService.translate('are-you-sure'),
          message: this.translocoService.translate('qr.attach'),
        })) {
          try {
            res.floor = this.currentFloor.number;
            res.position = {x, y};
            await this.qrCodesService.update(res._id, res);
            this.dialogs.alert({
              title: this.translocoService.translate('success'),
              message: this.translocoService.translate('qr.attached'),
            });
          } catch (err) {
            this.dialogs.error(err);
          }
        }
      }
    }
  }

  async objectClicked(mapId: MapObjectId) {
    const shop = this.shopsMap[mapId.id];

    if (shop) {
      const res = await this.dialog.open(AttachOrRemoveModalComponent, {
        data: {
          message: this.translocoService.translate('object-already-attached', {shop: this.facadePipe.transform(shop.facade, this.locale)}),
        }
      }).afterClosed().toPromise();
      switch (res) {
        case AttachOrRemoveResult.DETACH:
          this.reattachShop(mapId, true);
          break;
        case AttachOrRemoveResult.ATTACH:
          this.reattachShop(mapId);
          break;
        default:
          return;
      }
    } else {
      this.reattachShop(mapId);
    }
  }

  private async reattachShop(mapId: MapObjectId, detach?: boolean) {
    const lastId = mapId.id;
    const lastType = mapId.type;

    const swapParams = (shopId: string, type: string) => {
      const el = this.currentFloor.getElement(lastId);
      mapId.id = shopId;
      mapId.type = type;
      el.attr('id', mapId.toString());
      el.node.classList.remove(...el.node.classList);
      el.node.classList.add(type);
      this.currentFloor.elements[mapId.id] = el;
      delete this.currentFloor.elements[lastId];
      this.currentFloor.idToPoints[mapId.id] = this.currentFloor.idToPoints[lastId];
      delete this.currentFloor.idToPoints[lastId];
      Object.keys(this.currentFloor.pointsToId).forEach(key => {
        const id = this.currentFloor.pointsToId[key];
        if (id === lastId) {
          this.currentFloor.pointsToId[key] = mapId.id;
        }
      });
      const index = this.currentFloor.elementsByType[lastType].indexOf(lastId);
      if (index !== -1) {
        this.currentFloor.elementsByType[lastType].splice(index, 1);
      }
      if (!this.currentFloor.elementsByType[mapId.type]) {
        this.currentFloor.elementsByType[mapId.type] = [mapId.id];
      } else {
        this.currentFloor.elementsByType[mapId.type].push(mapId.id);
      }
    };

    if (detach) {
      // you can't just "delete" it, it will break logic. you can only replace it
      swapParams(UUID.UUID(), CustomObjectTypes.INACTIVE);
    } else {
      const ref = this.dialog.open(AttachShopModalComponent, {
        minWidth: '600px',
        data: {
          shops: this.shops,
          attached: this.floorsObject.getAllIds(),
          mall: this.mall
        }
      });
      const res: { shop: Shop, type: CustomObjectTypes } = await ref.afterClosed().toPromise();
      if (res) {
        swapParams(res.shop._id, res.type);
      }
    }
    this.map.selectObjects(difference(this.floorsObject.getAllIds(), Object.keys(this.shopsMap)));
  }

  selectLocale(locale: string) {
    this.locale = locale;
  }

  toggleFloorMenu() {
    this.showFloorMenu = !this.showFloorMenu;
  }

  toggleQrMenu() {
    this.showQrMenu = !this.showQrMenu;
  }

  openSettings(): void {
    this.dialog.open(ThreedSettingsModalComponent, {
      width: '90%',
      data: {
        isBigMap: this.isBigMap,
        mapConfig: this.mapConfig,
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.isBigMap = data.isBigMap;
        this.mapConfig = data.mapConfig;
      }
    });
  }
}
