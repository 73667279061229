import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SpeakerSessionsService } from '../../../../../../../../../providers/speaker-sessions/speaker-sessions.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpeakerSession, SpeakerSessionFile } from '../../../../../../../../../dataset/SpeakerSession';
import { DialogService } from '../../../../../../../../../common/alert-dialog/services/dialog.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Mall } from '../../../../../../../../../dataset/Mall';
import { Facade, FacadeData } from '../../../../../../../../../dataset/Facade';
import { map } from 'lodash';
import { getSortedAvailableLocales } from '../../../../../../../../../../utils/locale.utils';

@Component({
  selector: 'app-edit-file-dialog',
  templateUrl: './edit-file-dialog.component.html',
  styleUrls: ['./edit-file-dialog.component.scss']
})
export class EditFileDialogComponent implements OnInit, OnDestroy {

  file: File;
  form: FormGroup;
  loading = false;
  locales: string[] = [];
  locale = 'en';

  constructor(private service: SpeakerSessionsService,
              public dialogRef: MatDialogRef<EditFileDialogComponent>,
              private dialogs: DialogService,
              private fb: FormBuilder,
              private translocoService: TranslocoService,
              @Inject(MAT_DIALOG_DATA) public data: { file: SpeakerSessionFile, mall: Mall, session: SpeakerSession }) {
    const {file, mall} = this.data;
    this.locale = this.data.mall.settings?.default_locale || this.translocoService.getActiveLang();
    this.locales = getSortedAvailableLocales(mall.settings.frontend_available_locales, this.locale);
    this.form = this.fb.group({
      facade: this.fb.array([this.fb.group({
        __locale: this.locale,
        title: [null, Validators.required],
        description: null,
      })]),
      fileIndex: [null, Validators.required]
    });
    if (file) {
      const fileData = Object.assign({}, file);
      delete fileData.facade;
      this.form.patchValue(fileData);
      const facades = this.fb.array(map(file.facade, (facade, __locale) => this.fb.group({__locale, ...facade})));
      this.form.setControl('facade', facades);
    }
  }

  ngOnInit(): void {
  }

  changeFile($event: Event) {
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    if (target.files.length !== 0) {
      this.file = target.files.item(0);
    }
  }

  async confirm() {
    this.loading = true;
    this.form.disable();
    const facade: Facade<FacadeData> = this.facades.controls.reduce((last, control) => {
      const {__locale} = control.value;
      const value = Object.assign({}, control.value);
      delete value.__locale;
      last[__locale] = value;
      return last;
    }, {});
    try {
      let res;
      if (this.data.file) {
        res = await this.service.updateFile(this.data.session._id, this.data.file.fileUrl, facade, this.form.value.fileIndex);
      } else {
        const data = await this.service.addFile(this.data.session._id, this.file, facade, this.form.value.fileIndex);
        res = data.sessionFiles;
      }
      this.dialogRef.close(res);
    } catch (e) {
      this.dialogs.error(e);
    }
    this.form.enable();
    this.loading = false;
  }

  get facades(): FormArray {
    return this.form.get('facade') as FormArray;
  }

  hasFacade(__locale: string): boolean {
    return this.facades.controls.filter(c => c.value.__locale === __locale).length !== 0;
  }

  removeFacade(index: number) {
    this.facades.removeAt(index);
  }

  addFacade(__locale: string) {
    this.facades.push(this.fb.group({
      __locale,
      title: [null, Validators.required],
      description: null,
    }));
  }

  localeName(__locale: string) {
    return __locale;
  }

  localsCount(): number {
    return this.locales.length;
  }

  ngOnDestroy() {
  }

}
