import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Mall} from "../../dataset/Mall";
import {ActivatedRoute, Router} from "@angular/router";
import { NavigationService } from '../../providers/navigation/navigation.service';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-mall',
  templateUrl: './mall.component.html',
  styleUrls: ['./mall.component.scss']
})
export class MallComponent implements OnDestroy {

  @ViewChild('sidenav', { read: MatSidenav, static: true }) sidenav: MatSidenav;

  mall: Mall;

  version = environment.version;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private titleService: Title,) {
    this.route.data.subscribe((data: { mall: Mall }) => {
      this.mall = data.mall;
      this.navigationService.updateNavigation(data.mall._id);
      this.titleService.setTitle(`${this.mall.name} - TV CP`);
    });
    this.router.events.subscribe(event => {
      if (this.sidenav) {
        this.sidenav.close();
      }
    });
  }

  back() {
    this.router.navigateByUrl(`/app/malls`);
    this.titleService.setTitle('Tango Control');
  }

  ngOnDestroy(): void {
    this.navigationService.updateNavigation();
    this.titleService.setTitle('Tango Control');
  }

}
