import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Shop} from "../../../../dataset/Shop";
import {DirtyFormModalService} from "../../../../providers/dirty-form-modal/dirty-form-modal.service";
import {ShopsService} from "../../../../providers/shops/shops.service";
import {combineDateAndTime, getDateAndTime} from "../../../../../utils/time.utils";
import {DialogService} from "../../../../common/alert-dialog/services/dialog.service";

@Component({
  selector: 'app-shop-deactivate',
  templateUrl: './shop-deactivate.component.html',
  styleUrls: ['./shop-deactivate.component.scss']
})
export class ShopDeactivateComponent implements OnInit {

  form: FormGroup;
  now = new Date();

  constructor(private fb: FormBuilder,
              private dialogRef: MatDialogRef<ShopDeactivateComponent>,
              private dirtyFormService: DirtyFormModalService,
              private shopService: ShopsService,
              private dialogs: DialogService,
              @Inject(MAT_DIALOG_DATA) public data: { shop: Shop }) {
    this.form = fb.group({
      date: [null, Validators.required],
      time: [null, Validators.required],
    });
    this.dirtyFormService.registerForm(this.form, this.dialogRef);
  }

  ngOnInit(): void {
    if (this.data.shop.inActiveDate) {
      this.form.patchValue(getDateAndTime(this.data.shop.inActiveDate));
    }
  }

  async confirm(): Promise<void> {
    this.form.disable();
    try {
      const {date, time} = this.form.value;
      const {shop} = this.data;
      const res: Shop = await this.shopService.deactivate(shop._id, {
        mall: shop.mall,
        date: combineDateAndTime(date, time),
      });
      this.dialogRef.close(res);
    } catch (e) {
      this.dialogs.error(e);
    }
    this.form.enable();
  }

  async cancelDeactivation() {
    this.form.disable();
    try {
      const res: Shop = await this.shopService.cancelDeactivation(this.data.shop._id);
      this.dialogRef.close(res);
    } catch (e) {
      this.dialogs.error(e);
    }
    this.form.enable();
  }

  cancel() {
    this.dirtyFormService.closeSafely();
  }

}
