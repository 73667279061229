import {UUID} from 'angular2-uuid';

export class GraphEdge {

  constructor(public from: string,
              public to: string,
              public distance: number,
              public d: string,
              public fromFloor: number,
              public toFloor: number,
              public bidirectional: boolean = true,
              public id: string = UUID.UUID()) {
  }

}
