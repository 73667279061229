import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogService} from "../../common/alert-dialog/services/dialog.service";
import {UserService} from "../../providers/user/user.service";
import {fuseAnimations} from '@fuse/animations';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations   : fuseAnimations
})
export class SignInComponent implements OnInit {

  form: FormGroup;

  constructor(private router: Router,
              private fb: FormBuilder,
              private userService: UserService,
              private dialog: DialogService) {
    this.form = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  ngOnInit() {
  }

  async login() {
    this.form.disable();
    try {
      await this.userService.signIn(
        this.form.value.email,
        this.form.value.password
      );
      this.router.navigateByUrl('/app/malls');
    } catch (err) {
      this.dialog.error(err);
    }
    this.form.enable();
  }

}
