import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private expanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private textSubject: Subject<string> = new Subject<string>();

  get collapsedObservable(): Observable<boolean> {
    return this.expanded.asObservable();
  }

  get textObservable(): Observable<string> {
    return this.textSubject.asObservable();
  }

  setExpanded(expanded: boolean) {
    this.expanded.next(expanded);
  }

  setText(text: string) {
    this.textSubject.next(text);
  }
}
