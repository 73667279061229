import {chunk, drop, head} from 'lodash';
import {Element, Paper} from 'snapsvg-cjs';
import * as dUtils from 'svg-path-reverse';

export function polygonToPath(p: Element, parent: Paper): Element {

  const points = chunk(p.attr('points'), 2).map((arr: string[]) => ({
    x: arr[0],
    y: arr[1]
  }));

  const first = head(points);
  const other = drop(points);

  return parent.path(`M${first.x},${first.y} ${other.map(e => `L${e.x},${e.y}`)}Z`);
}

export function rectToPath(p: Element, parent: Paper): Element {
  const x = p.attr('x') || 0,
    y = p.attr('y') || 0,
    width = p.attr('width') || 1,
    height = p.attr('height') || 1;
  const points: any[] = [];
  points.push({x: width, y});
  points.push({x: width, y: height});
  points.push({x, y: height});
  return parent.path(`M${x},${y} ${points.map(e => `L${e.x},${e.y}`)}Z`);
}

export function circleToPath(p: Element, parent: Paper): Element {
  const r = p.attr('r');
  const cx = p.attr('cx');
  const cy = p.attr('cx');
  return parent.path(`M${cx},${cy} m-${r},0 a ${r},${r} 0 1,0 ${r * 2},0 a ${r},${r} 0 1,0 ${-(r * 2)},0`);
}

export function linesToPathString(d: string[]) {
  const first = head(d);
  const other = drop(d)
    .map(e => dUtils.normalize(e))
    .map(e => e.replace(/^M(\s+)(\d+.?(\d+)?)\s(\d+.?(\d+)?)/, ' '))
    .join('');
  return `${first} ${other}`;
}

export interface P {
  x: number;
  y: number;
}

export const distance = (a: P, b: P): number => {
  const a1 = a.x - b.x;
  const b1 = a.y - b.y;
  return Math.sqrt(a1 * a1 + b1 * b1);
};
