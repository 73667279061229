import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User} from "../../dataset/User";

@Injectable()
export class UsersService {

  constructor(private http: HttpClient) {
  }

  async getAll(): Promise<User[]> {
    return this.http.get<User[]>('/users').toPromise();
  }

  async getOne() {
  }

  async create(data: any) {
    return this.http.post<User>(`/users`, data).toPromise();
  }

  async update(id: string, data: any) {
    if (!data.password) {
      delete data.password;
    }
    return this.http.put<User>(`/users/${id}`, data).toPromise();
  }

  async remove(id: string) {
    return this.http.delete<User>(`/users/${id}`).toPromise();
  }

  async getMallUsers(mallId): Promise<{collaborators: User[], employees: User[]}> {
    return this.http.get<{collaborators: User[], employees: User[]}>(`/users/select-by-mall?mallId=${mallId}`).toPromise();
  }

  async makeOrganizer(activityId: string, userId: string) {
    return this.http.put<User>(`/assign-users/activity/assign-organizer?activityId=${activityId}&userId=${userId}`, {}).toPromise();
  }

  async makeModerator(activityId: string, userId: string) {
    return this.http.put<User>(`/assign-users/activity/assign-moderator?activityId=${activityId}&userId=${userId}`, {}).toPromise();
  }

  async makeManager(activityId: string, userId: string) {
    return this.http.put<User>(`/assign-users/activity/assign-manager?activityId=${activityId}&userId=${userId}`, {}).toPromise();
  }

  async deleteOrganizer(activityId: string, userId: string) {
    return this.http.delete(`/assign-users/activity/delete-organizer?activityId=${activityId}&userId=${userId}`, {}).toPromise();
  }

  async deleteModerator(activityId: string, userId: string) {
    return this.http.delete(`/assign-users/activity/delete-moderator?activityId=${activityId}&userId=${userId}`, {}).toPromise();
  }

  async deleteManager(activityId: string, userId: string) {
    return this.http.delete(`/assign-users/activity/delete-manager?activityId=${activityId}&userId=${userId}`, {}).toPromise();
  }

}
