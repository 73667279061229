import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Terminal} from "../../dataset/Terminal";
import {TerminalsService} from "../../providers/terminals/terminals.service";

@Injectable()
export class TerminalResolveService implements Resolve<Terminal> {

  constructor(private terminalsService: TerminalsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Terminal> {
    return await this.terminalsService.get(route.paramMap.get('id'));
  }

}
