import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from '../providers/user/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) {
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!localStorage.getItem('access_token')) {
      this.router.navigateByUrl('/auth/sign-in');
      return false;
    }
    try {
      await this.userService.me();
      return true;
    } catch (err) {
      this.router.navigateByUrl('/auth/sign-in');
      return false;
    }
  }
}
