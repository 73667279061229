import {Injectable} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent} from "../alert-dialog.component";
import {SafeHtml} from "@angular/platform-browser";

@Injectable()
export class DialogService {

  constructor(public dialog: MatDialog) {
  }

  alert(options: { title?: string, message: string | SafeHtml }): Promise<void> {
    const ref = this.dialog.open(AlertDialogComponent, {
      data: options,
      minWidth: '300px'
    });
    return ref.afterClosed().toPromise();
  }

  error(err: Error): Promise<void> {
    const ref = this.dialog.open(AlertDialogComponent, {
      data: {title: 'Error', message: err.message},
      minWidth: '300px'
    });
    return ref.afterClosed().toPromise();
  }

  confirm(options: { title?: string, message: string }): Promise<boolean> {
    const ref = this.dialog.open(AlertDialogComponent, {
      data: Object.assign({type: 'confirm'}, options),
      minWidth: '300px'
    });
    return ref.afterClosed().toPromise();
  }

}
