import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'facade'
})
export class FacadePipe implements PipeTransform {
  transform(facade: { [locale: string]: any } , lang: string, key?: string) {
    if (!facade) {
      return;
    }
    return facade[lang]
      ? facade[lang][key || 'name']
      : facade['en'] ? facade['en'][key || 'name'] : translate('error-facade', { lang });
  }
}
