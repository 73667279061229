const MAP_OBJECT_ID_PATTERN = /(.+)(\(((\w|-)+)?\|?(([-b]?\d,?)+)?\)(d?))$/i;

export enum CustomObjectTypes {
  OBJECT = 'object',
  TOILET = 'toilet',
  ATM = 'atm',
  NURSERYROOM = 'nurseryroom',
  KIOSK = 'kiosk',
  INACTIVE = 'inactive',
  AREA = 'area',
}

export class MapObjectId {

  id: string;
  type: string;
  floors: number[] = [];
  floor: number;
  accessible: boolean;

  constructor(input: string) {
    this.type = 'object';
    const result = MAP_OBJECT_ID_PATTERN.exec(input);
    if (!result) {
      this.id = input;
    } else {
      this.id = result[1];
      this.type = result[3];
      this.floors = result[5] ? result[5].split(',').map(e => parseInt(e.replace(/^b/g, '-'), 10)) : [];
      this.accessible = result[7] === 'd';
    }
  }

  toString(): string {
    return `${this.id}(${this.type}${this.floors.length ? '|' : ''}${this.floors.map(f => `${f}`.replace(/-/g, 'b')).join(',')})${this.accessible ? 'd' : ''}`;
  }

}
