import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {NewsService} from "../../providers/news/news.service";
import {NewsItem} from "../../dataset/NewsItem";

@Injectable({
  providedIn: 'root'
})
export class ActivityNewsResolveService implements Resolve<NewsItem[]> {

  constructor(private service: NewsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<NewsItem[]> {
    const mall: string = route.parent.data.mall._id;
    const activity: string | null = route.routeConfig.path === 'news' ? null : route.parent.paramMap.get('id');
    try {
      return await this.service.getAll(mall, activity);
    } catch (e) {
      return [];
    }
  }
}
