import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { Banner } from '../../../../dataset/Banner';
import { UploadService } from '../../../../providers/upload/upload.service';
import { Terminal } from '../../../../dataset/Terminal';
import { Mall } from "../../../../dataset/Mall";
import { TerminalsService } from "../../../../providers/terminals/terminals.service";
import { format, parseISO } from "date-fns";
import { BannersService } from "../../../../providers/banners/banners.service";
import { DatasetService } from "../../../../providers/dataset-service/dataset.service";
import { DataSetObject } from "../../../../dataset/DataSetObject";
import { find } from 'lodash';
import { map, startWith } from 'rxjs/operators';
import { Observable } from "rxjs";
import { DirtyFormModalService } from '../../../../providers/dirty-form-modal/dirty-form-modal.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isBefore } from 'date-fns';
import { BannerGroup } from '../../../../dataset/BannerGroup';
import {fileSizeValidator, FileSizeValidatorResults} from "../../../../../utils/file.utils";
import {DialogService} from "../../../../common/alert-dialog/services/dialog.service";
import {TranslocoService} from "@ngneat/transloco";

interface DataParams {
  banner?: Banner;
  mall: Mall;
  group?: BannerGroup;
}

@UntilDestroy()
@Component({
  selector: 'app-banners-edit-dialog',
  templateUrl: './banners-edit-dialog.component.html',
  styleUrls: ['./banners-edit-dialog.component.scss']
})
export class BannersEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;

  selectedDays: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  selectedTerminals: string[];
  isActive = true;

  loading = false;

  type: string;

  fileUrl: SafeUrl;
  file: File;

  extraFileUrl: SafeUrl;
  extraFile: File;

  terminals: Terminal[] = [];
  shops: DataSetObject[] = [];
  filteredShops: Observable<DataSetObject[]>;
  minDate = new Date();

  get isValid(): boolean {
    if (!this.form.valid) {
      return false;
    }

    if (this.data.banner) {
      return true;
    }

    if (!(this.file || this.extraFile)) {
      return false;
    }

    if (this.type === 'video') {
      return true;
    }
    return true;
  }

  get minEndDate(): Date {
    const from = this.form.get('availableFrom').value;
    const to = this.form.get('availableTo').value;
    if (isBefore(new Date(to), new Date(from))) {
      this.form.get('availableTo').setValue(from);
    }
    return from;
  }

  constructor(public dialogRef: MatDialogRef<BannersEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DataParams,
              private uploadService: UploadService,
              private fb: FormBuilder,
              private terminalsService: TerminalsService,
              private bannersService: BannersService,
              private datasetService: DatasetService,
              private dirtyFormService: DirtyFormModalService,
              private dialogs: DialogService,
              private translocoService: TranslocoService,
              private domSanitizer: DomSanitizer) {
    this.form = fb.group({
      name: ["Banner", Validators.required],
      description: [null],
      availableFrom: [new Date(), Validators.required],
      availableTo: [new Date(), Validators.required],
      duration: [null, Validators.required],
      index: [0, Validators.required],
      terminals: [null],
      shop: [null],
      startTime: null,
      endTime: null
    });
    this.dirtyFormService.registerForm(this.form, dialogRef);
  }

  async ngOnInit() {
    try {
      this.shops = await this.datasetService.getDataset(this.data.mall).getShops();
    } catch (e) {
    }
    try {
      this.terminals = await this.terminalsService.getAll({mall: this.data.mall._id});
      this.selectedTerminals = this.terminals.map(t => t._id);
      const banner: Banner = this.data.banner;
      if (banner) {
        const {availableFrom, availableTo} = banner;
        const startDateFull = parseISO(availableFrom);
        const endDateFull = parseISO(availableTo);
        const startTime = format(startDateFull, 'HH:mm');
        const endTime = format(endDateFull, 'HH:mm');
        const shop = find(this.shops, {_id: banner.shop});
        this.form.patchValue(Object.assign(banner, {
          shop,
          startTime,
          endTime,
        }));
        this.selectedTerminals = banner.terminals;
        this.selectedDays = banner.weekdays;
        this.isActive = banner.isActive;
        this.fileUrl = banner.file;
        this.extraFileUrl = banner.extraFile;
        this.type = banner.type;
      }
      this.filteredShops = this.form.controls['shop'].valueChanges
        .pipe(
          untilDestroyed(this),
          startWith<string | DataSetObject>(''),
          map(val => typeof val === 'string' ? val : val.name),
          map(val => val ? this.filterShops(val) : this.shops.slice())
        );
    } catch (err) {
      alert(err.message);
    }

  }

  onMetadata(ev, video) {
    const duration = video.duration;
    if (!this.form.value.duration) {
      this.form.patchValue({duration});
    }
  }

  async files($event: Event, extra = false) {
    this.dirtyFormService.forceDirtyForm();
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const file = target.files.item(0);
    if (fileSizeValidator(file) !== FileSizeValidatorResults.VALID) {
      const message = this.translocoService.translate(
        fileSizeValidator(file) === FileSizeValidatorResults.INVALID_SIZE ?
          'incorrect-file-size' :
          'incorrect-file-extension'
      );
      this.dialogs.alert(
        {
          title: `${this.translocoService.translate('error')} - ${file.name}`,
          message,
        });
      return;
    }
    target.value = "";
    const {url} = await this.uploadService.previewUrl(file);
    if (extra) {
      this.extraFileUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
      this.extraFile = file;
      if (!this.type) {
        this.type = file.type.startsWith('image') ? 'image' : 'video';
      }
    } else {
      this.fileUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
      this.file = file;
      if (!this.type) {
        this.type = file.type.startsWith('image') ? 'image' : 'video';
      }
    }
  }

  isDaySelected(day: string) {
    return this.selectedDays.includes(day);
  }

  selectDay(day: string) {
    this.dirtyFormService.forceDirtyForm();
    if (this.isDaySelected(day)) {
      const index = this.selectedDays.indexOf(day);
      this.selectedDays.splice(index, 1);
    } else {
      this.selectedDays.push(day);
    }
  }

  async confirm() {
    this.loading = true;
    try {
      const {availableFrom, availableTo, startTime, endTime} = this.form.value;
      const start = new Date(availableFrom);
      const end = new Date(availableTo);
      if (startTime) {
        const [startHours, startMinutes] = startTime.split(':');
        start.setHours(startHours);
        start.setMinutes(startMinutes);
      } else {
        start.setHours(0);
        start.setMinutes(0);
      }
      if (endTime) {
        const [endHours, endMinutes] = endTime.split(':');
        end.setHours(endHours);
        end.setMinutes(endMinutes);
      } else {
        end.setHours(23);
        end.setMinutes(59);
      }

      const banner: Banner & { startTime: string, endTime: string } = Object.assign(this.form.value, {
        weekdays: this.selectedDays,
        terminals: this.selectedTerminals,
        isActive: this.isActive,
        mall: this.data.mall._id,
        type: this.type,
        shop: this.form.value.shop ? this.form.value.shop._id : null,
        availableFrom: start,
        availableTo: end,
        file: this.file ? null : this.fileUrl,
        extraFile: this.extraFile ? null : this.extraFileUrl,
      });

      delete banner.startTime;
      delete banner.endTime;

      let res;
      if (this.data.banner) {
        res = await this.bannersService.update(this.data.banner._id, banner);
      } else {
        res = await this.bannersService.create(banner);
      }
      if (this.file) {
        res = await this.bannersService.upload(res._id, this.file, 'file');
      }
      if (this.extraFile) {
        res = await this.bannersService.upload(res._id, this.extraFile, 'extraFile');
      }
      this.dialogRef.close(res);

    } catch (err) {
      this.dialogs.alert(err);
    }
    this.loading = false;

  }

  displayFn(shop: DataSetObject): string | undefined {
    return shop ? shop.name : undefined;
  }

  filterShops(val: string): DataSetObject[] {
    return this.shops.filter(shop => shop.name.toLowerCase().includes(val.trim().toLowerCase()));
  }

  removeVerticalBanner() {
    this.fileUrl = null;
    this.file = null;
  }

  removeHorizontalBanner() {
    this.extraFileUrl = null;
    this.extraFile = null;
  }

  cancel() {
    this.dirtyFormService.closeSafely();
  }

  ngOnDestroy() {
  }

  selectAll() {
    this.selectedTerminals = this.terminals.map(terminal => terminal._id);
  }

  deselectAll() {
    this.selectedTerminals = [];
  }

}
