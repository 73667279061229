import {linesToPathString} from '../../../utils/shape.utils';
import {head, last, map} from 'lodash';
import {GraphLine} from './GraphLine';

export class GraphRouteSegment {

  path: string;
  from: string;
  to: string;

  constructor(lines: GraphLine[],
              public floor: number,
              public toFloor: string) {
    if (lines.length) {
      this.path = linesToPathString(map(lines, 'd'));
    }
    if (lines.length) {
      this.from = head(lines).from;
      this.to = last(lines).to;
    }
  }
}
