import {Injectable} from '@angular/core';
import * as dUtils from 'svg-path-reverse';
import {BBox} from 'snapsvg';
import * as Snap from 'snapsvg-cjs';
import {head, last, map, takeRight, uniq} from 'lodash';
import {circleToPath, distance, P, polygonToPath, rectToPath} from "../../../../utils/shape.utils";
import {CustomObjectTypes, MapObjectId} from "../../../dataset/map/MapObjectId";
import {Floor} from "../../../dataset/map/Floor";
import {GraphEdge} from "../../../dataset/map/GraphEdge";
import {Graph, idGen} from "../../../dataset/map/Graph";

const D = 'd';

type ProgressCallback = (operation: string, value: number) => void;

interface ParseOptions {
  graph_averaging_coefficient: number;
  pull_ratio: number;
}

interface IdPointsMap {
  [name: string]: string[];
}

interface PathContainer {
  path: Snap.Element;
  box: BBox;
  id?: MapObjectId;
  removeAfter: boolean;
}

const pause = (amount: number = 0): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, amount);
  });
};

type PathPointFn = (path: any[]) => { x: number, y: number };

const pathPoint = (fn): PathPointFn => {
  return (path: any[]): { x: number, y: number } => {
    const data = fn(path);
    const c: number[] = takeRight(data, 2);
    return {x: c[0], y: c[1]};
  };
};

const pathFirstPoint = pathPoint(head);
const pathLastPoint = pathPoint(last);
const allowedPortalTypes = ['elevator', 'travelator', 'escalator', 'stairs'];

@Injectable()
export class MapViewService {

  constructor() {
  }

  async parse(content: string, floorNumber: number, options: ParseOptions, progress?: ProgressCallback): Promise<{ floor: Floor, warnings: string[], error?: string }> {
    try {
      const warnings: string[] = [];

      const snap: Snap.Fragment = Snap.parse(content);

      const storesContainer = snap.select(`g#objects`);
      if (!storesContainer) throw('SVG doesn\'t contain IDs');

      const logoIds = [];
      const objectIDs = storesContainer.children().map(child => {
        // why try catch? because snap svg generates additional #text nodes which don't have a query selector
        try {
          const logos = child.selectAll('[id^="logo"]');
          if (logos.length > 0) {
            logos.forEach(logo => logoIds.push(logo.attr('id')));
          }
        } catch (e) {
        }
        return child.attr('id');
      });

      // we can't have duplicates of objects
      const set = new Set(objectIDs);
      let duplicates = [];
      if (objectIDs.length > 0 && set.size !== objectIDs.length) {
        duplicates = objectIDs.filter(item => {
          if (set.has(item)) {
            set.delete(item);
          } else {
            return item;
          }
        });
      }
      // TODO better regexp?
      const validRegexpForObjectIds = /^[A-Za-z0-9(,)|-]*$/;
      const validObjectTypes = [...Object.values(CustomObjectTypes), ...allowedPortalTypes].map(item => '(' + item);
      const wrongNames = [];
      objectIDs.forEach(id => {
        if (id) {
          if (!validRegexpForObjectIds.test(id)) {
            wrongNames.push(id);
          } else {
            let allowedType = false;
            for (const type of validObjectTypes) {
              if (id.includes(type)) {
                allowedType = true;
                break;
              }
            }
            if (!allowedType) {
              wrongNames.push(id);
            }
          }
        }
      });
      const validRegexpForLogoIds = /^[A-Za-z0-9(,)_|-]*$/;
      const wrongLogos = [];
      logoIds.forEach(id => {
        if (!validRegexpForLogoIds.test(id)) {
          wrongLogos.push(id);
        }
      });

      let errorMessage = '';
      if (duplicates.length > 0) {
        errorMessage += `<li>SVG has <b>duplicates of object IDs:</b> ${duplicates.join(', ')}.</li>`;
      }
      if (wrongLogos.length > 0) {
        errorMessage += `<li>The following <b>logotype ids have wrong names:</b> ${wrongLogos.join(', ')}.</li>`;
      }
      if (wrongNames.length > 0) {
        errorMessage += `<li>The following <b>ids have wrong names:</b> ${wrongNames.join(', ')}.
        Ids must have english characters or numbers followed by their type without spaces.
        Available types: ${validObjectTypes.map(item => item + ')').join(', ')}.</li>`;
      }
      if (errorMessage) {
        throw new Error(`<ul>${errorMessage}</ul>`);
      }
      const stores = storesContainer.children().filter(e => e.type === 'g');

      const pathsContainer = snap.select('g#waypath');
      const wayPaths = pathsContainer.children().filter(e => e.type === 'path');
      const wayPathsOffset = pathsContainer.getBBox();

      const idToPoints: IdPointsMap = {};
      const edges: GraphEdge[] = [];
      const nodes: string[] = [];

      const floor = new Floor(floorNumber);
      floor.graphTranslation = {x: wayPathsOffset.x, y: wayPathsOffset.y};

      if (progress) {
        progress("Convert shapes to paths", 0);
        await pause();
      }
      const paths: PathContainer[] = stores.map((parent: Snap.Element) => {
        const index = stores.indexOf(parent);
        const box = parent.getBBox();
        const shapeBindFilter = (e: Snap.Element) => !!e.attr('id') && (e.attr('id').toLowerCase().startsWith('shape') || e.attr('id').toLowerCase().startsWith('bind'));
        const bindFilter = (e: Snap.Element) => e.attr('id').toLowerCase().startsWith('bind');
        const shapes = parent.children().filter(shapeBindFilter);
        const bind = shapes.filter(bindFilter);
        const shape = bind.length ? bind[0] : shapes[0];
        const identifier = parent.attr('id');
        parent.attr('id', identifier);
        const mapObjectId = new MapObjectId(identifier);
        box.y += storesContainer.getBBox().y;
        box.x += storesContainer.getBBox().x;
        parent.addClass(mapObjectId.type);
        if (!shape) {
          console.log(`No shape for ${mapObjectId.id}`);
          warnings.push(`No shape for ${mapObjectId.id}`);
          return;
        }
        if (shape.type !== 'path') {
          warnings.push(`Shape of object ${identifier} is not a path. It will lead to errors in 3D map`);
        }
        let path: Snap.Element;
        let removeAfter = true;
        if (shape.type === 'path') {
          path = shape;
          removeAfter = false;
        } else if (shape.type === 'rect') {
          path = rectToPath(shape, parent);
        } else if (shape.type === 'polygon') {
          path = polygonToPath(shape, parent);
        } else if (shape.type === 'circle') {
          path = circleToPath(shape, parent);
        }
        if (progress) {
          progress("Convert shapes to paths", index / stores.length);
        }

        if (!path) {
          console.log(`No path for ${mapObjectId.id}`);
          warnings.push(`No path for ${mapObjectId.id}`);
          return undefined;
        }
        return {path, box, id: mapObjectId, removeAfter};
      }).filter(e => !!e);

      if (progress) {
        progress("Build path graph", 0);
      }
      await pause();

      const nodesRepository: P[] = [];

      for (const pathSegment of wayPaths) {
        const wayPathD = pathSegment.attr(D);
        const path: any[] = Snap.parsePathString(wayPathD);
        if (path) {
          const start = pathFirstPoint(path);
          const end = pathLastPoint(path);
          nodesRepository.push(start);
          nodesRepository.push(end);
        }
      }

      for (const node of nodesRepository) {
        for (const p of nodesRepository) {
          if (node === p) {
            continue;
          }
          if (distance(p, node) < options.graph_averaging_coefficient) {
            const index = nodesRepository.indexOf(node);
            if (index !== -1) {
              nodesRepository.splice(index, 1);
            }
          }
        }
      }

      const adjustIfNeeded = (point: P): P => {
        for (const p of nodesRepository) {
          if (distance(p, point) < options.pull_ratio) {
            return p;
          }
        }
        return point;
      };

      // Loop every path segments
      const total = wayPaths.length;
      for (const pathSegment of wayPaths) {

        const pathSegmentIndex = wayPaths.indexOf(pathSegment);

        const wayPathD = dUtils.normalize(pathSegment.attr(D));

        if (!wayPathD) {
          continue;
        }

        const path: any[] = Snap.parsePathString(wayPathD);
        const start = adjustIfNeeded(pathFirstPoint(path));
        const end = adjustIfNeeded(pathLastPoint(path));


        const fromId = idGen(start.x, start.y, floorNumber);
        const toId = idGen(end.x, end.y, floorNumber);

        nodes.push(fromId);
        nodes.push(toId);
        edges.push(new GraphEdge(fromId, toId, pathSegment.getTotalLength(), wayPathD, floorNumber, floorNumber));

        // attach shops to coordinates
        for (const el of paths) {

          // Adjust values
          const x1 = start.x + wayPathsOffset.x - el.box.x;
          const y1 = start.y + wayPathsOffset.y - el.box.y;
          const x2 = end.x + wayPathsOffset.x - el.box.x;
          const y2 = end.y + wayPathsOffset.y - el.box.y;

          // Prepare path and container
          const pathD = dUtils.normalize(el.path.attr(D));
          if (!idToPoints[el.id.id]) {
            idToPoints[el.id.id] = [];
          }
          // Object contains start point
          if (Snap.path.isPointInside(pathD, x1, y1)) {
            idToPoints[el.id.id].push(fromId);
          }
          // Object contains end point
          if (Snap.path.isPointInside(pathD, x2, y2)) {
            idToPoints[el.id.id].push(toId);
          }
        }
        if (progress) {
          progress(`Segment ${pathSegmentIndex} of ${total}`, pathSegmentIndex / total);
          await pause();
        }

      }

      floor.graph = new Graph(uniq(nodes), edges);
      floor.idToPoints = idToPoints;

      const unlinked = map(floor.idToPoints, (ids: string[], key) => !ids.length ? key : undefined).filter(e => !!e);
      if (unlinked.length > 0) {
        warnings.push(`The ${floorNumber} floor has unlinked objects: ${unlinked.join(', ')}`);
        console.log(`The ${floorNumber} floor has unlinked objects: ${unlinked.join(', ')}`);
      }

      // check if portal leads to exact same floor
      const recursivePortals = [];
      floor.portals = paths
        .filter(e => allowedPortalTypes.includes(e.id.type))
        .map(e => {
          if (e.id.floors.includes(floorNumber)) {
            recursivePortals.push(e.id.id);
          }
          return {
            fromFloor: floorNumber,
            toFloors: e.id.floors,
            accessible: e.id.accessible,
            id: e.id.id,
          };
        });
      if (recursivePortals.length > 0) {
        warnings.push(`The following portals lead to exact same floor: ${recursivePortals.join(', ')}. Portals should lead only to different floors.`);
      }

      floor.snap = snap.select('svg > g');

      paths.filter(pc => pc.removeAfter).forEach(pc => pc.path.remove());

      return {floor, warnings};
    } catch (e) {
      throw e;
    }
  }

}
