import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Account} from "../../dataset/Account";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private http: HttpClient) {
  }

  async getAll(): Promise<Account[]> {
    return this.http.get<Account[]>('/accounts').toPromise();
  }

  rxGetAll(query: string, activity?: string): Observable<Account[]> {
    const options = {params: {query, activity}};
    if (!activity) {
      delete options.params.activity;
    }
    return this.http.get<Account[]>('/accounts', options);
  }

  async get(id: string): Promise<Account> {
    return this.http.get<Account>(`/account/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Account>(`/account`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<Account>(`/account/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<Account>(`/account/${id}`).toPromise();
  }
}
