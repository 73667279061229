import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export enum AttachOrRemoveResult {
  ATTACH,
  DETACH,
}

@Component({
  selector: 'app-attach-or-remove-modal',
  templateUrl: './attach-or-remove-modal.component.html',
  styleUrls: ['./attach-or-remove-modal.component.scss']
})
export class AttachOrRemoveModalComponent implements OnInit {

  resultTypes = AttachOrRemoveResult;

  constructor(public dialogRef: MatDialogRef<AttachOrRemoveModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { message: string }) {
  }

  ngOnInit(): void {
  }

  close(result?: AttachOrRemoveResult) {
    this.dialogRef.close(result);
  }

}
