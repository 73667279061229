import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomSection, ActivitySection } from '../../dataset/ActivitySection';

@Injectable({
  providedIn: 'root'
})
export class ActivitySectionsService {

  constructor(private http: HttpClient) {

  }

  async getAll(activity: string): Promise<ActivitySection[]> {
    return this.http.get<ActivitySection[]>(`/activity-sections/${activity}`).toPromise();
  }

  async create(activity: string, data: any) {
    return this.http.post<CustomSection>(`/activity-sections/${activity}`, data).toPromise();
  }

  async update(activity: string, section: string, data: any) {
    return this.http.put<ActivitySection>(`/activity-sections/${activity}/${section}`, data).toPromise();
  }

  async delete(activity: string, section: string) {
    return this.http.delete<CustomSection>(`/activity-sections/${activity}/${section}`).toPromise();
  }

  async sort(activity: string, sections: {id: string, index: number}[]) {
    return this.http.post(`/activity-sections-sort/${activity}`, {
      sections
    }).toPromise();
  }
}
