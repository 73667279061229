import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { User } from "../dataset/User";
import { UserService } from "../providers/user/user.service";
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { FuseNavigation } from '@fuse/types';
import { NavigationService } from '../providers/navigation/navigation.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { ChatService } from '../providers/chat/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit, OnDestroy {

  user: User;
  navigation: any;
  fuseConfig: any;
  private navigationSubject: BehaviorSubject<FuseNavigation[]>;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private _fuseSidebarService: FuseSidebarService,
              private navigationService: NavigationService,
              private _fuseConfigService: FuseConfigService,
              private chatService: ChatService) {
    this.userService.subscribe(user => this.user = user);
    this.navigationSubject = this.navigationService.getSubject();
    this.navigationSubject.subscribe(nav => this.navigation = nav);
  }

  ngOnInit() {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });
  }

  ngOnDestroy(): void {
    this.navigationSubject.complete();
    // TODO disconnect chat
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

}
