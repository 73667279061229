import {Injectable} from '@angular/core';
import {Mall} from "../../dataset/Mall";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {DataSetObject} from "../../dataset/DataSetObject";

// TODO remove it
class DataSet {

  constructor(private http: HttpClient, private baseUrl: string) {
  }

  async getShops() {
    return await this.http.get<DataSetObject[]>(`${this.baseUrl}/shops`).toPromise();
  }

  async getCategories() {
    return await this.http.get<DataSetObject[]>(`${this.baseUrl}/categories`).toPromise();
  }

}

@Injectable()
export class DatasetService {

  constructor(private http: HttpClient) {
  }

  getDataset(mall: Mall) {
    return new DataSet(this.http, `${environment.api_server}/mall/${mall._id}/dataset`);
  }

}
