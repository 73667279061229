import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Banner } from '../../dataset/Banner';
import { BannerGroup } from '../../dataset/BannerGroup';
import {UploadService} from "../upload/upload.service";

@Injectable()
export class BannersService {

  constructor(private http: HttpClient,
              private uploadService: UploadService) {
  }

  async getAll(mall: string): Promise<Banner[]> {
    return this.http.get<Banner[]>('/banner', {
      params: new HttpParams().set('mall', mall)
    }).toPromise();
  }

  async get(id: string): Promise<Banner[]> {
    return this.http.get<Banner[]>(`/banner/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Banner>(`/banner`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<Banner>(`/banner/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<Banner>(`/banner/${id}`).toPromise();
  }

  async getGroups(mall: string): Promise<BannerGroup[]> {
    return this.http.get<BannerGroup[]>('banner/groups', {
      params: {
        mall,
      }
    }).toPromise();
  }

  async createGroup(data: any): Promise<BannerGroup> {
    return this.http.post<BannerGroup>('banner/groups', data).toPromise();
  }

  async updateGroup(groupId: string, data: any): Promise<BannerGroup> {
    return this.http.put<BannerGroup>(`banner/groups/${groupId}`, data).toPromise();
  }

  async deleteGroup(id: string) {
    return this.http.delete(`/banner/groups/${id}`).toPromise();
  }

  async upload(bannerId: string, file: File, type: 'file' | 'extraFile'): Promise<Banner> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.put<Banner>(`/banner/${bannerId}/${type}`, fileData).toPromise();
  }

}
