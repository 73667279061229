import {CategoryFacade} from "./CategoryFacade";

export enum CategoryActionNames {
  PLAN = 'plan',
  SEARCH = 'search',
  MARKETING = 'marketing',
  ACTIVITIES = 'activities',
  CINEMA = 'cinema',
  SHOP = 'shop',
  OBJECT_OF_TYPE = 'objectOfType',
}

interface CategoryAction {
  name: CategoryActionNames;
  payload: any;
}

export interface Category {
  icon: string;
  _id: string;
  __v: number;
  facade: {
    [locale: string]: CategoryFacade
  };
  parentName: string;
  showOnMain: boolean;
  color: string;
  cover: string;
  type: string;
  mall: string;
  parent: string;
  action?: CategoryAction;
  externalId: string;
}
