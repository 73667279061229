import {GraphEdge} from './GraphEdge';
import {chain, keyBy, range, reduce} from 'lodash';
import {GraphRoute} from './GraphRoute';
import {GraphLine} from './GraphLine';

export const idGen = (x, y, f) => {
  return `${f}-${x}x${y}`;
};

interface GraphNodeImpl {
  id: string;
  processed: boolean;
  edge: string;
  weight: number;
}

interface GraphEdgeImpl {
  id: string;
  from: GraphNodeImpl;
  to: GraphNodeImpl;
  distance: number;
  bidirectional: boolean;
}

interface GraphEdgeMap {
  [id: string]: GraphEdgeImpl;
}

interface GraphNodeMap {
  [id: string]: GraphNodeImpl;
}

interface GraphMatrixNodeValue {
  edge: string;
  distance: number;
}

interface GraphMatrixNode {
  [to: string]: GraphMatrixNodeValue;
}

interface GraphMatrix {
  [from: string]: GraphMatrixNode;
}

export class Graph {

  constructor(public nodes: string[] = [], public edges: GraphEdge[] = []) {
  }

  route(fromPoint: string, toPoint: string): GraphRoute {

    //  transform points and edges arrays to the dict
    const pointsDict: GraphNodeMap = chain(this.nodes)
      .map(p => ({
        id: p,
        processed: false,
        weight: Infinity - 1,
        edge: null
      }))
      .keyBy('id')
      .value();
    const edgesDict: GraphEdgeMap = chain(this.edges)
      .map((e: GraphEdge) => ({
        id: e.id,
        from: pointsDict[e.from],
        to: pointsDict[e.to],
        distance: e.distance,
        bidirectional: e.bidirectional
      }))
      .keyBy('id')
      .value();

    if (!pointsDict[fromPoint]) {
      return null;
    }

    // start point
    pointsDict[fromPoint].weight = 0;

    // init edges matrix for the point
    const distMatrix: GraphMatrix = {};
    Object.keys(edgesDict).forEach(edge => {
      const _edge = edgesDict[edge],
        from = _edge.from.id,
        to = _edge.to.id,
        distance = _edge.distance;

      reduce([to, from], (accum, id) => {
        distMatrix[id] = distMatrix[id] || {};
        return accum;
      }, distMatrix);

      distMatrix[from][to] = {edge, distance};
      if (_edge.bidirectional) {
        distMatrix[to][from] = {edge, distance};
      }
    });

    // start Dijkstra’s algorithm
    range(0, Object.keys(pointsDict).length).forEach(() => {
      let minWeight = Infinity;
      let minWeightId = null;
      for (const start of Object.keys(pointsDict)) {
        const start_point = pointsDict[start];
        if (!start_point.processed && start_point.weight < minWeight) {
          minWeight = start_point.weight;
          minWeightId = start;
        }
      }
      if (!minWeightId) {
        return;
      }
      const minWeightPoint = pointsDict[minWeightId];
      for (const finish of Object.keys(distMatrix[minWeightId])) {
        const currentPoint = pointsDict[finish];
        const edge: GraphMatrixNodeValue = distMatrix[minWeightId][finish];
        if (minWeightPoint.weight + edge.distance < currentPoint.weight) {
          currentPoint.weight = minWeightPoint.weight + edge.distance;
          currentPoint.edge = edge.edge;
        }
      }
      minWeightPoint.processed = true;
    });
    // build path
    let point = pointsDict[toPoint];
    let activeEdge = edgesDict[point.edge];
    const path = [];
    while (activeEdge) {
      path.unshift(activeEdge);
      point = point.id === activeEdge.from.id ? activeEdge.to : activeEdge.from;
      activeEdge = edgesDict[point.edge];
    }
    const lines: { [id: string]: GraphEdge } = keyBy(this.edges, 'id');
    let i = fromPoint;
    return new GraphRoute(path
      .map(e => {
        const edge = lines[e.id];
        return new GraphLine(edge.from, edge.to, edge.d, edge.distance, edge.fromFloor, edge.toFloor);
      })
      .map(item => {
        i = item.reverseIfNeed(i);
        return item;
      }));
  }

}
