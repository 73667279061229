export const environment = {
    production: false,
    version: "1.2.3",
    sentry: undefined,
    api_server: "https://api.isktangoserv.wtcmoscow.ru",
    chat_server: "https://devchat.tangodev.ru",
    chat_url: "devchat.tango.vision",
    chat_api_token: "AQEAAAABAAD_rAp4DJh05a1HAwFT3A6K",
    survey_results_page: "https://skevents.tangodev.ru/app",
    isWSS: true,
    web_terminal_url: 'https://front.isktangoserv.wtcmoscow.ru',
    new_web_terminal_url: "https://front.isktangoserv.wtcmoscow.ru",
    next_web_terminal_url: "https://front.isktangoserv.wtcmoscow.ru",
    qr_url: 'https://qr.isktangoserv.wtcmoscow.ru'
};
