import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Mall} from '../../../dataset/Mall';
import {Shop} from '../../../dataset/Shop';
import {DialogService} from '../../../common/alert-dialog/services/dialog.service';
import {ShopsService} from '../../../providers/shops/shops.service';
import {ShopsEditModalComponent} from './shops-edit-modal/shops-edit-modal.component';
import { debounceTime } from "rxjs/operators";
import {Category} from '../../../dataset/Category';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SearchService } from '../../../providers/search/search.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';
import { filterFacade } from '../../../../utils/facade';
import { MatSort } from '@angular/material/sort';
import {FacadePipe} from "../../../common/pipes/facade.pipe";
import {ShopDeactivateComponent} from "./shop-deactivate/shop-deactivate.component";
import {copyToBuffer} from "../../../../utils/utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Brands} from "../../../dataset/Brands";

@UntilDestroy()
@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss']
})
export class ShopsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  mall: Mall;
  brands: Brands[];
  categoriesMap: Map<string, string> = new Map();
  displayedColumns = ['name', 'categories', 'index', 'isActive', 'updatedAt', 'menu'];
  dataSource = new MatTableDataSource<Shop>();
  locale = 'en';
  hasProxy = false;

  constructor(private dialogs: DialogService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private shopsService: ShopsService,
              private searchService: SearchService,
              private facadePipe: FacadePipe,
              private snackbar: MatSnackBar,
              private translationService: TranslocoService) {
    this.route.data.subscribe(async (data: { mall: Mall, shops: Shop[], categories: Category[], brands: Brands[] }) => {
      this.mall = data.mall;
      this.brands = data.brands;
      this.dataSource.data = data.shops;
      if (data.mall.settings.default_locale) {
        this.locale = data.mall.settings.default_locale;
      }
      data.categories.forEach(category => {
        const name = category.facade[this.locale] ? category.facade[this.locale].name : 'Undefined';
        this.categoriesMap.set(category._id, name);
        if (category.externalId) {
          this.categoriesMap.set(category.externalId, name);
        }
      });
    });
    this.searchService.textObservable
      .pipe(untilDestroyed(this), debounceTime(200))
      .subscribe(value => this.dataSource.filter = value);
    this.translationService.langChanges$
      .pipe(untilDestroyed(this))
      .subscribe(val => this.locale = val);
    this.dataSource.filterPredicate = (data: Shop, filter: string) => filterFacade<Shop>(data, this.locale, filter);
    this.dataSource.sortingDataAccessor = (item: Shop, property) => {
      switch (property) {
        case 'name':
          return item.facade[this.locale]?.name;
        default:
          return item[property];
      }
    };
    this.route.queryParamMap.subscribe(params => {
      if (params.has('create')) {
        this.edit();
        return;
      }
      if (params.has('edit') || params.has('deactivate')) {
        const shop = this.dataSource.data
          .find(item => item._id === params.get('edit') || item._id === params.get('deactivate'));
        if (!shop) {
          this.dialogs.alert({
            title: 'Error',
            message: 'The shop has not found',
          });
          return;
        }
        if (params.has('edit')) this.edit(shop);
        if (params.has('deactivate')) this.deactivate(shop);
      }
    });
  }

  async ngOnInit() {
    if (this.mall.dataSetProxy?.url && this.mall.dataSetProxy?.shops) {
      this.hasProxy = true;
      this.dialogs.alert({
        title: this.translationService.translate('warning'),
        message: this.translationService.translate('proxyInfo'),
      });
    }
  }

  ngOnDestroy() {
    this.searchService.setExpanded(false);
  }

  async edit(shop?: Shop) {
    try {
      const mall = this.mall;
      const brands = this.brands;
      const ref = this.dialog.open(ShopsEditModalComponent, {
        data: {shop, mall, brands, hasProxy: this.hasProxy},
        minWidth: '300px'
      });
      const result: Shop = await ref.afterClosed().toPromise();
      this.router.navigate([], { queryParams: null });
      if (result) {
        if (shop) {
          Object.assign(shop, result);
        } else {
          const data = this.dataSource.data;
          data.push(result);
          this.dataSource.data = data;
        }
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

  async remove(shop: Shop) {
    try {
      if (await this.dialogs.confirm({
        title: this.translationService.translate('are-you-sure'),
        message: this.translationService.translate('remove', {item: this.facadePipe.transform(shop.facade, this.locale)})
      })) {
        await this.shopsService.delete(shop._id);
        const data = this.dataSource.data;
        const index = data.indexOf(shop);
        data.splice(index, 1);
        this.dataSource.data = data;
      }
    } catch (err) {
      this.dialogs.error(err);
    }
  }

  async deactivate(shop: Shop) {
    try {
      const res = await this.dialog.open(ShopDeactivateComponent, {
        minWidth: '500px',
        data: {
          shop,
        }
      }).afterClosed().toPromise();
      if (res) {
        Object.assign(shop, res);
      }
      this.router.navigate([], { queryParams: null });
    } catch (e) {
      this.dialogs.error(e);
    }
  }

  getCategoryNames(ids: string[]): string {
    const res = ids.map(id => this.categoriesMap.get(id) || id);
    return res.join(', ');
  }

  copyId(shop: Shop) {
    copyToBuffer(shop._id);
    this.snackbar.open(this.translationService.translate('value-copied'), this.translationService.translate('close'), {
      duration: 2000,
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
}
