import {ShopFacade} from "./ShopFacade";
import { Facade } from './Facade';
import { AccountInActivity } from './Account';
import {Schedule} from "./Schedule";

export enum ShopTypes {
  Exponent = 'Exponent',
  Hall = 'Hall',
}

export interface Shop {
  showcase: string;
  _id: string;
  __v: number;
  facade: Facade<ShopFacade>;
  color: string;
  logo: string;
  backgroundImage: string;
  scheduleNew: Schedule;
  phone: string;
  paymentType: string[];
  renterPhone: string;
  renterName: string;
  categories: string[];
  searchTags: string;
  mall: string;
  agents: AccountInActivity[];
  type: ShopTypes;
  index: number;
  gallery: string[];
  inActiveDate?: string;
  isActive: boolean;
  hideInSearch: boolean;
}
