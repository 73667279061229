import { Injectable } from '@angular/core';
import { ManageAccountsService } from '../../providers/manage-accounts/manage-accounts.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Mall } from '../../dataset/Mall';
import { ActivityOrganizer } from '../../dataset/ActivityOrganizer';

@Injectable({
  providedIn: 'root'
})
export class MallAdminsResolverService implements Resolve<ActivityOrganizer[]> {

  constructor(private service: ManageAccountsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ActivityOrganizer[]> {
    const mall: Mall = route.parent.data.mall;
    return (await this.service.getAdmins(mall._id)).map(admin => ({
      ...admin,
      isAdmin: true,
    }));
  }
}
