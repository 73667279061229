import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export enum AttachTerminalOrQrResult {
  TERMINAL,
  QR,
}

@Component({
  selector: 'app-attach-terminal-or-qr',
  templateUrl: './attach-terminal-or-qr.component.html',
  styleUrls: ['./attach-terminal-or-qr.component.scss']
})
export class AttachTerminalOrQrComponent implements OnInit {

  resultTypes = AttachTerminalOrQrResult;

  constructor(public dialogRef: MatDialogRef<AttachTerminalOrQrComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { message: string }) {
  }

  ngOnInit(): void {
  }

  close(result: AttachTerminalOrQrResult | null) {
    this.dialogRef.close(result);
  }

}
