import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Shop} from '../../dataset/Shop';
import {UploadService} from "../upload/upload.service";

@Injectable()
export class ShopsService {

  constructor(private http: HttpClient,
              private uploadService: UploadService) {
  }

  async getAll(mall: string): Promise<Shop[]> {
    return this.http.get<Shop[]>('/shop', {
      params: {
        mall,
        type: 'admin'
      }
    }).toPromise();
  }

  async get(id: string): Promise<Shop> {
    return this.http.get<Shop>(`/shop/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Shop>(`/shop`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<Shop>(`/shop/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<Shop>(`/shop/${id}`).toPromise();
  }

  async addAgent(shopId: string, userId: string): Promise<Shop> {
    return this.http.put<Shop>(`/assign/add-agent`, {}, {
      params: {
        userId,
        shopId,
      }
    }).toPromise();
  }

  async removeAgent(shopId: string, userId: string): Promise<Shop> {
    return this.http.delete<Shop>(`/assign/remove-agent?shopId=${shopId}&userId=${userId}`).toPromise();
  }

  async deactivate(shopId: string, data: {mall: string, date: string}): Promise<Shop> {
    return this.http.put<Shop>(`/shop/${shopId}/in-active`, data).toPromise();
  }

  async cancelDeactivation(shopId: string): Promise<Shop> {
    return this.http.delete<Shop>(`/shop/${shopId}/in-active`).toPromise();
  }

  async upload(shopId: string, file: File, type: 'logo' | 'backgroundimage'): Promise<Shop> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.post<Shop>(`/shop/${shopId}/${type}`, fileData).toPromise();
  }


}
