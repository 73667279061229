import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DirtyFormModalService } from '../../../../providers/dirty-form-modal/dirty-form-modal.service';
import {TerminalGroups, TerminalGroupsFacade} from '../../../../dataset/TerminalGroups';
import {Mall} from "../../../../dataset/Mall";
import {TranslocoService} from "@ngneat/transloco";
import {BannerGroup} from "../../../../dataset/BannerGroup";
import {Terminal} from "../../../../dataset/Terminal";
import {map} from "lodash";

@Component({
  selector: 'app-terminals-edit-modal',
  templateUrl: './terminals-group-edit-modal.component.html',
  styleUrls: ['./terminals-group-edit-modal.component.scss']
})
export class TerminalsGroupEditModalComponent implements OnInit {

  form: FormGroup;
  locale = 'ru';
  maxShortDescLength = 200;
  locales: string[] = ['ru', 'en'];

  constructor(public dialogRef: MatDialogRef<TerminalsGroupEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { mall: Mall, terminalGroup: TerminalGroups, bannerGroups: BannerGroup[], terminals: Terminal[] },
              private fb: FormBuilder,
              private translocoService: TranslocoService,
              private dirtyFormService: DirtyFormModalService) {
    this.form = fb.group({
      facade: this.fb.array([this.fb.group({
        __locale: this.locale,
        name: [null, Validators.required],
        description: null,
      })]),
      orientation: [null, Validators.required],
      bannerGroups: [],
      terminals: [],
      wrapper: this.fb.group({
        url: null,
        source: [null, Validators.required],
        updateInterval: [null, Validators.required],
        screenshots: null,
        pingInterval: [null, Validators.required],
        pingTimeout: [null, Validators.required],
        width: null,
        height: null,
      }),
    });
    this.dirtyFormService.registerForm(this.form, dialogRef);
  }

  ngOnInit() {
    this.locale = this.translocoService.getActiveLang() || this.data.mall.settings?.default_locale;
    const {terminalGroup} = this.data;
    if (terminalGroup) {
      const data = Object.assign({}, terminalGroup);
      delete data.facade;
      this.form.patchValue(data);
      const facades = this.fb.array(map(terminalGroup.facade, (facade, __locale) => this.fb.group({__locale, ...facade})));
      this.form.setControl('facade', facades);
    }
  }

  get facades(): FormArray {
    return this.form.get('facade') as FormArray;
  }

  localeName(__locale: string) {
    return __locale;
  }

  localsCount(): number {
    return this.locales.length;
  }

  addFacade(__locale: string) {
    this.facades.push(this.fb.group({
      __locale,
      name: [null, Validators.required],
      description: [null, Validators.required],
    }));
  }

  hasFacade(__locale: string): boolean {
    return this.facades.controls.filter(c => c.value.__locale === __locale).length !== 0;
  }

  removeFacade(index: number) {
    this.facades.removeAt(index);
  }

  confirm() {
    const facade: { [locale: string]: TerminalGroupsFacade } = this.facades.controls.reduce((last, control) => {
      const {__locale} = control.value;
      delete control.value.__locale;
      last[__locale] = control.value;
      return last;
    }, {});
    const bannerGroups = this.form.get('bannerGroups').value?.length ? this.form.get('bannerGroups').value : [];
    const terminals = this.form.get('terminals').value?.length ? this.form.get('terminals').value : [];
    this.dialogRef.close({ ...this.form.value, facade, mall: this.data.mall._id, bannerGroups, terminals });
  }

  cancel() {
    this.dirtyFormService.closeSafely();
  }

}
