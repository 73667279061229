import { NgModule } from '@angular/core';
import { CommonModule } from '../../common/common.module';
import { OrderIsSavedComponent } from './banners/banners.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CatalogEditModalComponent } from './catalog/catalog-edit-modal/catalog-edit-modal.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { TerminalsComponent } from './terminals/terminals.component';
import { MapEditorComponent } from './map-editor/map-editor.component';
import { EditFileDialogComponent } from './activities/activity/activity-sections/activity-schedule/speaker-sessions/speaker-session-files/edit-file-dialog/edit-file-dialog.component';
import { AttachTerminalModalComponent } from './map-editor/attach-terminal-modal/attach-terminal-modal.component';
import { ImportMapModalComponent } from './map-editor/import-map-modal/import-map-modal.component';
import { MallComponent } from './mall.component';
import { AttachShopModalComponent } from './map-editor/attach-shop-modal/attach-shop-modal.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ShopsModule } from './shops/shops.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {TerminalsModule} from "./terminals/terminals.module";
import {TerminalGroupsModule} from "./terminal-groups/terminal-groups.module";
import { TerminalGroupsComponent } from './terminal-groups/terminal-groups.component';
import { AttachOrRemoveModalComponent } from './map-editor/attach-or-remove-modal/attach-or-remove-modal.component';
import { ThreedSettingsModalComponent } from './map-editor/threed-settings-modal/threed-settings-modal.component';
import { QrCodesComponent } from './map-editor/qr-codes/qr-codes.component';
import { EditQrCodeComponent } from './map-editor/qr-codes/edit-qr-code/edit-qr-code.component';
import { AttachTerminalOrQrComponent } from './map-editor/attach-terminal-or-qr/attach-terminal-or-qr.component';
import { AttachQrModalComponent } from './map-editor/attach-qr-modal/attach-qr-modal.component';

@NgModule({
  declarations: [
    OrderIsSavedComponent,
    CatalogComponent,
    CatalogEditModalComponent,
    TerminalsComponent,
    MallComponent,
    MapEditorComponent,
    ImportMapModalComponent,
    AttachTerminalModalComponent,
    AttachShopModalComponent,
    EditFileDialogComponent,
    TerminalGroupsComponent,
    AttachOrRemoveModalComponent,
    ThreedSettingsModalComponent,
    QrCodesComponent,
    EditQrCodeComponent,
    AttachTerminalOrQrComponent,
    AttachQrModalComponent,
  ],
  imports: [
    CommonModule,
    ShopsModule,
    TerminalsModule,
    TerminalGroupsModule,
    SortablejsModule.forRoot({animation: 150}),
    ColorPickerModule,
    NgxChartsModule,
  ],
})
export class MallModule { }
