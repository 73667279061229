import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Country } from '../../dataset/Country';
import { CountriesService } from '../../providers/countries/countries.service';

@Injectable({
  providedIn: 'root'
})
export class CountriesResolveService implements Resolve<Country[]> {

  constructor(private countriesService: CountriesService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Country[]> {
    return await this.countriesService.getAll();
  }
}
