import { NgModule } from '@angular/core';
import {ActivateTerminalComponent} from "./activate-terminal/activate-terminal.component";
import {ScreenshotsComponent} from "./screenshots/screenshots.component";
import {TerminalsEditModalComponent} from "./terminals-edit-modal/terminals-edit-modal.component";
import {CommonModule} from "../../../common/common.module";
import { TerminalCommandsModalComponent } from './terminal-commands-modal/terminal-commands-modal.component';

@NgModule({
  declarations: [
    ActivateTerminalComponent,
    ScreenshotsComponent,
    TerminalsEditModalComponent,
    TerminalCommandsModalComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class TerminalsModule { }
