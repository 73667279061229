import {Injectable} from '@angular/core';
import {NewsItem} from "../../dataset/NewsItem";
import {HttpClient} from "@angular/common/http";
import {UploadService} from "../upload/upload.service";

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(private http: HttpClient,
              private uploadService: UploadService) {
  }

  async delete(_id: string) {
    return this.http.delete<NewsItem>(`/news/${_id}`).toPromise();
  }

  async update(_id: string, data: any) {
    return this.http.put<NewsItem>(`/news/${_id}`, data).toPromise();
  }

  async create(data: any) {
    return this.http.post<NewsItem>(`/news`, data).toPromise();
  }

  async getAll(mall: string, activity: string): Promise<NewsItem[]> {
    let url = `/news?mall=${mall}`;
    if (activity) {
      url += `&activity=${activity}`;
    }
    return this.http.get<NewsItem[]>(url).toPromise();
  }

  async upload(newsId: string, file: File): Promise<NewsItem> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.post<NewsItem>(`/news/${newsId}/cover`, fileData).toPromise();
  }
}
