import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivityContactPerson } from '../../dataset/ActivityContactPerson';

@Injectable({
  providedIn: 'root'
})
export class ActivityContactsService {

  constructor(private http: HttpClient) {
  }

  async getAll(activity: string): Promise<ActivityContactPerson[]> {
    return this.http.get<ActivityContactPerson[]>(`/activity-contact-person/${activity}`).toPromise();
  }

  async create(activity: string, data: any) {
    return this.http.post<ActivityContactPerson>(`/activity-contact-person/${activity}`, data).toPromise();
  }

  async update(activity: string, personId: string, data: any) {
    return this.http.put<ActivityContactPerson>(`/activity-contact-person/${activity}/${personId}`, data).toPromise();
  }

  async delete(activity: string, personId: string) {
    return this.http.delete(`/activity-contact-person/${activity}/${personId}`).toPromise();
  }

  async upload(activity: string, file: File): Promise<{ url: string }> {
    const fileData = new FormData();
    fileData.append('Content-Type', file.type);
    fileData.append('file', file);
    return this.http.post<{ url: string }>(`/activity-contact-person/${activity}/avatar`, fileData).toPromise();
  }
}
