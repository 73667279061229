import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Push } from '../../dataset/Push';
import { ActivityPushesService } from '../../providers/activity-pushes/activity-pushes.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityPushesResolveService implements Resolve<Push[]> {

  constructor(private service: ActivityPushesService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Push[]> {
    return await this.service.getPushNotifications(route.parent.paramMap.get('id'));
  }

}
