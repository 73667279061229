import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../providers/user/user.service";
import {DialogService} from "../../common/alert-dialog/services/dialog.service";
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations   : fuseAnimations
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;

  constructor(private router: Router,
              private fb: FormBuilder,
              private userService: UserService,
              private dialog: DialogService) {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
  }

  async send() {
    this.form.disable();
    try {
      const {email} = this.form.value;
      await this.userService.forgot(email);
      await this.dialog.alert({message: 'Инструкции отправлены на указанный email'});
      this.router.navigateByUrl('/auth/sign-in');
    } catch (err) {
      this.dialog.error(err);
    }
    this.form.enable();
  }

}
