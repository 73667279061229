import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Mall} from "../../dataset/Mall";
import {MallsService} from "../../providers/malls/malls.service";

@Injectable({
  providedIn: 'root'
})
export class MapResolver implements Resolve<{ floors: any[], mapConfig: any }> {

  constructor(private mallService: MallsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<{ floors: any[], mapConfig: any }> {
    const mall: Mall = route.parent.data.mall;
    const {floors, mapConfig} = await this.mallService.getMap(mall._id);
    return {
      floors: floors.map(floor => ({
        ...floor,
        snap: decodeURIComponent(floor.snap)
      })),
      mapConfig,
    };
  }
}
