import { Injectable } from '@angular/core';
import { CategoriesService } from '../../providers/categories/categories.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Category } from '../../dataset/Category';
import { Mall } from '../../dataset/Mall';

@Injectable()
export class CategoriesResolveService {

  constructor(private categoriesService: CategoriesService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Category[]> {
    const mall: Mall = route.parent.data.mall;
    return await this.categoriesService.getAll(mall._id);
  }

}
