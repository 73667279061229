import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivitySection } from '../../dataset/ActivitySection';
import { ActivitySectionsService } from '../../providers/activity-sections/activity-sections.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitySectionsResolverService implements Resolve<ActivitySection[]> {

  constructor(private service: ActivitySectionsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ActivitySection[]> {
    const id = route.parent.paramMap.get('id') || route.parent.data.activity._id;
    return await this.service.getAll(id);
  }
}
