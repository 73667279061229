import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MapConfig} from "../../../../dataset/map/MapConfig";

@Component({
  selector: 'app-threed-settings-modal',
  templateUrl: './threed-settings-modal.component.html',
  styleUrls: ['./threed-settings-modal.component.scss']
})
export class ThreedSettingsModalComponent implements OnInit {

  form: FormGroup;

  constructor(private fb: FormBuilder,
              private ref: MatDialogRef<ThreedSettingsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {isBigMap: boolean; mapConfig: MapConfig}) {
    this.form = this.fb.group({
      floors: this.fb.array([]),
      isBigMap: false,
    });

    if (!this.data.mapConfig) {
      return;
    }
    this.data.mapConfig.floors.forEach((floor, i) => {
      this.addFloor();
      floor.threeDObjects.forEach(object => {
        this.addThreeDObject(i);
        const [positionX, positionY, positionZ] = object.position;
        const [rotationX, rotationY, rotationZ] = object.rotation;
        const [scaleX, scaleY, scaleZ] = object.scale;
        delete object.position;
        delete object.rotation;
        delete object.scale;
        Object.assign(object, {
          positionX,
          positionY,
          positionZ,
          rotationX,
          rotationY,
          rotationZ,
          scaleX,
          scaleY,
          scaleZ,
        });
      });
    });
    this.form.patchValue({
      floors: this.data.mapConfig.floors,
      isBigMap: this.data.isBigMap,
    });
  }

  ngOnInit(): void {
  }

  get floorsControls(): FormArray {
    return this.form.get('floors') as FormArray;
  }

  addFloor(): void {
    this.floorsControls.controls.push(this.getFloorGroup());
  }

  getFloorGroup() {
    return this.fb.group({
      number: [1, Validators.required],
      threeDObjects: this.fb.array([]),
    });
  }

  getThreeDObjectGroup(): FormGroup {
    return this.fb.group({
      path: [null, Validators.required],
      scaleX: [0, Validators.required],
      scaleY: [0, Validators.required],
      scaleZ: [0, Validators.required],
      positionX: [0, Validators.required],
      positionY: [0, Validators.required],
      positionZ: [0, Validators.required],
      rotationX: [0, Validators.required],
      rotationY: [0, Validators.required],
      rotationZ: [0, Validators.required],
    });
  }

  getThreeDObjectsOfFloor(controlPosition: number): FormArray {
    return this.floorsControls.controls[controlPosition].get('threeDObjects') as FormArray;
  }

  addThreeDObject(controlPosition: number): void {
    this.getThreeDObjectsOfFloor(controlPosition).controls.push(this.getThreeDObjectGroup());
  }

  save(): void {
    const floors = this.form.getRawValue().floors;
    floors.forEach(floor => {
      floor.threeDObjects.forEach(object => {
        const {positionX, positionY, positionZ, scaleX, scaleY, scaleZ, rotationX, rotationY, rotationZ } = object;
        object.position = [positionX, positionY, positionZ];
        object.scale = [scaleX, scaleY, scaleZ];
        object.rotation = [rotationX, rotationY, rotationZ];
        delete object.positionX;
        delete object.positionY;
        delete object.positionZ;
        delete object.scaleX;
        delete object.scaleY;
        delete object.scaleZ;
        delete object.rotationX;
        delete object.rotationY;
        delete object.rotationZ;
      });
    });
    this.ref.close({
      isBigMap: this.form.value.isBigMap,
      mapConfig: {
        floors,
      }
    });
  }

  removeObject(floorNumber: number, objectNumber: number): void {
    this.getThreeDObjectsOfFloor(floorNumber).controls.splice(objectNumber, 1);
  }
}
