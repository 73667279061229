import {NgModule} from '@angular/core';
import {CommonModule as AngularCommon} from '@angular/common';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthComponent} from './auth.component';
import {SignInComponent} from './sign-in/sign-in.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {CommonModule} from "../common/common.module";
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    AngularCommon,
    AuthRoutingModule,
    FlexModule
  ],
  declarations: [
    AuthComponent,
    SignInComponent,
    ForgotPasswordComponent
  ]
})
export class AuthModule {
}
