import { QuillConfig, QuillModules } from 'ngx-quill/lib/quill-editor.interfaces';
import { ImageHandler, Options } from 'ngx-quill-upload';
import Quill from 'quill';
import { UploadService } from '../app/providers/upload/upload.service';

Quill.register('modules/imageHandler', ImageHandler);

export function getDefaultQuillConfig(): QuillConfig {
  return {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{'header': 1}],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],
        ['link']
      ],
    },
    theme: 'snow',
  };
}

export function getQuillModulesWithImages(uploadService: UploadService): QuillModules {
  const imageHandler: Options = {
    upload: async (file) => {
      return await uploadService.upload(file);
    },
    accepts: ['png', 'jpg', 'jpeg']
  };
  const defaultConfig: QuillConfig = getDefaultQuillConfig();
  (defaultConfig.modules.toolbar as Array<any>).push(['image']);
  return {
    ...defaultConfig.modules,
    imageHandler,
  };
}
