import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TerminalsEditModalComponent} from "../terminals-edit-modal/terminals-edit-modal.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { DirtyFormModalService } from '../../../../providers/dirty-form-modal/dirty-form-modal.service';

@Component({
  selector: 'app-activate-terminal',
  templateUrl: './activate-terminal.component.html',
  styleUrls: ['./activate-terminal.component.scss']
})
export class ActivateTerminalComponent implements OnInit {

  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<TerminalsEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              fb: FormBuilder,
              private dirtyFormService: DirtyFormModalService) {
    this.form = fb.group({
      pin: [null, Validators.required]
    });
    this.dirtyFormService.registerForm(this.form, dialogRef);
  }

  ngOnInit() {
  }

  confirm() {
    if (!this.form.value) {
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  cancel() {
    this.dirtyFormService.closeSafely();
  }

}
