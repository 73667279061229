import { NgModule } from '@angular/core';
import {TerminalsGroupEditModalComponent} from "./terminals-group-edit-modal/terminals-group-edit-modal.component";
import {CommonModule} from "../../../common/common.module";

@NgModule({
  declarations: [
    TerminalsGroupEditModalComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class TerminalGroupsModule { }
