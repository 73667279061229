import {BBox} from 'snapsvg-cjs';

export function union(boxes: BBox[]) {
  const xs = [], ys = [], x2s = [], y2s = [];
  for (const box of boxes) {
    xs.push(box.x);
    ys.push(box.y);
    x2s.push(box.x + box.width);
    y2s.push(box.y + box.height);
  }
  const x = Math.min.apply(0, xs);
  const y = Math.min.apply(0, ys);
  const x2 = Math.max.apply(0, x2s);
  const y2 = Math.max.apply(0, y2s);
  const w = x2 - x;
  const h = y2 - y;
  return {
    x, y,
    x2, y2,
    width: w,
    height: h,
    h, w,
    cx: x + (x2 - x) / 2,
    cy: y + (y2 - y) / 2
  };
}
