const MAXIMUM_SCALE_FACTOR = 8.0;

export class ViewBox {

  private scale = 1.0;

  get x(): number {
    return this._x;
  }

  get y(): number {
    return this._y;
  }

  get w(): number {
    return this._w;
  }

  get h(): number {
    return this._h;
  }

  constructor(private _x: number,
              private _y: number,
              private _w: number,
              private _h: number) {
  }

  resize(w: number, h: number) {
    this._w = w;
    this._h = h;
  }

  translate(x: number, y: number) {
    this._x = x;
    this._y = y;
  }

  adjustToBounds(W: number, H: number) {

    const maxW = W / MAXIMUM_SCALE_FACTOR;
    const maxH = H / MAXIMUM_SCALE_FACTOR;

    let h = this.h;
    let w = this.w;
    let x = this.x;
    let y = this.y;

    if (h > H) {
      h = H;
    }

    if (w > W) {
      w = W;
    }

    if (w < maxW) {
      x -= (maxW - w) / 2;
      w = maxW;
    }

    if (h < maxH) {
      y -= (maxH - h) / 2;
      h = maxH;
    }

    if (x < 0) {
      x = 0;
    }

    if (y < 0) {
      y = 0;
    }

    if (x + w > W) {
      x = W - w;
    }

    if (y + h > H) {
      y = H - h;
    }

    this.resize(w, h);
    this.translate(x, y);

  }

  get origin(): HammerPoint {
    return {x: this.x, y: this.y};
  }

  scaleBy(factor: number) {
    const x = this.x + this.w / 2;
    const y = this.y + this.h / 2;
    this.scaleByAroundPoint(factor, {x, y});
  }

  scaleByAroundPoint(factor: number, {x, y}: { x: number; y: number }) {
    this.scale *= factor;
    const w = this.w * factor;
    const h = this.h * factor;
    this.resize(w, h);
    this.translate(x - w / 2, y - h / 2);
  }

  centerOnPoint(x: number, y: number) {
    this.translate(x - this.w / 2, y - this.h / 2);
  }

  copyByScale(factor: number): ViewBox {
    const copy = new ViewBox(this._x, this._y, this._w, this._h);
    copy.scaleBy(factor);
    return copy;
  }
}
