import { Injectable } from '@angular/core';
import { BannersService } from '../../providers/banners/banners.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BannerGroup } from '../../dataset/BannerGroup';
import { Mall } from '../../dataset/Mall';

@Injectable({
  providedIn: 'root'
})
export class BannerGroupsResolveService implements Resolve<BannerGroup[]> {

  constructor(private service: BannersService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<BannerGroup[]> {
    const mall: Mall = route.parent.data.mall;
    return await this.service.getGroups(mall._id);
  }
}
