import { NgModule } from '@angular/core';
import { ShopsComponent } from './shops.component';
import { ShopsEditModalComponent } from './shops-edit-modal/shops-edit-modal.component';
import { ShopAgentsComponent } from './shop-agents/shop-agents.component';
import { CommonModule } from '../../../common/common.module';
import { ShopDeactivateComponent } from './shop-deactivate/shop-deactivate.component';

@NgModule({
  declarations: [
    ShopsComponent,
    ShopsEditModalComponent,
    ShopAgentsComponent,
    ShopDeactivateComponent,
  ],
  imports: [
    CommonModule,
  ]
})
export class ShopsModule { }
