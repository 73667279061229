import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Category } from '../../dataset/Category';
import {UploadService} from "../upload/upload.service";

@Injectable()
export class CategoriesService {

  constructor(private http: HttpClient,
              private uploadService: UploadService) {
  }

  async getAll(mall: string): Promise<Category[]> {
    return this.http.get<Category[]>('/category', {
      params: new HttpParams().set('mall', mall)
    }).toPromise();
  }

  async get(id: string): Promise<Category[]> {
    return this.http.get<Category[]>(`/category/${id}`).toPromise();
  }

  async create(data: any) {
    return this.http.post<Category>(`/category`, data).toPromise();
  }

  async update(id: string, data: any) {
    return this.http.put<Category>(`/category/${id}`, data).toPromise();
  }

  async delete(id: string) {
    return this.http.delete<Category>(`/category/${id}`).toPromise();
  }

  async upload(categoryId: string, file: File, type: 'cover' | 'icon'): Promise<Category> {
    const fileData = this.uploadService.prepareFile(file);
    return this.http.post<Category>(`/category/${categoryId}/${type}`, fileData).toPromise();
  }

}
