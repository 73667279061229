import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as Snap from 'snapsvg-cjs';
import {chain} from 'lodash';
import {union} from "../../../utils/bbox.utils";
import {GraphRoute} from "../../dataset/map/GraphRoute";
import {MapObjectId} from "../../dataset/map/MapObjectId";
import {ViewBox} from "../../dataset/map/ViewBox";
import {Floors} from "../../dataset/map/Floors";
import {Floor} from "../../dataset/map/Floor";
import {P} from "../../../utils/shape.utils";
import {Terminal} from "../../dataset/Terminal";
import {getCoordinatesFromString} from "../../../utils/utils";
import {QrCode} from "../../dataset/QrCode";
import {idGen} from "../../dataset/map/Graph";

const PADDING = 100;

export interface MapOptions {
  route_drawing_speed: number;
}

interface SelectObjectsOptions {
  routeToNearest: boolean;
  disabled: boolean;
}

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit, OnDestroy, OnChanges {

  markerSize = 30;
  raySize = 30;
  rayColor = '#ffffff';

  @ViewChild('svg', {static: true}) _svg: ElementRef;

  private _locale: string;
  get locale(): string {
    return this._locale;
  }

  @Input()
  set locale(locale: string) {
    this._locale = locale;
    this.hideLogos();
  }

  @Input() settings: MapOptions = {
    route_drawing_speed: 1000
  };

  @Input() showBoundPointsLabels: boolean;

  @Output() objectClick: EventEmitter<MapObjectId> = new EventEmitter<MapObjectId>();
  @Output() graphPointClicked: EventEmitter<{ x: number; y: number; }> = new EventEmitter<{ x: number; y: number; }>();

  @Output() initialize: EventEmitter<void> = new EventEmitter<void>();

  points: P[];

  private _floorNumber: number;
  @Input() set floorNumber(input: number) {
    if (this._floorNumber === input) {
      return;
    }
    this._floorNumber = input;
    if (!this.floors) {
      return;
    }
    this.floorNumberChange.emit(input);
    if (this.floors.get(input)) {
      this.currentFloor = this.floors.get(input);
    }
  }

  get floorNumber(): number {
    return this._floorNumber;
  }

  @Output() floorNumberChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() currentFloorNumber: number;
  @Input() currentId: string;
  @Input() currentBearing = 0.0;

  @Input() floors: Floors;
  @Input() terminals?: Terminal[];
  @Input() qrCodes?: QrCode[];

  private lastOffset: HammerPoint = {x: 0, y: 0};

  private viewBoxStore: ViewBox;
  private viewBox = new ViewBox(0, 0, 0, 0);
  private hostWidth = 0;
  private hostHeight = 0;
  private snap: Snap.Fragment;
  private snapMap: Snap.Element;
  private snapPath: Snap.Element;

  private activeObjects: Snap.Element[] = [];
  private activeObjectsId: string[] = [];
  mapRoute: GraphRoute;

  filteredTerminals: Terminal[];
  filteredQrCodes: QrCode[];
  // при наведении на точку надо показать подсказку со всеми терминалами/qr-кодами в этой точке
  pointsToTerminalNames: {[key: string]: string} = {};
  pointsToQrCodeNames: {[key: string]: string} = {};

  private get rect(): ClientRect {
    return (this.el.nativeElement as HTMLElement).getBoundingClientRect();
  }

  portalCoordinates: { x: number; y: number };

  myCoordinates: { x: number; y: number };

  private _currentFloor: Floor;
  get currentFloor(): Floor {
    return this._currentFloor;
  }

  set currentFloor(floor: Floor) {

    this.deselectActive();

    if (!floor) {
      return;
    }

    if (this._currentFloor) {
      this._currentFloor.snap.remove();
    }

    this.points = [];

    if (!floor.snap) {
      return;
    }

    floor.snap.insertBefore(this.snapPath);

    const W = this.snapMap.getBBox().width;
    const H = this.snapMap.getBBox().height;
    const X = this.snapMap.getBBox().x;
    const Y = this.snapMap.getBBox().y;
    const rect = this.rect;
    const w = rect.width;
    const h = rect.height;

    this.viewBox.resize(W, H);

    this.viewBox.translate(X, Y);

    this.hostWidth = w;
    this.hostHeight = h;

    this.points = floor.graph.nodes.map(n => {
      const parts = n.replace(/^-?[0-9]+-/, '')
        .split('x')
        .map(e => parseFloat(e));
      const x = parts[0];
      const y = parts[1];
      return {x, y};
    });


    this.updateTranslation();

    this.snapPath.attr('transform', `translate(${floor.graphTranslation.x},${floor.graphTranslation.y})`);
    this._currentFloor = floor;
    this.filterTerminals();
    this.filterQrCodes();
    this.hideLogos();
    this.selectActive();
    if (this.mapRoute) {
      this.drawRoute();
    }
    this.updateMyCoordinates();
  }

  private deselectActive() {
    this.activeObjects.forEach(ob => {
      ob.removeClass('active-object');
      const highlighter = ob.select('#highlighter');
      if (highlighter) {
        highlighter.remove();
      }
    });
    this.activeObjects.length = 0;
  }

  private selectActive() {
    this.activeObjects.push(...this.activeObjectsId.map(id => {
      if (!this.currentFloor) {
        return;
      }
      const el = this.currentFloor.getElement(id);
      if (el) {
        el.addClass('active-object');
        return el;
      }
    }).filter(e => !!e));
  }

  public reset() {
    const {width, height} = this.snapMap.getBBox();
    this.viewBox.resize(width, height);
    this.viewBox.translate(0, 0);
    this.updateTranslation(true);
    this.clearRoute();
    this.deselectActive();
    this.activeObjectsId = [];
  }

  public selectObjects(ids: string[],
                       options: { routeToNearest?: boolean, focus?: boolean } = {routeToNearest: false, focus: false},
                       route?: GraphRoute) {
    if (!ids) {
      return;
    }
    this.deselectActive();
    this.activeObjectsId = ids;
    this.selectActive();
    if (options.routeToNearest) {
      if (route) {
        this.showRoute(route);
      } else {
        this.routeToObjectWithIds(ids);
      }
    } else if (options.focus) {
      const boxes = ids.map(id => {
        const el = this.currentFloor.getElement(id);
        if (el) {
          return el.getBBox();
        }
      }).filter(e => !!e);
      if (boxes.length) {
        const superBox = union(boxes);
        this.zoomToBox(superBox);
      }
    }
  }

  public selectObjectsByType(type: string, options: SelectObjectsOptions = {
    routeToNearest: false,
    disabled: false
  }) {
    const ids = this.floors.getIdsForType(type, options.disabled) || [];
    this.selectObjects(ids, options);
  }

  private pathBox() {
    const box = this.snapPath.select('#way').getBBox();
    box.x += this.currentFloor.graphTranslation.x;
    box.y += this.currentFloor.graphTranslation.y;
    return box;
  }

  private zoomToBox({x, y, w, h}: Snap.BBox) {
    this.viewBox.resize(w + PADDING * 2, h + PADDING * 2);
    this.viewBox.translate(x - PADDING, y - PADDING);
    this.adjustContentOffsetToBounds();
    this.updateTranslation(true);
  }

  private drawRoute() {
    const paper = this.snapPath.select('#way') as Snap.Paper;
    paper.clear();
    const segment = this.mapRoute.getSegment(this.floorNumber);
    if (segment) {
      const path = paper.path(segment.path);
      paper.append(path);
      const pathLength = path.getTotalLength();
      path.attr({
        'stroke-dasharray': pathLength,
        'stroke-dashoffset': pathLength
      });
      path.animate({'stroke-dashoffset': 0}, (pathLength / this.settings.route_drawing_speed) * 1000);
      let box = this.pathBox();
      const fromId = this.getObjectId(segment.from);
      const fromEl = this.currentFloor.getElement(fromId);
      if (fromEl) {
        box = union([box, fromEl.getBBox()]);
        this.activeObjects.push(fromEl);
        this.activeObjectsId.push(fromId);
        fromEl.addClass('active-object');
      }
      if (!segment.toFloor) {
        const objectId = this.getObjectId(segment.to);
        const el = this.currentFloor.getElement(objectId);
        box = union([box, el.getBBox()]);
      }
      this.zoomToBox(box);
      this.updatePortalCoordinates();
    }
  }

  private hideLogos() {
    if (!this._currentFloor) {
      return;
    }
    console.log('hide logos');
    this._currentFloor.getElements().forEach(element => {
      const logos = element.selectAll('[id^="logo"]');
      const currentLogoLocal = `logo${this._locale}`;
      const data = [];
      logos.forEach(d => {
        d.removeClass('hidden');
        const id = d.attr('id');
        if (id && id.startsWith(currentLogoLocal)) {
          data.push(d);
        }
      });
      const curr = data.length ? data[0] : logos[0];
      logos.forEach(d => {
        if (d !== curr) {
          d.addClass('hidden');
        }
      });
    });
  }

  private getObjectId(node: string): string {
    return this.currentFloor.pointsToId[node];
  }

  private clearRoute() {
    (this.snapPath.select('#way') as Snap.Paper).clear();
    this.mapRoute = null;
  }

  constructor(private el: ElementRef) {
  }

  panStart() {
    this.lastOffset = this.viewBox.origin;
  }

  panMove(ev) {
    const dX = this.hostWidth / this.viewBox.w;
    const dY = this.hostHeight / this.viewBox.h;
    this.viewBox.translate(this.lastOffset.x - (ev.deltaX / dX), this.lastOffset.y - (ev.deltaY / dY));
    this.updateTranslation(false);
  }

  panStop() {
    this.adjustContentOffsetToBounds();
    this.updateTranslation(true);

  }

  pinchStart(e: { scale: number; center: HammerPoint }) {
    this.viewBoxStore = this.viewBox;
    console.log('pinchStart', e);
  }

  pinchMove({scale}: { scale: number; center: HammerPoint }) {
    this.viewBox = this.viewBoxStore.copyByScale(1 / scale);
    this.updateScale(false);
  }

  pinchEnd(e: { scale: number; center: HammerPoint }) {
    console.log('pinchEnd', e);
    this.viewBoxStore = null;
  }

  async ngOnInit() {
    this.snap = Snap(this._svg.nativeElement);
    this.snapMap = this.snap.select('g#map');
    this.snapPath = this.snap.select('g#path');
    if (typeof this._floorNumber !== 'undefined') {
      this.currentFloor = this.floors.get(this._floorNumber);
    }
  }

  ngOnDestroy(): void {
    this.deselectActive();
  }

  public zoomIn() {
    this.viewBox.scaleBy(0.5);
    this.updateScale(true);
  }

  public zoomOut() {
    this.viewBox.scaleBy(2);
    this.updateScale(true);
  }

  public isAttached(id: string): boolean {
    return this.floors.graph.nodes.includes(id);
  }

  private updateScale(animated = false) {
    this.adjustContentOffsetToBounds();
    this.updateTranslation(animated);
  }

  private updateTranslation(animated = false) {
    const root = this.snap as Snap.Element;
    const viewBoxX = this.snapMap.getBBox().x === this.viewBox.x ? this.viewBox.x : this.viewBox.x + this.snapMap.getBBox().x;
    const viewBoxY = this.snapMap.getBBox().y === this.viewBox.y ? this.viewBox.y : this.viewBox.y + this.snapMap.getBBox().y;
    const viewBox = `${viewBoxX} ${viewBoxY} ${this.viewBox.w} ${this.viewBox.h}`;
    if (animated) {
      root.animate({viewBox}, 250, (window as any).mina.easeinout);
    } else {
      root.attr({viewBox});
    }
  }

  @HostListener('window:resize')
  resize() {
    const rect = this.rect;
    const w = rect.width;
    const h = rect.height;
    this.hostWidth = w;
    this.hostHeight = h;
  }

  click($event: MouseEvent) {
    const findRightParent = (element: Element): Element => {
      if (!element) {
        return null;
      }
      if (element.parentElement && element.parentElement.id === 'objects') {
        return element;
      }
      return findRightParent(element.parentElement);
    };
    const objectElement = findRightParent($event.target as Element);
    if (objectElement) {
      const objId = new MapObjectId(objectElement.id);
      if (this.mapRoute) {
        const segment = this.mapRoute.getSegment(this.floorNumber);
        if (segment) {
          const fromId = this.getObjectId(segment.from);
          if (objId.id === fromId) {
            const prevSegment = this.mapRoute.getPrevSegment(this.floorNumber);
            this.floorNumber = prevSegment.floor;
            return;
          }
        }
      }
      this.objectClick.emit(objId);
    }
  }

  routeToObjectWithIds(uids: string[], from?: string[]) {
    this.clearRoute();

    const FROM = from || [this.currentId];
    const route = chain(uids)
      .map(uid => this.floors.getIdProjection(uid) || [])
      .flatten()
      .map(id => FROM.map(p => this.floors.graph.route(p, id)))
      .flatten()
      .compact()
      .minBy(r => r.distance)
      .value();

    if (route) {
      this.showRoute(route);
    }
  }

  private showRoute(route: GraphRoute) {
    const FROM_FLOOR = this.currentFloorNumber;
    this.mapRoute = route;
    if (this.currentFloor.number !== FROM_FLOOR) {
      this.floorNumber = FROM_FLOOR;
    } else {
      this.drawRoute();
    }
  }

  updatePortalCoordinates() {
    const objId = this.getObjectId(this.mapRoute.getSegment(this.floorNumber).to);
    const el = this.currentFloor.getElement(objId);
    if (el) {
      const x = el.getBBox().x;
      const y = el.getBBox().y;
      this.portalCoordinates = {x, y};
    }
  }

  updateMyCoordinates() {
    if (this.currentFloorNumber === this.floorNumber && this.currentId) {
      const data = getCoordinatesFromString(this.currentId);
      const x = data.x - this.markerSize / 2 + this.currentFloor.graphTranslation.x;
      const y = data.y - this.markerSize / 2 + this.currentFloor.graphTranslation.y;
      this.myCoordinates = {x, y};
    }
  }

  goToFloor($event: MouseEvent) {
    $event.stopPropagation();
    this.floorNumber = parseInt(this.mapRoute.getSegment(this.currentFloor.number).toFloor, 10);
  }

  /**
   * UTILS
   */

  adjustContentOffsetToBounds() {
    const W = this.snapMap.getBBox().width;
    const H = this.snapMap.getBBox().height;
    this.viewBox.adjustToBounds(W, H);

  }

  deviceLocation() {
    this.floorNumber = this.currentFloorNumber;
    const data = getCoordinatesFromString(this.currentId);
    const x = data.x - 60 + this.currentFloor.graphTranslation.x;
    const y = data.y - 60 + this.currentFloor.graphTranslation.y;
    this.viewBox.resize(300, 300);
    this.viewBox.centerOnPoint(x, y);
    this.adjustContentOffsetToBounds();
    this.updateTranslation(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['floors'] && changes['floors'].currentValue && !changes['floors'].firstChange) {
      this.currentFloor = this.floors.get(this._floorNumber);
    }
    if (changes['terminals'] && changes['terminals'].currentValue) {
      this.terminals = changes['terminals'].currentValue;
      this.filterTerminals();
    }
    if (changes['qrCodes'] && changes['qrCodes'].currentValue) {
      this.qrCodes = changes['qrCodes'].currentValue;
      this.filterQrCodes();
    }
  }

  filterTerminals() {
    if (!this.terminals || this.terminals.length === 0 || !this.currentFloor) {
      return;
    }
    this.filteredTerminals = this.terminals.filter(item => item.floor === this.currentFloor.number);
    this.filteredTerminals.forEach(item => {
      if (this.pointsToTerminalNames[item.mapId]) {
        if (!this.pointsToTerminalNames[item.mapId].includes(item.name)) {
          this.pointsToTerminalNames[item.mapId] += `, ${item.name}`;
        }
      } else {
        this.pointsToTerminalNames[item.mapId] = item.name;
      }
    });
  }

  filterQrCodes(): void {
    if (!this.qrCodes || this.qrCodes.length === 0 || !this.currentFloor) {
      return;
    }
    this.filteredQrCodes = this.qrCodes.filter(item => item.floor === this.currentFloor.number);
    this.filteredQrCodes.forEach(item => {
      const mapId = idGen(item.position.x, item.position.y, item.floor);
      if (this.pointsToQrCodeNames[mapId]) {
        if (!this.pointsToQrCodeNames[mapId].includes(item.title)) {
          this.pointsToQrCodeNames[mapId] += `, ${item.title}`;
        }
      } else {
        this.pointsToQrCodeNames[mapId] = item.title;
      }
    });
    console.log(this.filteredQrCodes)
  }

  wheelMove(event: WheelEvent) {
    event.preventDefault();
    if (event.deltaY < 0) {
      this.zoomIn();
    } else {
      this.zoomOut();
    }
  }

}
